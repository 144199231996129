// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@media only screen and (min-width: 1024px) {
    .content-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .content-header-title span {
        font-size: x-large;
        font-weight: bold;
    }
}

@media only screen and (min-width: 1144px)  {
    .content-body {
        width: 50%;
    }
    .content-body-initial{
        display: flex;
        gap: 3%;
    }
    .content-body-field-middle {
        display: flex;
        gap: 3%;
        align-items: center;
    }
    .content-body-field-end {
        display: flex;
        gap: 3%;
        align-items: center;
    }
    .form-label {
        font-size: 0.69rem;
    }
}

@media only screen and (min-width: 1580px) {
    .form-label {
        font-size: 1rem;
    }
    .table-stripped th{
        padding: 20px;
    }
    .table-stripped td {
        padding: 20px; 
    }
}
`, "",{"version":3,"sources":["webpack://./src/views/invoice/style.css"],"names":[],"mappings":";;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,8BAA8B;QAC9B,mBAAmB;IACvB;IACA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,aAAa;QACb,OAAO;IACX;IACA;QACI,aAAa;QACb,OAAO;QACP,mBAAmB;IACvB;IACA;QACI,aAAa;QACb,OAAO;QACP,mBAAmB;IACvB;IACA;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n\n@media only screen and (min-width: 1024px) {\n    .content-header {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        align-items: center;\n    }\n    .content-header-title span {\n        font-size: x-large;\n        font-weight: bold;\n    }\n}\n\n@media only screen and (min-width: 1144px)  {\n    .content-body {\n        width: 50%;\n    }\n    .content-body-initial{\n        display: flex;\n        gap: 3%;\n    }\n    .content-body-field-middle {\n        display: flex;\n        gap: 3%;\n        align-items: center;\n    }\n    .content-body-field-end {\n        display: flex;\n        gap: 3%;\n        align-items: center;\n    }\n    .form-label {\n        font-size: 0.69rem;\n    }\n}\n\n@media only screen and (min-width: 1580px) {\n    .form-label {\n        font-size: 1rem;\n    }\n    .table-stripped th{\n        padding: 20px;\n    }\n    .table-stripped td {\n        padding: 20px; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
