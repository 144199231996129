import { useParams } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useEffect, useState } from "react";

import Layout from "../../components/layout/layout";
import Loading from "../../components/loading";
import InputCurrency from "../../components/input-currency";

import Property from "../../services/property";
import Tenant from "../../services/tenant";

import "../../assets/styles/property.css";
import Service from "../../services/contract";

import SeeDetail from "../../components/icons/see-detail";
import ListClipBoard from "../../components/icons/list-clip-board";
import Button from "../../components/button";
import RemoveFile from "../../components/icons/remove-file";
import Pencil from "../../components/icons/pencil";
import DollarCoin from "../../components/icons/dollar-coin";
import { BsThreeDots } from "react-icons/bs";

import Time from "../../hooks/time";
import InputLateFee from "../../components/input-latefee";
import InputLateInterest from "../../components/input-lateinterest";
import LoadingBasicFull from "../../components/loading-basic-full";
import LoadingOverlay from "../../components/loading-overlay";
import "./style.css"

const Contract = () => {
  const auth = useAuthUser();
  let { id } = useParams();
  const [data, setData] = useState(null);
  const [form, setForm] = useState(null);
  const [updateIndex, setUpdateIndex] = useState([]);
  const [bankAccount, setBankAccount] = useState([]);
  const [tenant, setTenant] = useState([]);
  const [property, setProperty] = useState([]);
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (!id) window.location.href = "/contratos";
    else {
      Service.updateIndexList().then((updateIndexResult) => {
        setUpdateIndex(updateIndexResult.update_index);
        Service.bankAccountList(auth().id).then((bankAccountResult) => {
          setBankAccount(bankAccountResult.bank_accounts);
          Tenant.listByUser(auth().id, "", true).then((tenantResult) => {
            setTenant(tenantResult.users);
            Property.listByUser(auth().id, "", true).then((propertyResult) => {
              setProperty(propertyResult.properties);
              Service.contractDetails({ id, user: auth().id }).then(
                (response) => {
                  if (!response.success) window.location.href = "/contratos";
                  else {
                    const item = response.contract[0];
                    const item2 = response.contract[0];

                    item.start_at = Time.formatToYYYYMMDD(item.start_at);
                    item.end_at = Time.formatToYYYYMMDD(item.end_at);

                    item.months_difference = Time.calculateMonthDifference(
                      item.start_at,
                      item.end_at
                    );

                    setData(item);

                    item2.bank_account_id = parseInt(item2.bank_account_id);
                    item2.is_active = item2.is_active === "1";
                    item2.late_fee = parseFloat(item2.late_fee);
                    item2.late_interest = parseFloat(item2.late_interest);
                    item2.update_index_id = parseInt(item2.update_index_id);

                    setForm(item2);
                  }
                }
              );
            });
          });
        });
      });
    }
  }, []);

  const submit = (active = null) => {
    setError(false);
    setErrorMessage("");

    let newForm = {};
    newForm = {
      id: form.id,
      property_id: form.property_id ?? data.property_id,
      tenant_id: form.tenant_id ?? data.tenant_id,
      update_index_id: form.update_index_id,
      bank_account_id: form.bank_account_id,
      is_active: active != null ? active : form.is_active,
      deposit_value: form.deposit_value ? parseFloat(form.deposit_value) : 0,
      late_fee: form.late_fee && typeof (form.late_fee) === 'string'
        ? parseFloat(form.late_fee.replace(" %", "").replace(",", "."))
        : 0,
      late_interest: form.late_interest && typeof (form.late_interest) === 'string'
        ? parseFloat(
          form.late_interest.replace(" a.m. %", "").replace(",", ".")
        )
        : 0,
      months_difference: parseInt(form.months_difference),
      pay_day: parseInt(form.pay_day),
      condominium_value: form.condominium_value
        ? parseFloat(
          form.condominium_value
            .replace("R$ ", "")
            .replace(".", "")
            .replace(",", ".")
        )
        : null,
      rent_value: form.rent_value
        ? parseFloat(
          form.rent_value
            .replace("R$ ", "")
            .replace(".", "")
            .replace(",", ".")
        )
        : null,
      iptu_value: form.iptu_value
        ? parseFloat(
          form.iptu_value
            .replace("R$ ", "")
            .replace(".", "")
            .replace(",", ".")
        )
        : null,
      start_at: form.start_at,
    };

    if (
      (newForm.deposit_value || newForm.deposit_value === 0) &&
      (newForm.late_fee || newForm.late_fee === 0) &&
      (newForm.late_interest || newForm.late_interest === 0) &&
      newForm.months_difference &&
      newForm.pay_day &&
      newForm.rent_value &&
      newForm.start_at
    ) {
      let start_date = new Date(newForm.start_at);
      let end_date = new Date(
        start_date.setMonth(start_date.getMonth() + newForm.months_difference)
      );
      setLoadingOverlay(true);
      newForm.start_at = new Date(newForm.start_at).toISOString();
      newForm.end_at = end_date.toISOString();

      delete newForm.months_difference;

      Service.contractUpdate(newForm).then((response) => {
        if (response) window.location.reload()
        else {
          setLoadingOverlay(false);
          setError(false);
          setErrorMessage("Ocorreu um erro inesperado ao tentar atualizar.Tente novamente.")
        }
      });
    } else setError(true);
  };

  return (
    <Layout>

      {data === null ? (
        <div style={{ height: '100vh', display: 'flex', 'justifyContent': 'center', 'flexDirection': 'column', alignItems: 'center', margin: 'auto' }}><LoadingBasicFull /></div>
      ) : (
        <div id="layout-content-container" style={{ padding: '20px', overflowY: 'scroll', maxHeight: 'calc(100vh - 40px)', width: '100%' }}>
          {loadingOverlay && <LoadingOverlay />}
          <div className="my-properties-container p-5 property-details">
            <div className="topo d-flex topo flex-row " style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="content-header-title" style={{ display: 'flex', alignItems: 'center' }}>
                <ListClipBoard />
                <h4 className="content-header-title" style={{ marginTop: '10px', marginLeft: '15px' }}>Detalhes de Contrato</h4>
              </div>

              {
                <div className="content-header-contract-desktop ">
                  <Button
                    customClass={"imob-button-outline"}
                    display={form.is_active ? "Inativar" : "Ativar"}
                    icon={<RemoveFile />}
                    onClick={() => submit(!form.is_active)}
                  >
                  </Button>
                  <Button
                    onClick={() => setEdit(!edit)}
                    customClass={"imob-button-outline"}
                    display="Editar"
                    icon={<Pencil />}
                  />
                </div>}

              {<div className="content-header-actions-mobile">
                <button
                  className="imob-button-neutral imob-button-neutral-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsThreeDots size={25} />
                </button>
                <ul className="dropdown-menu my-dropdown">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setEdit(!edit)}
                      href={`#`}
                    >
                      <Pencil />
                      <span>Editar</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => submit(!form.is_active)}
                      href="#"
                    >
                      <RemoveFile />
                      <span>{form.is_active ? "Inativar" : "Ativar"}</span>
                    </a>
                  </li>
                </ul>
              </div>
              }

            </div>
            <hr />
            <div className="content-body">
              {error ? (
                <div id="step-required" className="w-100">
                  <div className="alert imob-alert imob-alert-danger" role="alert">
                    <i className="bx bx-info-circle"></i>
                    {errorMessage ? <><strong>Atenção! </strong> {errorMessage}</> : <><strong> Preencha todos os campos</strong> antes de continuar.</>}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="content-body-field w-100 mb-2">
                <label htmlFor="property_name" className="form-label">
                  Imóvel:
                </label>
                <div className="d-flex imob-align-center">
                  <select
                    onChange={(evt) =>
                      setForm({
                        ...form,
                        property_id: parseInt(evt.target.value),
                      })
                    }
                    defaultValue={data.property_id}
                    disabled={true}
                    name="property_name"
                    id="property_name"
                    className="form-select"
                  >
                    {property.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.type.name} - {item.name}
                      </option>
                    ))}
                    <option>{data.property_name}</option>
                  </select>
                  <a href="#">
                    <SeeDetail href={`/imoveis/detalhe/${form.property_id}`} />
                  </a>
                </div>
              </div>
              <div className="content-body-field w-100 mb-2">
                <label htmlFor="tenant_name" className="form-label">
                  Locatário:
                </label>
                <div className="d-flex imob-align-center">
                  <select
                    onChange={(evt) =>
                      setForm({
                        ...form,
                        tenant_id: parseInt(evt.target.value),
                      })
                    }
                    value={data.tenant_id}
                    disabled={true}
                    name="tenant_name"
                    id="tenant_name"
                    className="form-select input"
                  >
                    {tenant.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <a href="#">
                    <SeeDetail href={`/locatarios/detalhe/${form.tenant_id}`} />
                  </a>
                </div>
              </div>
              <div className="content-body-field-group w25">
                <div className="content-body-field w25  mb-2">
                  <label htmlFor="start_date" className="form-label">
                    Data de Início:
                  </label>
                  <input
                    onChange={(evt) =>
                      setForm({ ...form, start_at: evt.target.value })
                    }
                    name="start_date"
                    id="start_date"
                    disabled={edit ? false : "disabled"}
                    type="date"
                    className="form-control input"
                    defaultValue={data.start_at}
                  />
                </div>
                <div className="content-body-field w25  mb-2">
                  <label htmlFor="end_at" className="form-label">
                    Data Fim:
                  </label>
                  <input
                    name="end_at"
                    id="end_at"
                    disabled={"disabled"}
                    type="date"
                    className="form-control input"
                    defaultValue={data.end_at}
                  />
                </div>
                <div className="content-body-field w25  mb-2">
                  <label htmlFor="months_difference" className="form-label">
                    Prazo do Contrato (em meses)
                  </label>
                  <input
                    onKeyUp={(evt) =>
                      setForm({
                        ...form,
                        months_difference: parseInt(evt.target.value),
                      })
                    }
                    name="months_difference"
                    id="months_difference"
                    disabled={edit ? false : "disabled"}
                    type="number"
                    className="form-control input"
                    defaultValue={data.months_difference}
                  />
                </div>
              </div>
              <div className="content-body-field-group w25">
                <div className="content-body-field w25  mb-2">
                  <label htmlFor="deposit_value" className="form-label">
                    Cheque Caução (em meses):
                  </label>
                  <input
                    onKeyUp={(evt) =>
                      setForm({
                        ...form,
                        deposit_value: parseInt(evt.target.value),
                      })
                    }
                    name="deposit_value"
                    id="deposit_value"
                    disabled={edit ? false : "disabled"}
                    type="number"
                    className="form-control input"
                    defaultValue={data.deposit_value}
                  />
                </div>
                <div className="content-body-field w54 mb-2">
                  <label htmlFor="update_index_id" className="form-label">
                    Índice de Atualização:
                  </label>
                  <select
                    onChange={(evt) =>
                      setForm({
                        ...form,
                        update_index_id: parseInt(evt.target.value),
                      })
                    }
                    disabled={edit ? false : "disabled"}
                    defaultValue={parseInt(data.update_index_id)}
                    name="update_index_id"
                    id="update_index_id"
                    className="form-select input"
                  >
                    {updateIndex.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="content-body-field w-25 mb-2 textStatusContainer" style={{ display: 'flex', flexDirection: 'column', gap: '3px', textAlign: 'center' }}>
                  <p htmlFor="update_index_id" className="form-label">
                    Status:
                  </p>
                  <div>
                    {form.is_active ? (
                      <span className='c-tag-success textStatusContract' style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                        Ativo
                      </span>
                    ) : <span className='c-tag-warning textStatusContract' style={{ paddingLeft: '50px', paddingRight: '50px' }}>Inátivo</span>}
                  </div>

                </div>
              </div>
            </div>
            <hr />
            <div className="content-header">
              <div className="content-header-title">
                <DollarCoin /> <span>Informações de Cobrança</span>
              </div>
            </div>
            <div className="content-body">
              <div className="content-body-field-group content-body-field-group-three">
                <div className="content-body-field w25-2  mb-2">
                  <label htmlFor="condominium_value" className="form-label">
                    Valor do Condomínio:
                  </label>
                  <InputCurrency
                    onKeyUp={(evt) =>
                      setForm({ ...form, condominium_value: evt.target.value })
                    }
                    name="condominium_value"
                    id="condominium_value"
                    type="text"
                    disabled={edit ? false : "disabled"}
                    className="form-control input"
                    defaultValue={data.condominium_value}
                  />
                </div>
                <div className="content-body-field w25-2  mb-2">
                  <label htmlFor="iptu_value" className="form-label">
                    Valor do IPTU:
                  </label>
                  <InputCurrency
                    onKeyUp={(evt) =>
                      setForm({ ...form, iptu_value: evt.target.value })
                    }
                    name="iptu_value"
                    id="iptu_value"
                    disabled={edit ? false : "disabled"}
                    type="text"
                    className="form-control input"
                    defaultValue={data.iptu_value}
                  />
                </div>
                <div className="content-body-field w25-2  mb-2">
                  <label htmlFor="rent_value" className="form-label">
                    Valor Total Mensal:
                  </label>
                  <InputCurrency
                    onKeyUp={(evt) =>
                      setForm({
                        ...form,
                        rent_value: evt.target.value
                      })
                    }
                    name="rent_value"
                    id="rent_value"
                    disabled={edit ? false : "disabled"}
                    type="text"
                    className="form-control input"
                    defaultValue={`R$ ${parseFloat(form.rent_value) + (parseFloat(form.condominium_value) || 0) + (parseFloat(form.iptu_value) || 0)}`}
                  />
                </div>

              </div>
              <div className="content-body-field-group content-body-field-group-three">
                <div className="content-body-field w25-2  mb-2">
                  <label htmlFor="pay_day" className="form-label">
                    Dia de Vencimento:
                  </label>
                  <input
                    onKeyUp={(evt) =>
                      setForm({ ...form, pay_day: parseInt(evt.target.value) })
                    }
                    name="pay_day"
                    id="pay_day"
                    disabled={edit ? false : "disabled"}
                    type="number"
                    className="form-control input"
                    defaultValue={data.pay_day}
                  />
                </div>
                <div className="content-body-field w25-2  mb-2">
                  <label htmlFor="late_fee" className="form-label">
                    Multa por Atraso:
                  </label>
                  <InputLateFee
                    onKeyUp={(evt) =>
                      setForm({ ...form, late_fee: evt.target.value })
                    }
                    name="late_fee"
                    id="late_fee"
                    disabled={edit ? false : "disabled"}
                    type="text"
                    className="form-control input"
                    defaultValue={data.late_fee}
                  />
                </div>
                <div className="content-body-field w25-2  mb-2">
                  <label htmlFor="late_interest" className="form-label">
                    Juros por Atraso:
                  </label>
                  <InputLateInterest
                    onKeyUp={(evt) =>
                      setForm({ ...form, late_interest: evt.target.value })
                    }
                    name="late_interest"
                    id="late_interest"
                    disabled={edit ? false : "disabled"}
                    type="text"
                    className="form-control input"
                    defaultValue={data.late_interest}
                  />
                </div>
              </div>
              <div className="content-body-field w-100  mb-2">
                <label htmlFor="bank_account" className="form-label">
                  Conta para Recebimento:
                </label>
                <select
                  onChange={(evt) =>
                    setForm({
                      ...form,
                      bank_account_id: parseInt(evt.target.value),
                    })
                  }
                  defaultValue={data.bank_account_id}
                  disabled={edit ? false : "disabled"}
                  name="bank_account"
                  id="bank_account"
                  className="form-select input"
                >
                  {bankAccount.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.bank_name ? <>
                        {item.bank_name} - {item.agency} -{" "}
                        {item.account}{" "}
                      </> : `PIX: ${item.pix}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  disabled={edit ? false : "disabled"}
                  className="btn imob-button"
                  onClick={() => submit()}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Contract;
