import React, { useState, useEffect } from 'react';
import LogoHorizontal from '../../../../assets/images/logo_full.svg'
import User from '../../../../services/user';
import LoadingBasic from '../../../../components/loading-basic';
import { IoMdArrowRoundBack } from "react-icons/io";
import "./style.css";
import { useToken } from '../../../../context/TokenContext';
import { useInfo } from '../../../../context/UserContext';


const Step02 = ({ onNextStep, onPrevStep }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [wppErrorMessage, setWppErrorMessage] = React.useState(false);
  const [wppLoading, setWppLoading] = React.useState(false);
  const { tokenWpp, setTokenWpp } = useToken();
  const {userData, setUserData} = useInfo()


  function handleChange(e, index) {
    if (isNaN(e.target.value)) return false;
    setOtp([
      ...otp.map((data, indx) => (indx === index ? e.target.value : data)),
    ]);
    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus()
    }
  }
  useEffect(() => {
    if (otp.join('').length === 6) {
      handleSubmit();
    }
  }, [otp]);

  const getOtpString = () => {
    return otp.join('');
  };

  useEffect(() => {
    console.log(tokenWpp)
  }, [])

  const handleSubmit = () => {
    const otpString = getOtpString();
    console.log('Formulário enviado com sucesso:', otpString);
    setWppErrorMessage("")
    User.validateWppCode({ token: tokenWpp.token, code: otpString }).then(({ success }) => {
      console.log(success)
      if (success === true) {
        setWppLoading(true);

        onNextStep()
      } else setWppErrorMessage("Código inválido")
    })
  };

  const sendCodeAgain = () => {
    User.sendVerificationCodeForgotPassword(userData.mobile).then((response) => {
      setTokenWpp(response);
      console.log(tokenWpp);
    });
  }

  return (
    <>
      <section className='containerGeralStep02'>
        <IoMdArrowRoundBack size={20} onClick={onPrevStep} />
        <figure>
          <img src={LogoHorizontal} alt='EuImob logo'></img>
        </figure>

        <div className='containerInfoStep02 mt-4'>
          <h2 className='tituloFormOtp'>Informe o código</h2>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong className='text01'>Enviamos um código de confirmação para</strong>

            <strong className='text02'>{userData.mobile}</strong>
            {/*<a href='#' className='textRedefinirNumero' >Editar celular</a>*/}
          </div>
        </div>
        {wppLoading ? <LoadingBasic /> : <>
          {wppErrorMessage && <div className="mt-3" role="alert">
            <div className="alert imob-alert imob-alert-danger" role="alert">
              <i className='bx bx-info-circle'></i>
              <strong> Atenção! </strong> {wppErrorMessage}
            </div>
          </div>}
        </>}
        <form>
          <label className='labelInputOtp'>Digite o código</label>

          <div className='containerOtpInput'>
            {otp.map((data, i) => {
              return <input type='tel'
                value={data}
                maxLength={1}
                onChange={(e) => handleChange(e, i)}
                className='inputOtp' />
            })}
          </div>
          {<p className=" textReenviarCodigo" onClick={sendCodeAgain}>Reenviar código</p>}
        </form>
      </section>
    </>
  );
}

export default Step02;


