// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
    .content-header-desktop {
        display: none;
    }
}
@media only screen and (min-width: 600px) {
    .content-header-mobile {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/property/style.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n    .content-header-desktop {\n        display: none;\n    }\n}\n@media only screen and (min-width: 600px) {\n    .content-header-mobile {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
