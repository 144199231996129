import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import LoadingBasic from "../../../components/loading-basic";
import Contract from "../../../services/contract";

const Step03 = ({ config, setForm, form, switchStep }) => {
    const [updateIndex, setUpdateIndex] = useState([]);
    const [currentForm, setCurrentForm] = useState({ start_at: null, end_at: null, deposit_value: 0, update_index_id: null });
    const [error, setError] = useState({ errorStatus: false, errorMessage: "" });

    useEffect(() => {
        Contract.updateIndexList().then((response) => {
            setUpdateIndex(response.update_index);
            setCurrentForm({ ...currentForm, ...form, update_index_id: response.update_index[0].id });

        })
    }, [])

    const previous = () => {

        switchStep({ ...config, currentStep: 2 });
    }

    const PrazoEInvalido = (start_at, end_at) => {
        const end_at_ok = new Date(start_at);
        end_at_ok.setMonth(end_at_ok.getMonth() + end_at);
        return new Date(end_at_ok) < new Date();
    }

    const next = () => {
        if (!currentForm.end_at && !currentForm.start_at && !currentForm.deposit_value) {
            setError({ ...error, errorStatus: true, errorMessage: "Preencha os campos" })
        }
        else if (!currentForm.start_at) {
            setError({ ...error, errorStatus: true, errorMessage: "Data de início obrigatório" })
        }
        else if (!currentForm.end_at) {
            setError({ ...error, errorStatus: true, errorMessage: "Prazo do contrato obrigatório" })
        } 
        else if((PrazoEInvalido(currentForm.start_at, currentForm.end_at))) {
            setError({ ...error, errorStatus: true, errorMessage: "O contrato deve ter um prazo maior do que a data atual" })
        }
        else {
            setForm({ ...form, ...currentForm })
            switchStep({ ...config, currentStep: 4 });
        }
    }

    return <>
        <span className="modal-step-title start">Legal!</span>
        <p className="modal-step-text">Agora que temos o imóvel e o locatário selecionados, por favor, forneça os seguintes dados para ajudar no controle do contrato:</p>
        {error.errorStatus ?
            <div id="step-03-required" className="w-100">
                <div className="alert imob-alert imob-alert-danger" role="alert">
                    <i className='bx bx-info-circle'></i>
                    <strong> {error.errorMessage}</strong>
                </div>
            </div> : <></>}
        <div className="modal-step-fields without-overflow">
            {updateIndex.length === 0 ? <LoadingBasic /> :
                <div className="d-flex flex-row row">
                    <div className="item w-50">
                        <label htmlFor="start-date" style={{ fontSize: 'medium' }}>Data de início</label>
                        <div className={`input-group mb-3`}>
                            {/* <input  min={new Date().toISOString().split('T')[0]} defaultValue={form.start_at} onChange={(evt) => setCurrentForm({ ...currentForm, start_at: evt.target.value })} id="start-date" name="start-date" type="date" className="form-control input" placeholder="00/00/0000" /> */}
                            <input defaultValue={form.start_at} onChange={(evt) => setCurrentForm({ ...currentForm, start_at: evt.target.value })} id="start-date" name="start-date" type="date" className="form-control input" placeholder="00/00/0000" />
                        </div>
                    </div>
                    <div className="item w-50">
                        <label htmlFor="end-months" style={{ fontSize: 'medium' }}>Prazo do contrato (em meses)</label>
                        <div className={`input-group mb-3`}>
                            <input defaultValue={form.end_at} onKeyUp={(evt) => setCurrentForm({ ...currentForm, end_at: parseInt(evt.target.value) })} id="end-months" name="end-months" type="number" className="form-control input" placeholder="00" />
                        </div>
                    </div>
                    <div className="item w-50">
                        <label htmlFor="deposit-value" style={{ fontSize: 'medium' }}>Caução (em meses)</label>
                        <div className={`input-group mb-3`}>
                            <input defaultValue={form.deposit_value} onKeyUp={(evt) => setCurrentForm({ ...currentForm, deposit_value: parseFloat(evt.target.value ?? 0) })} id="deposit-value" name="deposit-value" type="number" className="form-control input" placeholder="00" />
                        </div>
                    </div>
                    <div className="item w-50">
                        <label htmlFor="update-index" style={{ fontSize: 'medium' }}>Índice de Atualização</label>
                        <div className={`input-group mb-3`}>
                            <select defaultValue={form.update_index_id} onChange={(evt) => setCurrentForm({ ...currentForm, update_index_id: parseInt(evt.target.value) })} name="update-index" className="form-select">
                                {updateIndex.map(item => <option key={item.id} value={item.id}>{item.description}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className="modal-step-actions">
            <Button onClick={previous} customClass='imob-button-neutral' display='Voltar' />
            <Button onClick={next} display="Continuar" />
        </div>
    </>
}

export default Step03;