import Menu from "./menu";
import Nav from "./nav";
import "../../assets/styles/navbar.css";
import "../../assets/styles/layout.css";
import "../../assets/styles/icons.css";
import NavMobile from "./nav-mobile";
import { useAuthUser } from 'react-auth-kit'
import { useEffect } from "react";

const Layout = (props) => {
    const auth = useAuthUser();
    useEffect(() => { if (auth()?.profile?.id != 2) window.location.href = "/sair" }, [])

    return <main id="body-pd">
        <div id="navbar">
            <Nav />
            <NavMobile />
            <Menu />
        </div>
        <main id="content">
            {props.children}
        </main>
    </main>
}

export default Layout;