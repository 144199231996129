export default class Invoice {
    static list = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/invoice-list`, { method: 'POST', body: JSON.stringify(data) });

        return await response.json();
    }

    static details = async (user, id, external = false) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/invoice-detail`, { method: 'POST', body: JSON.stringify({ user, id, external  }) });

        return await response.json();
    }

    static additionTypeList = async () => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/invoice-addition-type-list`, { method: 'POST' });

        return await response.json();
    }

    static addAddition = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/invoice-addition-add`, { method: 'POST', body: JSON.stringify(data) });

        return response.status;
    }

    static update = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/invoice-update`, { method: 'POST', body: JSON.stringify(data) });

        return response.status;
    }

    static sendMessage = async (data) => {
        try{
            fetch(`${process.env.REACT_APP_WHATSAPP_MESSAGE_API_URL}/api/sendText?session=default&phone=${data.phone}&text=${data.text}`, {mode: 'no-cors'}).then((res) => {
                console.log(res)
            })
        } catch (e) {
            console.log(e.message)
        }
    }
}