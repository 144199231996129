import React from "react";
import { useSignOut } from 'react-auth-kit'
import Loading from "../../components/loading";

const Logout = () => {
    const signOut = useSignOut()

    React.useEffect(() => {
        signOut();
    }, [signOut])

    return <Loading />
}

export default Logout;