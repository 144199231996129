import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import LoadingBasic from "../../../components/loading-basic";
import RetangularItem from "../../../components/rectangular-item";
import { useAuthUser } from "react-auth-kit";
import Contract from "../../../services/contract";

const Step00 = () => {
    const auth = useAuthUser();
    const [session, setSession] = useState("");

    useEffect(() => {
        Contract.activeContract(auth().id).then((response) => {
            setSession(response.session)
        });
    }, [])

    return <>
        {session === "" ? <LoadingBasic /> : <>
            <div className="c-step-final justify-content-center p-5">
                <h4 className="text-center">Entenda a mensalidade</h4>
                <p className="text-justify">A assinatura da eu.imob tem o custo fixo de R$50,00 por contrato ativo. O processamento ocorre todo dia 1° no cartão de crédito cadastrado.
                <br />O cadastro é necessário apenas no primeiro contrato. Se houver a necessidade de substitui-lo, você poderá realizar a troca na sessão “Minha conta” {">"} “Dados de pagamento”</p>
                <div className="c-options d-flex flex-column">
                     <a href={session} style={{ color: 'white' }} className="btn c-option my-button">Ativar cartão</a> 
                    <a href="/contracots" className="btn c-option my-button" style={{
                        background: 'transparent',
                        color: '#03619c',
                        marginTop: '5px'
                    }}>Página inicial</a>
                </div>
            </div>
        </>}
        
    </>
}

export default Step00;