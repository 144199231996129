// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.retangular-item {
    border: 2px solid var(--color-background);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    padding: 5px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: .8em;
}

.retangular-item.selected {
    order: -1;
}

.retangular-item.selected, .retangular-item:hover {
    background-color: var(--color-background);
}

.retangular-item span {
    width: 100%;
    text-transform: capitalize;
}

.retangular-item span:first-child {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/retangular-item.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".retangular-item {\n    border: 2px solid var(--color-background);\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 5px 0;\n    padding: 5px 20px;\n    border-radius: 6px;\n    cursor: pointer;\n    font-size: .8em;\n}\n\n.retangular-item.selected {\n    order: -1;\n}\n\n.retangular-item.selected, .retangular-item:hover {\n    background-color: var(--color-background);\n}\n\n.retangular-item span {\n    width: 100%;\n    text-transform: capitalize;\n}\n\n.retangular-item span:first-child {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
