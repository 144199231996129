// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-properties-faturas {
    margin-top: 15%;
}

.filter-fatura-mobile {
    font-size: 1rem !important;
}

.faturaGroup {
    background-color:#e7ebee;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.faturaTitle {
    font-size: 1rem;
}
.faturaData {
    font-size: 1rem;
}
.form-label {
    margin-top: 20px;
}

.form-label {
    margin-top: 20px;
}

@media screen and (max-width: 380px) {
    .filter-fatura-mobile {
        font-size: 0.8rem !important;
    }
}


`, "",{"version":3,"sources":["webpack://./src/views/invoice/shared/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;AACf;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".my-properties-faturas {\n    margin-top: 15%;\n}\n\n.filter-fatura-mobile {\n    font-size: 1rem !important;\n}\n\n.faturaGroup {\n    background-color:#e7ebee;\n    border-radius: 5px;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    width: 100%;\n}\n.faturaTitle {\n    font-size: 1rem;\n}\n.faturaData {\n    font-size: 1rem;\n}\n.form-label {\n    margin-top: 20px;\n}\n\n.form-label {\n    margin-top: 20px;\n}\n\n@media screen and (max-width: 380px) {\n    .filter-fatura-mobile {\n        font-size: 0.8rem !important;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
