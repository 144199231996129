import { useEffect } from "react";

const NavMobile = () => {
    useEffect(() => {
        let action = window.location.pathname.substring(1, window.location.pathname.length) + '/';
        action = action.substring(0, action.indexOf('/'));
        if(!action) action = 'home';
        
        if (document.querySelector('.nav_mobile_link.active') != null) {
            if (document.querySelector(`.nav_mobile_link.${action}`) != null) {
                document.querySelector('.active')?.classList?.toggle('active');
                document.querySelector(`.nav_mobile_link.${action}`)?.classList?.toggle('active');
                document.querySelector(`.nav_mobile_link.${action} i`)?.classList?.forEach(bxClass => {
                    if (bxClass.includes('bx-')) {
                        let newBxClass = bxClass.replace('bx-', 'bxs-');
                        document.querySelector(`.nav_mobile_link.${action} i`)?.classList?.remove(bxClass);
                        document.querySelector(`.nav_mobile_link.${action} i`)?.classList?.add(newBxClass);
                    }
                });
            }
        }
    }, [])

    return <div id="nav-bar nav-bar">
        <nav className="navbar fixed-bottom nav-mobile">
            <a href="/home" className="nav_mobile_link home active">
                <i className='bx bx-home nav_icon'></i>
                <span className="nav_mobile_name">Home</span>
            </a>
            <a href="/contratos" className="nav_mobile_link contratos">
                <i className='bx bx-file nav_icon'></i>
                <span className="nav_mobile_name">Contratos</span>
            </a>
            <a href="/imoveis" className="nav_mobile_link imoveis">
                <i className='bx bx-building-house nav_icon'></i>
                <span className="nav_mobile_name">Imóveis</span>
            </a>
            <a href="/locatarios" className="nav_mobile_link locatarios">
                <i className='bx bx-group nav_icon'></i>
                <span className="nav_mobile_name">Locatários</span>
            </a>
            <a href="/faturas" className="nav_mobile_link faturas">
                <i className='bx bx-wallet-alt nav_icon'></i>
                <span className="nav_mobile_name">Faturas</span>
            </a>
        </nav>
    </div>
}

export default NavMobile;