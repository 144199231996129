import Property from "../../../services/property";
import { useAuthUser } from 'react-auth-kit'
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';

const Step = ({ idReturning, setIdReturning, contractReturn }) => {
    const auth = useAuthUser();

    const [form, setForm] = useState({ id_property_type: 1, created_by: auth().id, is_furnished: false, is_animal_lover: false, is_iptu_included: false, is_water_included: false, is_energy_included: false, is_gas_included: false });
    const [isInvalid, setIsInvalid] = React.useState({ stepTwo: true, stepThree: true, stepFour: true });
    const [submitResult, setSubmitResult] = React.useState({ loading: true, success: true });
    const [propertyTypes, setPropertyTypes] = React.useState([]);
    const [invalidCustomMessage, setInvalidCustomMessage] = React.useState({ CEP: false });

    const cepValidate = (cep) => {
        const formattedCep =  cep.replace(/(\d{5})(\d)/, '$1-$2')
        return formattedCep
    }

    const handleCepChange = (e) => {
        const newCepNumber = e.target.value
        const digitsOnly = newCepNumber.replace(/\D/g, '')
        const limitedDigits = digitsOnly.slice(0, 8);
        let formattedCepNumber = cepValidate(limitedDigits)
        setForm({...form, zipcode: formattedCepNumber})
    }

    const submit = async () => {
        setIsInvalid({ ...isInvalid, stepFour: true });
        if (form.name) {
            document.querySelector('.c-step').classList.toggle('d-none')
    
            const response = await Property.create(form);
    
            document.querySelector(`.c-step-view.c-step-04`).classList.toggle('true');
            document.querySelector(`.c-step-view.c-step-05`).classList.toggle('true');
    
            if (setIdReturning) setIdReturning({ ...idReturning, property_id: response.id, property: true });
            setSubmitResult({ loading: false, success: response.success, id: response.id });
        }
        else {
            setIsInvalid({ ...isInvalid, stepFour: false });
            return false;
        }
    }

    const switchStep = async (step, values) => {
        if (values != null) setForm(values);

        if (await validateStep(step)) {
            document.querySelector(`.c-step-display-0${step + 1}`)?.classList?.toggle('true');
            document.querySelector(`.c-step-view.c-step-0${step}`).classList.toggle('true');
            document.querySelector(`.c-step-view.c-step-0${step + 1}`).classList.toggle('true');
        }
    }

    const validateStep = async (step) => {
        switch (step) {
            case 2:
                setIsInvalid({ ...isInvalid, stepTwo: true });
                if (form.zipcode && form.zipcode.length === 9) {
                    const json = await Property.validateZipcode(form.zipcode)
                    if (json.erro === undefined) {
                        setForm({ ...form, city: json.localidade, state: json.uf, address: json.logradouro })
                        return true;
                    } else {
                        setInvalidCustomMessage({ ...invalidCustomMessage, CEP: true });
                        setIsInvalid({ ...isInvalid, stepTwo: false });
                        return false
                    }
                } else {
                    setIsInvalid({ ...isInvalid, stepTwo: false });
                    return false;
                }
            case 3:
                setIsInvalid({ ...isInvalid, stepThree: true });
                if (form.city && form.state && form.address && form.number) return true;
                else {
                    setIsInvalid({ ...isInvalid, stepThree: false });
                    return false;
                }
            case 4:
                setIsInvalid({ ...isInvalid, stepFour: true });
                if (form.name) return true;
                else {
                    setIsInvalid({ ...isInvalid, stepFour: false });
                    return false;
                }
            default:
                return true;
        }
    }

    useEffect(() => {
        const myModal = document.getElementById('property-creation-modal') ?? document.getElementById(`new-property-modal`);

        myModal.addEventListener('show.bs.modal', () => {
            let dialogModal = document.querySelector('#property-creation-modal .modal-dialog');
            if (window.matchMedia("(max-width: 768px)").matches) {
                if (!dialogModal.classList.contains('modal-fullscreen')) dialogModal.classList.add('modal-fullscreen')
            } else dialogModal.classList.remove('modal-fullscreen')
        });

        Property.listTypes().then(result => {
            if (result.success) setPropertyTypes(result.propertyTypes);
        });

    }, [])

    return <div className="property-creation">
        <div className="c-step">
            <span className="c-step-display c-step-display-01 true">Etapa 1</span>
            <span className="c-step-display c-step-display-02">Etapa 2</span>
            <span className="c-step-display c-step-display-03">Etapa 3</span>
            <span className="c-step-display c-step-display-04"><i className='bx bx-check'></i></span>
        </div>
        <div className="c-step-view c-step-01 true">
            <p>Vamos iniciar o cadastro do seu imóvel?</p>
            <p>O primeiro passo é informar o tipo do imóvel.</p>
            <div className="c-options">
                {propertyTypes.map(type => <button key={type.id} onClick={() => switchStep(1, { ...form, id_property_type: type.id })} className="btn c-option">{type.name}</button>)}
            </div>
            {contractReturn?.active && <div className="c-options-nav d-flex justify-content-center">
                <button onClick={() => contractReturn.callback({ ...contractReturn.config, currentStep: 1 })} className="btn c-prev-step">Voltar para o Contrato</button>
            </div>}
        </div>
        <div className="c-step-view c-step-02">
            <p>Para facilitar o cadastro do endereço, nos informe seu CEP.</p>
            {isInvalid.stepTwo === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        {invalidCustomMessage.CEP ? 
                            <><strong> Preencha um CEP válido</strong> antes de continuar.</> : 
                            <><strong> Preencha o CEP</strong> antes de continuar.</>
                        }
                    </div>
                </div>
            </> : <></>}
            <div className="c-options">
                <input type="text" value={form.zipcode} onChange={handleCepChange} className="form-control c-input-option" placeholder="00000-000" />
            </div>
            <div className="c-options-nav">
                <button onClick={() => switchStep(1)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => switchStep(2)} className="btn c-next-step">Continuar</button>
            </div>
        </div>
        <div className="c-step-view c-step-03">
            <p>Agora, confirme o endereço e nos passe mais alguns detalhes.</p>
            {isInvalid.stepThree === false ? <>
                <div className="alert alert-danger text-center mt-4" role="alert">
                    Preencha todos os campos antes de continuar.
                </div>
            </> : <></>}
            <div className="c-options">
                <div className="mb-3 c-option-box">
                    <label htmlFor="estado" className="form-label">Estado</label>
                    <input defaultValue={form.state ?? ""} disabled={true} id="estado" className="form-control c-input-option-0" placeholder="Ex: São Paulo" type="text" />
                </div>
                <div className="mb-3 c-option-box">
                    <label htmlFor="estado" className="form-label">Cidade</label>
                    <input defaultValue={form.city ?? ""} disabled={true} id="estado" className="form-control c-input-option-0" placeholder="Ex: Guarulhos" type="text" />
                </div>
                <div className="mb-3 c-option-box">
                    <label htmlFor="estado" className="form-label">Logradouro</label>
                    <input defaultValue={form.address ?? ""} disabled={true} id="estado" className="form-control c-input-option-0" placeholder="Ex: Rua X" type="text" />
                </div>
                <div className="mb-3 c-option-box c-option-with-sub">
                    <div className="c-option-sub-box">
                        <label htmlFor="estado" className="form-label">Número</label>
                        <input onKeyUp={(evt) => setForm({ ...form, number: evt.target.value })} id="estado" className="form-control c-input-option-0" placeholder="Ex: 000" type="text" />
                    </div>
                    <div className="c-option-sub-box">
                        <label htmlFor="estado" className="form-label">Complemento</label>
                        <input onKeyUp={(evt) => setForm({ ...form, complement: evt.target.value })} id="estado" className="form-control c-input-option-0" placeholder="Ex: Ap 000" type="text" />
                    </div>
                </div>
            </div>
            <div className="c-options-nav">
                <button onClick={() => switchStep(2)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => switchStep(3)} className="btn c-next-step">Continuar</button>
            </div>
        </div>
        <div className="c-step-view c-step-04">
            <p>Para facilitar as consultas do dia a dia, você gostaria de dar um nome ao seu imóvel?</p>
            {isInvalid.stepFour === false ? <>
                <div className="alert alert-danger text-center mt-4" role="alert">
                    Preencha um apelido antes de continuar.
                </div>
            </> : <></>}
            <div className="c-options">
                <input onKeyUp={(evt) => setForm({ ...form, name: evt.target.value })} className="form-control c-input-option" type="texte" placeholder="Ex: Minha casa x" />
            </div>
            <div className="c-options-nav">
                <button onClick={() => switchStep(3)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => submit()} className="btn c-next-step">Continuar</button>
            </div>
        </div>
        {/* <div className="c-step-view c-step-05 justify-content-center">
            <div className="d-flex flex-column justify-content-between">
                <h2 style={{ color: "var(--color-primary)" }}>Ative seu imóvel!</h2>
                <p>
                    <strong>Pague</strong> apenas <strong>pelos imóveis ativos</strong>. O valor para ativação deste imóvel é de R$xx,xx.
                </p>
                <p>
                    Cadastre seu cartão de crédito abaixo e aproveite os benefícios da Eu.imob!
                    <br /><strong>Lembre-se:</strong> Imóveis inativados não gerarão cobranças.
                </p>
                <p>Insira as informações do cartão</p>
                <div className="mb-3 c-option-box">
                    <label htmlFor="estado" className="form-label">Nome do cartão</label>
                    <input id="card" className="form-control c-input-option-0" type="text" />
                </div>
                <div className="mb-3 c-option-box">
                    <label htmlFor="estado" className="form-label">Número do cartão</label>
                    <input id="card_number" className="form-control c-input-option-0" type="text" />
                </div>
                <div className="mb-3 c-option-box c-option-with-sub row">
                    <div className="c-option-sub-box col-6">
                        <label htmlFor="estado" className="form-label">Data de expiração</label>
                        <div className="d-flex" style={{ width: '40%' }}>
                            <select id="expiration_date_day" className="form-control c-select-option-0 col-2">
                                {Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0')).map((month) => <option key={month} value={month}>{month}</option>)}
                            </select>
                            <select id="expiration_date_month" className="form-control c-select-option-0 col-2" type="text" style={{ marginLeft: '5px' }}>
                                {Array.from({ length: 2040 - 2024 + 1 }, (_, i) => 2024 + i).map((year) => <option key={year} value={year}>{year}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="c-option-sub-box col-6">
                        <label htmlFor="estado" className="form-label">Código (CVV)</label>
                        <input id="cvv" className="form-control c-input-option-0" type="text" />
                    </div>
                </div>
                <div className="c-options-nav flex-column w-100">
                    <button onClick={() => switchStep(5)} className="btn c-next-step col-12 w-100">Ativar imóvel</button>
                    <a href="/imoveis" className="btn c-prev-step col-12 w-100 mt-2">Página inicial</a>
                </div>
            </div>
        </div> */}
        <div className="c-step-view c-step-05 justify-content-center">
            {submitResult.loading ? <div className="spinner-border text-success mt-5" role="status"></div> : submitResult.success ? <div className="box-success">
                <span style={{display: 'flex', justifyContent: 'center', margin: '30px 0', fontSize: '5em', color: '#00A28A'}}><i className='bx bxs-check-circle'></i></span>
                <h4 style={{textAlign: "center"}}>Imóvel cadastrado!</h4>
                <p>Agora seu imóvel está ativo e pronto para aproveitar os benefícios da eu.imob.</p>
                {/* <h5>Parabéns!</h5> */}
                {/* <p>Seu <strong>cartão de crédito </strong>foi <strong>cadastrado com sucesso</strong>. Agora seu imóvel está <strong>ativo</strong> e pronto para aproveitar os benefícios do Eu.imob.<br /> Obrigado por escolher nosso serviço!</p> */}
                <div className="c-options">
                    <a href={`/contratos`} className="btn c-option imob-button w-100">Cadastrar contrato</a>
                    <a href={`/imoveis/detalhe/${submitResult.id}`} className="btn c-option imob-button w-100">Acessar detalhes do imóvel</a>
                    <a href="/imoveis" className="btn c-option imob-button w-100">Página inicial</a>
                </div>
            </div> : <>Erro no cadastro</>}
        </div>
    </div>
}

export default Step;