// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainLayoutNav {
    height: 100vh;
    width: 100%; 
    display: flex; 
    flex-direction: column; 

}

.mainLayoutContent {
    width: 100%;
    height: 88vh; 
    position: relative;
    top: 0; 
    display: flex;
}

@media only screen and (max-width: 380px) {
    .mainLayoutContent {
        height: 84vh;
    }
}


@media only screen and (max-width: 1134px) {
    .mainLayoutNav {
        height: 100%;
        width: 100%; 
        display: flex; 
        flex-direction: column; 
        overflow-y: scroll;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,aAAa;AACjB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;;AAGA;IACI;QACI,YAAY;QACZ,WAAW;QACX,aAAa;QACb,sBAAsB;QACtB,kBAAkB;IACtB;AACJ","sourcesContent":[".mainLayoutNav {\n    height: 100vh;\n    width: 100%; \n    display: flex; \n    flex-direction: column; \n\n}\n\n.mainLayoutContent {\n    width: 100%;\n    height: 88vh; \n    position: relative;\n    top: 0; \n    display: flex;\n}\n\n@media only screen and (max-width: 380px) {\n    .mainLayoutContent {\n        height: 84vh;\n    }\n}\n\n\n@media only screen and (max-width: 1134px) {\n    .mainLayoutNav {\n        height: 100%;\n        width: 100%; \n        display: flex; \n        flex-direction: column; \n        overflow-y: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
