// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.see-detail-eye-icon {
    background: var(--color-primary);
    padding: 7px 8px;
    border-radius: 5px;
    align-self: center;
    justify-self: center;
    margin: 0 10px;
}

.see-detail-eye-icon img {
    width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/icons.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".see-detail-eye-icon {\n    background: var(--color-primary);\n    padding: 7px 8px;\n    border-radius: 5px;\n    align-self: center;\n    justify-self: center;\n    margin: 0 10px;\n}\n\n.see-detail-eye-icon img {\n    width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
