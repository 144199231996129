import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import InputMask from 'react-input-mask';
import InputCurrency from "../../../components/input-currency";
import InputLateFee from "../../../components/input-latefee";
import InputLateInterest from "../../../components/input-lateinterest";

const Step04 = ({ config, setForm, form, switchStep }) => {
    const [currentForm, setCurrentForm] = useState({ rent_value: null, pay_day: null, late_fee: 0, late_interest: 0 });
    const [error, setError] = useState({errorStatus: false, errorMessage: ""})

    useEffect(() => { setCurrentForm({ ...currentForm, ...form }) }, [])

    const previous = () => {
        switchStep({ ...config, currentStep: 3 });
    }

    const next = () => {
        /*if (currentForm.rent_value && currentForm.pay_day) {
            setForm({ ...form, ...currentForm });
            switchStep({ ...config, currentStep: 5 });
            console.log(currentForm)
        }
        else {
            setError({...error, errorStatus: true, errorMessage:"Preencha todos os campos antes de continuar"})
        }*/
        if (!currentForm.rent_value && !currentForm.pay_day) {
            setError({...error, errorStatus: true, errorMessage:"Preencha todos os campos antes de continuar"})
        } else if(!currentForm.rent_value) {
            setError({...error, errorStatus: true, errorMessage:"Valor mensal da locação obrigatório "})
        }
        else if(!currentForm.pay_day) {
            setError({...error, errorStatus: true, errorMessage:"Dia do pagamento obrigatório"})
        } else {
            setForm({ ...form, ...currentForm });
            switchStep({ ...config, currentStep: 5 });
            console.log(currentForm)
        }
    }
    const dataValidate = (data) => {
        const formattedData =  data.replace(/^(?:(?:31\/(?:0?[13578]|1[02]))\/|(?:(?:29|30)\/(?:0?[13-9]|1[0-2])\/))(?:1[6-9]|[2-9]\d)?\d{2}$/)
        return formattedData
    }
    const handleDataChange = (e) => {
        const newCepNumber = e.target.value
        const digitsOnly = newCepNumber.replace(/\D/g, '')
        const limitedDigits = digitsOnly.slice(0, 8); 
        let formattedCepNumber = dataValidate(limitedDigits)
        setForm({...form, zipcode: formattedCepNumber})
    }

    return <>
        <p className="modal-step-text">Para que possamos gerar as faturas mensalmente, por favor, nos forneça os seguintes dados:</p>
        {error.errorStatus ?<div id="step-required" className=" w-100">
            <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong> {error.errorMessage}</strong> 
            </div>
        </div> : <></>}
        
        <div className="modal-step-fields without-overflow">
            <div className="d-flex flex-row row">
                <div className="item w-50">
                    <label htmlFor="rent-value" style={{fontSize: 'medium'}}>Valor Mensal da Locação</label>
                    <div className={`input-group mb-3`}>
                        <InputCurrency defaultValue={form.rent_value} onKeyUp={(evt) => setCurrentForm({ ...currentForm, rent_value: parseFloat(evt.target.value.replace("R$ ", "").replace(".", "").replace(",", ".")) })} id="rent-value" name="rent-value" type="text" className="form-control input" placeholder="R$ 00.000,00"/>
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="pay-day" style={{fontSize: 'medium'}}>Dia de Vencimento</label>
                    <div className={`input-group mb-3`}>
                        <input defaultValue={form.pay_day} onChange={(evt) => setCurrentForm({ ...currentForm, pay_day: parseInt(evt.target.value) })} id="pay-day" name="pay-day"  className="form-control input" type="number" placeholder="00/00/0000" />
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="late-fee" style={{fontSize: 'medium'}}>Multa por Atraso</label>
                    <div className={`input-group mb-3`}>
                        <InputLateFee defaultValue={form.late_fee} onKeyUp={(evt) => setCurrentForm({ ...currentForm, late_fee: parseFloat(evt.target.value.replace(" %", "").replace(",", ".")) })} id="late-fee" name="late-fee" type="text" className="form-control input" placeholder="00%"/>
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="late-interest" style={{fontSize: 'medium'}}>Taxa de Juros por Atraso</label>
                    <div className={`input-group mb-3`}>
                        <InputLateInterest defaultValue={form.late_interest} onKeyUp={(evt) => setCurrentForm({ ...currentForm, late_interest: parseFloat(evt.target.value.replace(" a.m. %", "").replace(",", ".")) })} id="late-interest" name="late-interest" type="text" className="form-control input" placeholder="01 a.m. %"/>
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="rent-value" style={{fontSize: 'medium'}}>Valor do Condomínio</label>
                    <div className={`input-group mb-3`}>
                        <InputCurrency defaultValue={form.condominium_value} onKeyUp={(evt) => setCurrentForm({ ...currentForm, condominium_value: parseFloat(evt.target.value.replace("R$ ", "").replace(".", "").replace(",", ".")) })} id="condominium-value" name="condominium-value" type="text" className="form-control input" placeholder="R$ 00.000,00"/>
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="rent-value" style={{fontSize: 'medium'}}>Valor do IPTU</label>
                    <div className={`input-group mb-3`}>
                        <InputCurrency defaultValue={form.iptu_value} onKeyUp={(evt) => setCurrentForm({ ...currentForm, iptu_value: parseFloat(evt.target.value.replace("R$ ", "").replace(".", "").replace(",", ".")) })} id="iptu-value" name="iptu-value" type="text" className="form-control input" placeholder="R$ 00.000,00" />
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-step-actions">
            <Button onClick={previous} customClass='imob-button-neutral' display='Voltar' />
            <Button onClick={next} display="Continuar" />
        </div>
    </>
}

export default Step04;