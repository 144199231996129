import React, { useEffect } from "react";

import Logo from "../assets/images/logo_color.svg"
import "../assets/styles/modal-step.css";

const ModalStep = ({ feature, featureDisplay, steps, activeStep, view, disableLabel }) => {
    useEffect(() => {
        document.getElementById(`new-${feature}-modal`).addEventListener('show.bs.modal', () => {
            let dialogModal = document.querySelector(`#new-${feature}-modal .modal-dialog`);
            if (window.matchMedia("(max-width: 768px)").matches) {
                if (!dialogModal.classList.contains('modal-fullscreen')) dialogModal.classList.add('modal-fullscreen')
            } else dialogModal.classList.remove('modal-fullscreen')
        });
    }, [])

    return <div className="modal fade" id={`new-${feature}-modal`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`new-${feature}-modal`} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close-modal" data-bs-dismiss="modal" aria-label="Close"><i className='bx bx-x'></i></button>
                </div>
                <div className={`modal-body new-${feature}`}>
                    {disableLabel ? <>
                        {view}
                    </> :
                        <>
                            <h5 className="title">Cadastro de {featureDisplay}</h5>
                            {steps != null ? <div className="modal-step-list">{Array.from(Array(parseInt(steps)).keys()).map((step, index, origin) => <span key={index} data-active={parseInt(activeStep) >= step + 1 ? "true" : "false"}>{origin.indexOf(step) < (origin.length - 1) ? `Etapa ${index + 1}` : <i className='bx bx-check'></i>}</span>)}</div> : <></>}
                            <div className="modal-step-view"> {view} </div>
                        </>
                    }
                </div>
                <div className="modal-footer modal-step-footer">
                    <img src={Logo} />
                </div>
            </div>
        </div>
    </div>
}

export default ModalStep;