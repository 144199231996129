import React, { useEffect, useState } from "react";
import { useAuthUser } from 'react-auth-kit'

import Property from "../../../services/property";
import Tenant from "../../../services/tenant";

import ModalStep from "../../../components/modal-step";

import Step from "../../property/new/step";
import StepTenant from "../../tenant/new/step";
import StepBankAccount from "../../bank-account/new/step-1";

import Step00 from "./step-0";
import Step01 from "./step-1";
import Step02 from "./step-2";
import Step03 from "./step-3";
import Step04 from "./step-4";
import Step05 from "./step-5";
import Step06 from "./step-6";
import Contract from "../../../services/contract";

const New = () => {
    const auth = useAuthUser();

    const [modalConfig, setModalConfig] = useState({ disableLabels: false, feature: 'contract', display: 'contrato', steps: 6, currentStep: 1, update: 0 });
    const [statics, setStatics] = useState({ properties: {}, tenants: {} });
    const [idReturning, setIdReturning] = useState({ property_id: null, property: false, tenant_id: null, tenant: false, bank_account_id: null, bank_account: false });
    const [form, setForm] = useState({ property_id: null, tenant_id: null, bank_account_id: null });
    const [control, setControl] = useState(true);

    const [view, setView] = useState();

    const defineStatics = (evt) => {
        setStatics(evt);
        setModalConfig({ ...modalConfig, update: modalConfig.update + 1 });
    }

    useEffect(() => {
        // Contract.needSetupRecurrence(btoa(auth().id)).then((has) => {
        // if (!has) setView(<Step00 />)
        // else {
        setModalConfig({ ...modalConfig, disableLabels: false });
        setView(<Step01 properties={{ loading: true }} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
        document.getElementById(`new-${modalConfig.feature}-modal`).addEventListener("shown.bs.modal", () => {
            Property.listByUser(auth().id, "", false, true, true)
                .then(result => defineStatics({ ...statics, properties: { loading: false, items: result.properties, original: result.properties } }))
                .catch(_ => defineStatics({ ...statics, properties: { loading: false, items: [], original: [] } }))
        });

        document.getElementById(`new-${modalConfig.feature}-modal`).addEventListener("hide.bs.modal", () => {
            window.location.reload();
        });
        // }
        // })
    }, [])

    useEffect(() => {
        if (!control) {
            switch (modalConfig.currentStep) {
                case 1:
                    setModalConfig({ ...modalConfig, disableLabels: false });
                    setView(<Step01 properties={statics.properties} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                    break;
                case 2:
                    setView(<Step02 tenants={{ loading: true }} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                    Tenant.listByUser(auth().id, "", false, true)
                        .then(result => {
                            let item = { loading: false, items: result.users, original: result.users };
                            setStatics({ ...statics, tenants: item });
                            setView(<Step02 tenants={item} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />)
                        })
                        .catch(_ => {
                            let item = { loading: false, items: [], original: [] }
                            setStatics({ ...statics, tenants: item });
                            setView(<Step02 tenants={item} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />)
                        })
                    break;
                case 3:
                    setView(<Step03 config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                    break;
                case 4:
                    setView(<Step04 config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                    break;
                case 5:
                    setView(<Step05 config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                    break;
                case 6:
                    setView(<Step06 config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                    break;
                case 10:
                    setModalConfig({ ...modalConfig, feature: 'property', display: 'Imóvel', steps: null });
                    setView(<Step idReturning={idReturning} setIdReturning={setIdReturning} contractReturn={{ active: true, callback: setModalConfig, config: modalConfig }} />)
                    break;
                case 11:
                    setModalConfig({ ...modalConfig, feature: 'tenant', display: 'Locatário', steps: null });
                    setView(<StepTenant idReturning={idReturning} setIdReturning={setIdReturning} contractReturn={{ active: true, callback: setModalConfig, config: modalConfig }} />)
                    break;
                case 12:
                    setModalConfig({ ...modalConfig, feature: 'bank-account', display: 'Conta Bancária', steps: null });
                    setView(<StepBankAccount idReturning={idReturning} setIdReturning={setIdReturning} contractReturn={{ active: true, callback: setModalConfig, config: modalConfig }} />)
                    break;
                default:
                    setView("")
                    break;
            }
        }
        setControl(false);
    }, [modalConfig.currentStep, modalConfig.update, form])

    useEffect(() => {
        if (!control) setView(<Step02 tenants={statics.tenants} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
    }, [form.tenant_id])

    useEffect(() => {
        if (!control) {
            if (idReturning.bank_account) {
                setForm({ ...form, bank_account_id: idReturning.bank_account_id });
                console.log(idReturning)
                setTimeout(() => {
                    setView(<Step05 config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                    setModalConfig({ ...modalConfig, feature: 'contract', display: 'contrato', steps: 6, currentStep: 5 });
                }, 1000);
            }
            else if (idReturning.tenant) {
                setView(<Step02 tenants={{ loading: true }} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                setTimeout(() => {
                    setModalConfig({ ...modalConfig, feature: 'contract', display: 'contrato', steps: 6, currentStep: 2 });
                }, 600);
                setTimeout(() => {
                    setForm({ ...form, tenant_id: idReturning.tenant_id });
                }, 1000);
            }
            else if (idReturning.property) {
                setForm({ ...form, property_id: idReturning.property_id });
                setView(<Step01 properties={{ loading: true }} config={modalConfig} setForm={setForm} form={form} switchStep={setModalConfig} />);
                Property.listByUser(auth().id, "", false, true, true)
                    .then(result => defineStatics({ ...statics, properties: { loading: false, items: result.properties, original: result.properties } }))
                    .catch(_ => defineStatics({ ...statics, properties: { loading: false, items: [], original: [] } }))
                setTimeout(() => {
                    setModalConfig({ ...modalConfig, feature: 'contract', display: 'contrato', steps: 6, currentStep: 1 });
                }, 500);
            }
        }
    }, [idReturning])

    return <>
        <ModalStep
            disableLabel={modalConfig.disableLabels}
            feature={modalConfig.feature}
            featureDisplay={modalConfig.display}
            steps={modalConfig.steps}
            activeStep={modalConfig.currentStep}
            view={view}
        ></ModalStep>
    </>
}

export default New;