import { createBrowserRouter } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";

import Index from "./views";

/** Feature: Usuário */
import Login from "./views/user/login/login";
import Logout from "./views/user/logout";
import Register from "./views/user/register/index";
import Profile from "./views/user/profile/profile";

/** Feature: Imóveis */
import Property from "./views/property/property";
import PropertyDetail from "./views/property/detail";

/** Feature: Locatário */
import Tenant from "./views/tenant/tenant";
import TenantDetail from "./views/tenant/detail";

/** Feature: Contrato */
import Contracts from "./views/contract/index";
import Contract from "./views/contract/contract";
import UpdateBank from "./views/bank-account/update";

/** Feature: Faturas */
import Invoices from "./views/invoice";
import Invoice from "./views/invoice/invoice";
import ExternalInvoice from "./views/invoice/external";
import Home from "./views/user/home/home";
import PassOut from "./views/administration/pass-out";
import ForgotPassword from "./views/user/forgot-password/forgot-password";
import DeleteAccount from "./views/user/delete-account/delete";

const routes = [];

/** Usuários */
routes.push(
  // { path: "/", element: <Index /> },
  { path: "/", element: <Login /> },
  { path: "/esqueci-minha-senha", element: <ForgotPassword /> },
  { path: "/deletar-conta", element: <DeleteAccount/> },
  {
    path: "/home",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Home />{" "}
      </RequireAuth>
    ),
  },
  { path: "/entrar", element: <Login /> },
  { path: "/registrar", element: <Register /> },
  {
    path: "/sair",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Logout />
      </RequireAuth>
    ),
  },
  {
    path: "/conta",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Profile />{" "}
      </RequireAuth>
    ),
  }
);

/** Imóveis */
routes.push(
  {
    path: "/imoveis",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Property />
      </RequireAuth>
    ),
  },
  {
    path: "/imoveis/detalhe/:id",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <PropertyDetail />
      </RequireAuth>
    ),
  }
);

/** Locatário */
routes.push(
  {
    path: "/locatarios",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Tenant />
      </RequireAuth>
    ),
  },
  {
    path: "/locatarios/detalhe/:id",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <TenantDetail />
      </RequireAuth>
    ),
  }
);

/** Contratos */
routes.push(
  {
    path: "/contratos",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Contracts />
      </RequireAuth>
    ),
  },
  {
    path: "/contratos/detalhe/:id",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Contract />
      </RequireAuth>
    ),
  },
  {
    path: "/conta/atualizar/:reference",
    element: <UpdateBank />
  }
);

/** Faturas */
routes.push(
  {
    path: "/faturas",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Invoices />
      </RequireAuth>
    ),
  },
  {
    path: "/faturas/detalhe/:id",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <Invoice />
      </RequireAuth>
    ),
  },
  {
    path: "/r/:id",
    element: <ExternalInvoice />,
  }
);

/** Admin */

routes.push(
  {
    path: "/admin/repasse",
    element: (
      <RequireAuth loginPath={"/entrar"}>
        <PassOut />
      </RequireAuth>
    ),
  }
);

const Controller = createBrowserRouter(routes);

export default Controller;
