import { useAuthUser } from 'react-auth-kit';
import ProfilePhoto from "../../assets/images/profile.jpg";

const Menu = () => {
    const auth = useAuthUser();

    const toggle = () => {
        const toggle = document.getElementById('header-toggle'),
            nav = document.getElementById('nav-bar'),
            bodypd = document.getElementById('body-pd'),
            headerpd = document.getElementById('header');

        nav.classList.toggle('show');
        toggle.classList.toggle('bx-x');
        bodypd.classList.toggle('body-pd');
        headerpd.classList.toggle('body-pd');
    }

    return <header className="header" id="header">
        <div className="header_toggle"> <i className='bx bx-menu' id="header-toggle" onClick={() => toggle()}></i> </div>
        <div className='header_name' style={{ cursor: 'pointer' }} onClick={() => window.location = '/sair'}> <span>Sair</span> </div>
        <div className='header_ring'><i className='bx bx-bell'></i></div>
    </header>
}

export default Menu;