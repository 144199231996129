// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-properties-imoveis {
    margin-top: 15%;
}

.filter-imovel-mobile {
    font-size: small !important;
}
.imovel-info {
    background-color:#e7ebee;
    border-radius: 5px;
}
.imovel-title {
    font-size: 1rem;
}
.imovel-data {
    font-size: 1rem;
    text-align: left;
}

.dataBodyImoveis tr:hover .databodyImoveisItem {
    background-color: rgba(88, 88, 88, 0.1);
    cursor: pointer;
    transition: 0.2s ease;
}

@media screen and (max-width: 380px) {
    .filter-imovel-mobile {
        font-size: 0.70rem !important;
    } 
    .imovel-title {
        font-size: 1rem;
    }
    .imovel-data {
        font-size: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/property/shared/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;AACA;IACI,wBAAwB;IACxB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,uCAAuC;IACvC,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI;QACI,6BAA6B;IACjC;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".my-properties-imoveis {\n    margin-top: 15%;\n}\n\n.filter-imovel-mobile {\n    font-size: small !important;\n}\n.imovel-info {\n    background-color:#e7ebee;\n    border-radius: 5px;\n}\n.imovel-title {\n    font-size: 1rem;\n}\n.imovel-data {\n    font-size: 1rem;\n    text-align: left;\n}\n\n.dataBodyImoveis tr:hover .databodyImoveisItem {\n    background-color: rgba(88, 88, 88, 0.1);\n    cursor: pointer;\n    transition: 0.2s ease;\n}\n\n@media screen and (max-width: 380px) {\n    .filter-imovel-mobile {\n        font-size: 0.70rem !important;\n    } \n    .imovel-title {\n        font-size: 1rem;\n    }\n    .imovel-data {\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
