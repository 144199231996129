import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'
import Property from '../../../services/property';
import Loading from '../../../components/loading';
import "./style.css"

const ListMobile = () => {
    const auth = useAuthUser();

    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        Property.listByUser(auth().id, search, true).then(result => {
            setProperties(result.properties);
            setLoading(false);
        });
    }, []);

    const searchProperties = (evt, all = true, is_active = true) => {

        //setLoading(true);
        Property.listByUser(auth().id, search, all, is_active).then(result => {
            setProperties(result.properties);
            setLoading(false);
            setTimeout(() => {
                document.querySelector('.mobile-filter.c-custom').classList.toggle('c-custom');

                if (all || !Array.from(evt.target.classList).includes('mobile-filter')) document.querySelector('.mobile-filter.ver-todos').classList.toggle('c-custom');
                else if (is_active === false) document.querySelector('.mobile-filter.inativos').classList.toggle('c-custom');
            }, 100);
        });
    }

    return loading ? <Loading /> :
        <div className="container-fluid my-properties-imoveis d-md-none d-block">
            <div className='d-flex justify-content-around' style={{ alignItems: "center" }}>
                <div className="input-group mb-3 my-mobile-search-box">
                    <span onClick={(evt) => searchProperties(evt)} className="input-group-text"><i className='bx bx-search'></i></span>
                    <input onKeyUp={(e) => {
                        setSearch(e.target.value);
                        searchProperties()
                    }}
                        type="text"
                        className="form-control"
                        placeholder="Procurar Imóvel" />
                    <button data-bs-toggle="modal" data-bs-target="#property-creation-modal" className='btn btn-success my-button my-mobile-button'>
                        <i className='bx bx-plus' ></i>
                    </button>
                </div>
            </div>
            <div className='my-propertis-mobile-container'>
                <div className='mobile-filters'>
                    <span className='mobile-filter filter-imovel-mobile'>Alugados</span>
                    <span className='mobile-filter filter-imovel-mobile'>Disponíveis</span>
                    <span onClick={(evt) => searchProperties(evt, false, false)} className='mobile-filter inativos filter-imovel-mobile'>Inativos</span>
                    <span onClick={(evt) => searchProperties(evt, true, true)} className='mobile-filter ver-todos c-custom filter-imovel-mobile'>Ver todos</span>
                </div>
                <div className='mobile-list-items'>
                    {properties.length > 0 ? properties.map(item => <div onClick={() => window.location.href = `/imoveis/detalhe/${item.id}`} className='mobile-list-item p-3' key={item.id}>
                        {/* <div className="dropdown c-mobile-action-list">
                            <button className="my-button-neutral-0 " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className='bx bx-dots-horizontal-rounded' ></i>
                            </button>
                            <ul className="dropdown-menu my-dropdown">
                                <li><a className="dropdown-item" href={`/imoveis/detalhe/${item.id}`}><i className='bx bx-edit-alt'></i><span>Editar</span></a></li>
                                <li><a className="dropdown-item" href="#"><i className='bx bx-trash'></i><span>Excluir</span></a></li>
                            </ul>
                        </div> */}
                        <div className='property-mobile-details-list imovel-info '>
                            <div className='c-attr-group' >
                                <div className='c-attr'>
                                    <span className='c-title imovel-title'>Apelido:</span>
                                    <span className='c-content imovel-data'>{item.name}</span>
                                </div>
                                <div className='c-attr'>
                                    <span className='c-title imovel-title'>Endereço:</span>
                                    <span className='c-content imovel-data'>{item.address}</span>
                                </div>
                            </div>
                            <div className='c-attr-group'>
                                <div className='c-attr-sub-group'>
                                    <div className='c-attr'>
                                        <span className='c-title imovel-title'>Tipo:</span>
                                        <span className='c-content imovel-data'>{item.type.name}</span>
                                    </div>
                                    <div className='c-attr'>
                                        <span className='c-title imovel-title'>UF:</span>
                                        <span className='c-content imovel-data'>{item.state}</span>
                                    </div>
                                </div>

                                <div className='c-attr'>
                                    <span className='c-title imovel-title'>Cidade:</span>
                                    <span className='c-content imovel-data'>{item.city}</span>
                                </div>
                            </div>
                        </div>
                        <div className='c-attr c-attr-group-custom' style={{ display: 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'flexDirection': 'column', 'padding': '10px' }}>
                            <span className='c-title'>Status:</span>
                            <span className='c-content' style={{ marginTop: '10px' }}>
                                <span className='c-tag-warning'>Pendente</span>
                            </span>
                        </div>
                    </div>
                    ) : <div className='text-center' style={{ marginTop: '120px' }}><h1><i className='bx bx-sad'></i></h1><span className=''>Nenhum imóvel encontrado...</span></div>}
                </div>
            </div>
        </div>
}

export default ListMobile;