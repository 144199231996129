export default class Property {
    static listByUser = async (user, search, all, is_active, without_contract_active = false) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-property-list`, { method: 'POST', body: JSON.stringify({ user, search, all, is_active, without_contract_active }) })

        return await response.json();
    }

    static create = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-property-add`, { method: 'POST', body: JSON.stringify(data) })

        return await response.json();
    }

    static listTypes = async () => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-property-type-list`, { method: 'GET' })

        return await response.json();
    }

    static detail = async (id, user) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-property-details`, { method: 'POST', body: JSON.stringify({ id, user }) });

        return await response.json();
    }

    static update = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-property-edit`, { method: 'POST', body: JSON.stringify(data) })

        return response.status;
    }

    static validateZipcode = async (zipcode) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${sanitize(zipcode)}/json`);
            
            if(response.ok) {
                const json = await response.json();
                return json;
            }

            return false;
        } catch (error) {
            return false;
        }
    }
}

function sanitize(str) {
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "");
    return str.trim();
}