import { useParams } from "react-router-dom";
import { useAuthUser } from 'react-auth-kit'
import { useEffect, useState } from "react";
import Layout from "../../components/layout/layout";
import Loading from "../../components/loading";
import Property from "../../services/property";
import "../../assets/styles/property.css";
import "./style.css";
import { BsThreeDots } from "react-icons/bs";

const Detail = () => {
    const auth = useAuthUser();
    let { id } = useParams();
    const [property, setProperty] = useState(null);
    const [types, setTypes] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!id) window.location.href = '/imoveis';
        else {
            Property.listTypes().then(response => {
                setTypes(response.propertyTypes);
                Property.detail(id, auth().id).then(response => {
                    if (!response.success) window.location.href = '/imoveis';
                    else {
                        setProperty(response.property);
                    }
                });
            });
        }
    }, [])

    /* const loadZipcode = (zipcode) => {
         if (zipcode && zipcode.length >= 8) {
             setLoading(true);
             Property.validateZipcode(zipcode).then((json) => {
                 if (json) {
                     console.log(json)
                     setProperty({ ...property, city: json.localidade, state: json.uf, address: json.logradouro, zipcode: json.cep })
                     setLoading(false);
                 }
             });
         } else {
             setError("Cep inválido")
         }
     }*/
    const cepValidate = (cep) => {
        const formattedCep = cep.replace(/(\d{5})(\d)/, '$1-$2')
        return formattedCep
    }

    const handleCepChange = (e) => {
        const newCepNumber = e.target.value
        const digitsOnly = newCepNumber.replace(/\D/g, '')
        const limitedDigits = digitsOnly.slice(0, 8);
        let formattedCepNumber = cepValidate(limitedDigits)
        setProperty({ ...property, zipcode: formattedCepNumber })
    }


    const update_all_property = (is_active) => {
        Property.validateZipcode(property.zipcode)
            .then((res) => {
                if (res.erro === true || property.zipcode.length <= 8) {
                    setError("Cep inválido")
                } else {
                    setProperty({ ...property, city: res.localidade, state: res.uf, address: res.logradouro, zipcode: res.cep })

                    if (!property.name || property.name.length <= 2) {
                        setError("Apelido inválido")
                    }
                    else if (!property.number || property.number === "0") {
                        setError("Número inválido. Por favor, insira um número válido.");
                    } else {
                        window.location.reload()
                        setLoading(true);
                        Property.update({
                            ...property,
                            is_active: is_active != null ? is_active : property.is_active,
                            id_property_type: parseInt(property.id_property_type),
                            complement: property.complement ?? "",
                            updated_by: auth().id
                        }).then(response => {
                            if (response === 204) {
                                setLoading(false);
                                setError(false);
                                window.location.reload()
                            } else {
                                setLoading(false);
                                setError(true);
                            }
                        }).catch(error => {
                            setLoading(false);
                            setError(true);

                        });
                    }
                }
            }).catch(error => {
                setError("Erro ao validar o CEP");

            });
    };

    return <Layout>
        {property === null || loading ? <Loading /> : <div className="container-fluid" style={{ marginTop: '0', marginBottom: '90px', overflowY: 'scroll' }}>
            <div className='my-properties-container p-5 property-details'>
                <div className="topo d-flex justify-content-between topo flex-row">
                    <h4 className="content-header-title d-flex align-items-center gap-2"><i className='bx bx-user-pin'></i> Detalhes de Imóvel</h4>
                    <div className="content-header-actions content-header-desktop">
                        {/* <Button onClick={() => submit(!form.is_active)} customClass={"imob-button-outline"} display={form.is_active ? "Inativar" : "Ativar"} icon={<RemoveFile />} /> */}
                        {/* <Button onClick={() => setEdit(true)} customClass={"imob-button-outline"} display="Editar" icon={<Pencil />} /> */}
                        <button className="btn imob-button imob-button-outline" onClick={() => update_all_property(!property.is_active)}>
                            {property.is_active ? <><i className='bx bx-block'></i> Inativar</> : <><i className='bx bx-lock-open-alt'></i> Ativar</>}
                        </button>
                        <button onClick={() => setEdit(!edit)} className="btn imob-button imob-button-outline">
                            <i className='bx bx-edit-alt'></i>
                            Editar
                        </button>
                    </div>
                    <div className="content-header-actions-mobile content-header-mobile">
                        <button className="imob-button-neutral imob-button-neutral-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <BsThreeDots size={25} />
                        </button>
                        <ul className="dropdown-menu my-dropdown">
                            <li><a className="dropdown-item" onClick={() => setEdit(!edit)} href={`#`}><i className='bx bx-edit-alt'></i><span>Editar</span></a></li>
                            <li><a className="dropdown-item" onClick={() => update_all_property(!property.is_active)} href="#"><i className='bx bx-lock-open-alt'></i><span>{property.is_active ? "Inativar" : "Ativar"}</span></a></li>
                        </ul>
                    </div>
                    {/* <div className="content-header-actions-mobile">
                        <button className="imob-button-neutral imob-button-neutral-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='bx bx-dots-horizontal-rounded dots-icon' ></i>
                        </button>
                        <ul className="dropdown-menu my-dropdown">
                            <li><a className="dropdown-item" onClick={() => setEdit(true)} href={`#`}><i className='bx bx-edit-alt'></i><span>Editar</span></a></li>
                            <li><a className="dropdown-item" onClick={() => update_all_property(!property.is_active)} href="#"><i className='bx bx-lock-open-alt'></i><span>{property.is_active ? "Inativar" : "Ativar"}</span></a></li>
                        </ul>
                    </div>*/}
                    {/* <div className="actions c-detail-actions">
                        <div>
                            <button onClick={() => setEdit(true)} className="btn my-button-outline"><i className='bx bx-edit-alt'></i> Editar</button>
                            <button className="btn my-button-outline" onClick={() => update_all_property(!property.is_active)}>
                                {property.is_active ? <><i className='bx bx-block'></i> Inativar</> : <><i className='bx bx-lock-open-alt'></i> Ativar</>}
                            </button>
                        </div>
                        <button className="btn btn-success my-button my-mobile-button"><i className='bx bx-plus' ></i></button>
                    </div>  */}
                </div>
                <hr />
                <div className="d-flex justify-content-start flex-column flex-lg-row">
                    <div className="m-3 input-100 m-auto">
                        {error ? <div className="alert alert-danger text-center mt-4" role="alert">
                            <i className='bx bx-info-circle'></i>
                            <strong> Atenção! </strong> {error}
                        </div> : <></>}
                    </div>
                </div>
                <div className="d-flex justify-content-start flex-column flex-lg-row">
                    <div className="m-3 input-100">
                        <label htmlFor="apelido" className="form-label">Apelido:</label>
                        <input disabled={edit ? false : 'disabled'} onKeyUp={(evt) => { setProperty({ ...property, name: evt.target.value }) }} defaultValue={property.name} type="text" className="form-control" id="apelido" />
                    </div>
                    <div className="m-3 select-width">
                        <label htmlFor="tipo" className="form-label">Tipo:</label>
                        <select disabled={edit ? false : 'disabled'} className="form-select" defaultValue={property.type.id} onChange={(evt) => setProperty({ ...property, id_property_type: evt.target.value })}>
                            {types.length > 0 ? types.map(type => <option key={type.id} value={type.id}>{type.name}</option>) : <></>}
                        </select>
                    </div>
                </div>
                <div className="d-flex justify-content-start flex-column flex-lg-row">
                    <div className="m-3 input-100">
                        <label htmlFor="cep" className="form-label">CEP:</label>
                        <input disabled={edit ? false : 'disabled'} onChange={handleCepChange} value={property.zipcode} type="text" className="form-control" id="cep" />
                    </div>
                    <div className="m-3 input-short">
                        <label htmlFor="uf" className="form-label">UF:</label>
                        <input disabled={true} onKeyUp={(evt) => setProperty({ ...property, state: evt.target.value })} defaultValue={property.state} type="text" className="form-control" id="uf" />
                    </div>
                    <div className="m-3 input-short">
                        <label htmlFor="cidade" className="form-label">Cidade:</label>
                        <input disabled={true} onKeyUp={(evt) => setProperty({ ...property, city: evt.target.value })} defaultValue={property.city} type="text" className="form-control" id="cidade" />
                    </div>

                </div>
                <div className="d-flex justify-content-start flex-column flex-lg-row">
                    <div className="m-3 input-100">
                        <label htmlFor="endereco" className="form-label">Endereço:</label>
                        <input disabled={true} value={property.address} type="text" className="form-control" id="endereco" />
                    </div>
                    <div className="m-3 input-short">
                        <label htmlFor="numero" className="form-label">Nº:</label>
                        <input disabled={edit ? false : 'disabled'} onKeyUp={(evt) => setProperty({ ...property, number: evt.target.value })} defaultValue={property.number} type="text" className="form-control" id="numero" />
                    </div>
                    <div className="m-3 select-short">
                        <label htmlFor="complemento" className="form-label">Complemento:</label>
                        <input disabled={edit ? false : 'disabled'} onKeyUp={(evt) => setProperty({ ...property, complement: evt.target.value })} defaultValue={property.complement} type="text" className="form-control" id="complemento" />
                    </div>
                </div>
                {/* <div className="d-flex justify-content-start flex-column flex-lg-row">
                    <div className="m-3 anexo">
                        <div className="topo">
                            <label htmlFor="cidade" className="form-label"><i className='bx bx-cloud-upload' ></i> Anexar arquivo</label>
                            <button className="btn"><i className='bx bx-plus'></i></button>
                        </div>
                        <div className="box">
                            <button className="btn"><i className='bx bx-plus' ></i> Novo Anexo</button>
                        </div>

                    </div>
                </div> */}
                <div className="d-flex justify-content-center">
                    <button disabled={edit ? false : 'disabled'} className="btn imob-button" onClick={() => update_all_property()}>Confirmar</button>
                </div>
            </div>

        </div>}
    </Layout>
}

export default Detail;