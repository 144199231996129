import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'

import Loading from '../../../components/loading';
import Contract from '../../../services/contract';
import "./style.css";
import ExplanationImage from './explanation';

const ListMobile = () => {
    const auth = useAuthUser();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [isActive, setIsActive] = useState(null);
    const [firstContract, setFirstContract] = useState(false)

    useEffect(() => {
        setLoading(false);
        Contract.contractList({ search: searchCriteria, user: auth().id, is_active: isActive }).then(response => {
            setData(response.contracts);
            if(!response?.contracts?.length > 0) setFirstContract(true)
        })
    }, [])

    const colorFilters = () => {
        let filters = document.querySelectorAll(".mobile-filter");

        if (filters) {
            filters.forEach(element => {
                element.classList.remove('c-custom');

                if (isActive && element.classList.contains('ativos')) element.classList.add('c-custom');
                else if ((!isActive && isActive != null) && element.classList.contains('inativos')) element.classList.add('c-custom');
                else if (isActive === null && element.classList.contains('ver-todos')) element.classList.add('c-custom');
            });
        }
    }

    const search = () => {
        setLoading(true);
        Contract.contractList({ search: searchCriteria, user: auth().id, is_active: isActive }).then(response => {
            setData(response.contracts);
            setLoading(false);
            setTimeout(() => {
                colorFilters()
            }, 1);
        })
    }

    useEffect(() => {
        search()
    }, [isActive])

    return loading ? <Loading /> :
        <div className="container-fluid  my-properties-mobile d-block p-3 container-filter-contract-mobile" >
            {firstContract ? <ExplanationImage /> : <></>} 
            <div className='d-flex justify-content-around' style={{ alignItems: "center" }}>
                <div className="input-group mb-3 my-mobile-search-box">
                    <span onClick={(evt) => search(evt)} className="input-group-text"><i className='bx bx-search'></i></span>
                    <input defaultValue={searchCriteria} onKeyUp={(e) => setSearchCriteria(e.target.value)} type="text" className="form-control" placeholder="Procurar Contrato" />
                    <button data-bs-toggle="modal" data-bs-target="#new-contract-modal" className='btn btn-success my-button my-mobile-button'>
                        <i className='bx bx-plus' ></i>
                    </button>
                </div>
            </div>
            <div className='my-propertis-mobile-container'>
                
                <div className='mobile-filters filter-mobile-contract'>
                    <span className='mobile-filter ativos filter-contract-mobile' onClick={(evt) => setIsActive(true)}>Ativo</span>
                    {/* <span className='mobile-filter'>Pendente</span> */}
                    <span onClick={(evt) => setIsActive(false)} className='mobile-filter inativos filter-contract-mobile'>Inativos</span>
                    <span onClick={(evt) => setIsActive(null)} className='mobile-filter ver-todos c-custom filter-contract-mobile'>Ver todos</span>
                </div>
                <div className='mobile-list-items '>
                    {data.length > 0 ? data.map(item => <div className='mobile-list-item' onClick={() => window.location.href = `/contratos/detalhe/${item.id}`} key={item.id}>
                        <div className='property-mobile-details-list'>
                            <div className='listagemImovelContainer'>
                                <div className='listagemInfo'>
                                    <span className='listagemTitulo'>Imóvel:</span>
                                    <span className='listagemData'>{item.property_name}</span>
                                </div>
                                <div className='listagemInfo'>
                                    <span className='listagemTitulo'>Valor Mensal:</span>
                                    <span className='listagemData'>R$ {item.rent_value}</span>
                                </div>
                                <div className='listagemInfo'>
                                    <span className='listagemTitulo'>Locatário:</span>
                                    <span className='listagemData'>{item.tenant_name}</span>
                                </div>
                                <div className='listagemInfo'>
                                    <span className='listagemTitulo'>Dia de Vencimento:</span>
                                    <span className='listagemData'>{item.pay_day}</span>
                                </div>
                            </div>

                        </div>
                        <div className='c-attr c-attr-group-custom' style={{ display: 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'flexDirection': 'column', 'padding': '10px' }}>
                            <span className='c-title'>Status:</span>
                            <span className='c-content' style={{ marginTop: '10px' }}>
                                {item.is_active !== "1" ? <span className='c-tag-outline'>Inativo</span> :
                                    !item.end_at || item.end_at < new Date().toISOString().slice(0, 100) ? <span className='c-tag-warning'>Finalizado</span> :
                                        item.end_at && item.end_at > new Date().toISOString().slice(0, 100) ? <span className='c-tag-success'>Ativo</span> : <></>}
                            </span>
                        </div>
                    </div>
                    ) : <div className='text-center' style={{ marginTop: '120px' }}><h1><i class='bx bx-sad'></i></h1><span className=''>Nenhum contrato encontrado...</span></div>}
                </div>
            </div>
        </div>
}

export default ListMobile;