import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'

import Loading from '../../../components/loading';
import Invoice from '../../../services/invoice';
import Time from '../../../hooks/time';
import "./style.css"

const ListMobile = () => {
    const auth = useAuthUser();

    const [data, setData] = useState([]);
    const [dataOriginal, setDataOriginal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [isActive, _] = useState(null);

    useEffect(() => {
        Invoice.list({ search: searchCriteria, user: auth().id, is_active: isActive }).then(response => {
            setLoading(false);
            let params = new URL(document.location).searchParams;
            let ex = params.get("ex"), ap = params.get("ap");

            if (ex) setData(response.invoices.filter(item => item.due_date_actual < new Date().toISOString().slice(0, 100) && item.paid_out === '0'))
            else if (ap) setData(response.invoices.filter(item => item.due_date_actual > new Date().toISOString().slice(0, 100) && item.due_date_actual < new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().slice(0, 100) && item.paid_out === '0'));
            else setData(response.invoices);

            setDataOriginal(response.invoices);

        })
    }, [])

    const colorFilters = (full, selected, week) => {
        let filters = document.querySelectorAll(".mobile-filter");

        if (filters && full) {
            filters.forEach(element => {
                element.classList.remove('c-custom');

                if (element.classList.contains(selected)) element.classList.add('c-custom');

                switch (selected) {
                    case 'a-vencer':
                        if (week) setData(dataOriginal.filter(item => item.due_date_actual > new Date().toISOString().slice(0, 100) && item.due_date_actual < new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().slice(0, 100) && item.paid_out === '0'));
                        else setData(dataOriginal.filter(item => item.due_date_actual > new Date().toISOString().slice(0, 100) && item.paid_out === '0'));
                        break;
                    case 'pago':
                        setData(dataOriginal.filter(item => item.paid_out === '1'));
                        break;
                    case 'vencido':
                        setData(dataOriginal.filter(item => item.due_date_actual < new Date().toISOString().slice(0, 100) && item.paid_out === '0'));
                        break;
                    case 'ver-todos':
                        setData(dataOriginal);
                        break;
                    default:
                        setData(dataOriginal);
                        break;
                }
            });
        }
    }

    const search = () => {
        setLoading(true);
        Invoice.list({ search: searchCriteria, user: auth().id, is_active: isActive }).then(response => {
            let params = new URL(document.location).searchParams;
            let ex = params.get("ex"), ap = params.get("ap");
            setDataOriginal(response.invoices);
            colorFilters()
        })
    }

    useEffect(() => {
        search()
    }, [isActive])

    return loading ? <Loading /> :
        <div className="container-fluid my-properties-faturas d-md-none d-block">
            <div className='d-flex justify-content-around' style={{ alignItems: "center" }}>
                <div className="input-group mb-3 my-mobile-search-box">
                    <span onClick={(evt) => search(evt)} className="input-group-text"><i className='bx bx-search'></i></span>
                    <input defaultValue={searchCriteria} onKeyUp={(e) => setSearchCriteria(e.target.value)} type="text" className="form-control" placeholder="Procurar Fatura" />
                    {/* <button data-bs-toggle="modal" data-bs-target="#new-contract-modal" className='btn btn-success my-button my-mobile-button'>
                        <i className='bx bx-plus' ></i>
                    </button> */}
                </div>
            </div>
            <div className='my-propertis-mobile-container'>
                <div className='mobile-filters'>
                    <span onClick={() => colorFilters(true, 'a-vencer')} className='mobile-filter a-vencer filter-fatura-mobile'>A vencer</span>
                    <span onClick={() => colorFilters(true, 'pago')} className='mobile-filter pago filter-fatura-mobile'>Pago</span>
                    <span onClick={() => colorFilters(true, 'vencido')} className='mobile-filter vencido filter-fatura-mobile'>Vencido</span>
                    <span onClick={() => colorFilters(true, 'ver-todos')} className='mobile-filter ver-todos c-custom filter-fatura-mobile'>Ver todos</span>
                </div>
                <div className='mobile-list-items'>
                    {data.length > 0 ? data.map((item, index) => <div className='mobile-list-item' key={index}>
                        <div onClick={() => window.location.href = `/faturas/detalhe/${item.invoice_id}`} className='property-mobile-details-list d-flex'>
                            <div className='c-attr-group faturaGroup'>
                                {/* <div className='c-attr'>
                                    <span className='c-title'>Número:</span>
                                    <span className='c-content'>{item.invoice_id}</span>
                                </div> */}
                                <div className='c-attr '>
                                    <span className='c-title faturaTitle'>Imóvel:</span>
                                    <span className='c-content faturaData'>R$ {item.property_name}</span>
                                </div>
                                <div className='c-attr'>
                                    <span className='c-title faturaTitle'>Locatário:</span>
                                    <span className='c-content faturaData'>{item.tenant_name}</span>
                                </div>
                                <div className='c-attr'>
                                    <span className='c-title faturaTitle'>Valor Total:</span>
                                    <span className='c-content faturaData'>R$ {item.total_value}</span>
                                </div>
                                <div className='c-attr'>
                                    <span className='c-title faturaTitle'>Mês de Cobrança:</span>
                                    <span className='c-content faturaData'>{item.due_date ? `${Time.months()[new Date(item.due_date).getMonth()]}/${new Date(item.due_date).getFullYear()}` : ""}</span>
                                </div>
                            </div>

                        </div>
                        <div className='c-attr c-attr-group-custom' style={{ display: 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'flexDirection': 'column', 'padding': '10px' }}>
                            <span className='c-title'>Status:</span>
                            <span className='c-content' style={{ marginTop: '10px' }}>
                                {item.paid_out === '1' ? <span className='badge c-tag-success'>Pago</span> : item.due_date_actual < new Date().toISOString().slice(0, 10) ? <span className='badge c-tag-danger'>Vencido</span> : <span className='badge c-tag-warning'>Pendente</span>}
                            </span>
                        </div>
                    </div>
                    ) : <div className='text-center' style={{ marginTop: '120px' }}><h1><i className='bx bx-sad'></i></h1><span className=''>Nenhum contrato encontrado...</span></div>}
                </div>
            </div>
        </div>
}

export default ListMobile;


