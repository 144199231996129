import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { Document, Page, pdf } from "@react-pdf/renderer";

import Service from "../../services/invoice";
import Time from "../../hooks/time";
import Logo from "../../assets/images/logo_full.svg";

const ExternalInvoice = () => {
  // pdf.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdf.version}/pdf.worker.js`;
  let { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [pdfInvoice, setDocument] = useState(null);

  useEffect(() => {
    document.body.classList.add("bg-white");

    if (!id) window.location.href = "/";
    else {
      const invoice_id = parseInt(atob(id).split("_")[1]);
      Service.details(null, invoice_id, true).then((response) => {
        if (response?.invoice?.document) setDocument(`${response.invoice.document}`);
        else setInvoice(response.invoice);
      });
    }
  }, []);

  return invoice ? (
    <>
      <div className="d-flex justify-content-center mt-5"><img src={Logo} /></div>
      <div className="container bg-white position-relative">
        <h1 className="my-4"><strong>Fatura</strong></h1>
        <hr />
        <div className="mb-3 d-flex justify-content-between">
          <div>
            <p><strong>Número da Fatura:</strong> {invoice.invoice_id}</p>
            <p><strong>Referência:</strong> {Time.formatToMMYYYY(invoice.due_date_actual)}</p>
            <p><strong>Vencimento:</strong> {Time.formatToDDMMYYYY(invoice.due_date_actual)}</p>
            <p><strong>Total:</strong> R$ {parseFloat(invoice.total_value) +
              invoice.additions?.reduce(
                (a, b) =>
                  parseInt(b.invoice_addition_type_id) !== 1
                    ? a + parseFloat(b.value)
                    : a - parseFloat(b.value),
                0
              )}</p>
            <p><strong>Status:</strong> <span className={`badge ${invoice.paid_out === "1" ? "c-tag-success" : invoice.due_date_actual < Time.formatToYYYYMMDD(new Date()) ? "c-tag-danger" : "c-tag-warning"}`}>{invoice.paid_out === "1"
              ? "Pago"
              : invoice.due_date_actual < Time.formatToYYYYMMDD(new Date())
                ? "Vencido"
                : "A Vencer"}</span></p>
          </div>
          {/* <div>
            qr code
          </div> */}
        </div>
        <hr />
        <div className="mb-3">
          <h3><strong>Detalhes do Pagador</strong></h3>
          <p><strong>Nome:</strong> {invoice.tenant_name}</p>
          <p><strong>E-mail:</strong> {invoice.contact_email}</p>
          <p><strong>Imóvel:</strong> {invoice.property_address}, {invoice.property_number}</p>
          <p><strong>Cidade:</strong> <span className="text-capitalize">{invoice.property_city}</span></p>
        </div>
        <hr />
        <div className="mb-3">
          <h3><strong>Detalhe de Valores</strong></h3>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Observação</th>
                <th>Valor (R$)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aluguel</td>
                <td>Valor do Aluguel</td>
                <td>R$ {invoice.rent_value}</td>
              </tr>
              <tr>
                <td>Condomínio</td>
                <td>Valor do Condomínio</td>
                <td>R$ {invoice.condominium_value}</td>
              </tr>
              <tr>
                <td>IPTU</td>
                <td>Valor do IPTU</td>
                <td>R$ {invoice.iptu_value}</td>
              </tr>
              {invoice.additions?.map((addition) => (
                <tr>
                  <td>{addition.invoice_addition_type}</td>
                  <td>{addition.description}</td>
                  <td>R$ {addition.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : pdfInvoice ? <embed
    src={`data:application/pdf;base64,${pdfInvoice}`}
    type="application/pdf"
    width="100%"
    height="100%"
    style={{
      position: "fixed", // Faz com que o elemento fique fixo e sobreponha qualquer outro conteúdo
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }} /> : (
    <div className="container bg-white"></div>
  );
};

export default ExternalInvoice;
