// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerGeralStep03 {
    display: flex;
    flex-direction: column;
}
.containerInfoStep03 {
    margin-top: 8%;
}
figure {
    margin: auto;
}
.formEmailNomeRegister {
    display: flex;
    flex-direction: column;
    gap: 25%;
}`, "",{"version":3,"sources":["webpack://./src/views/user/register/step03/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ","sourcesContent":[".containerGeralStep03 {\n    display: flex;\n    flex-direction: column;\n}\n.containerInfoStep03 {\n    margin-top: 8%;\n}\nfigure {\n    margin: auto;\n}\n.formEmailNomeRegister {\n    display: flex;\n    flex-direction: column;\n    gap: 25%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
