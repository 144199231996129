export default class Contract {
    static updateIndexList = async () => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/update-index-list`, { method: 'GET' });

        return await response.json();
    }

    static bankAccountList = async (user) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/bank-account-list`, { method: 'POST', body: JSON.stringify({ user }) });

        return await response.json();
    }

    static bankAccountInsert = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/bank-account-insert`, { method: 'POST', body: JSON.stringify(data) });

        return await response.json();
    }

    static bankAccountUpdate = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/account`, {
            method: 'PUT', body: JSON.stringify(data), headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return response.status;
    }

    static contractInsert = async (data) => {
        if (!data.late_fee) data.late_fee = 0;
        if (!data.late_interest) data.late_interest = 0;
        if (!data.deposit_value) data.deposit_value = 0;

        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/contract-insert`, { method: 'POST', body: JSON.stringify(data) });

        return await response.json();
    }

    static contractUpdate = async (data) => {
        await fetch(`${process.env.REACT_APP_GATEWAY_URL}/contract-update`, { method: 'POST', body: JSON.stringify(data) });

        return true;
    }

    static contractList = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/contract-list`, { method: 'POST', body: JSON.stringify(data) });

        return await response.json();
    }

    static contractDetails = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/contract-detail`, { method: 'POST', body: JSON.stringify(data) });

        return await response.json();
    }

    static activeContract = async (id, contract) => {
        const response = await fetch(`${process.env.REACT_APP_PAYMENT_API_URL}/recurrence/new-session?from=web`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, add: true, contract })
        });

        if (response.status == 202) return {}
        else return await response.json();
    }

    static updateContract = async (id, contract) => {
        const response = await fetch(`${process.env.REACT_APP_PAYMENT_API_URL}/recurrence/update-session?from=web`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, add: true, contract })
        });

        if (response.status == 202) return {}
        else return await response.json();
    }

    static needSetupRecurrence = async (user) => {
        const response = await fetch(`${process.env.REACT_APP_PAYMENT_API_URL}/recurrence/has-setup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user })
        });

        return (await response.json()).has;
    }

    static getInfo = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PAYMENT_API_URL}/recurrence/user-info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id })
            }); 
    
            return await response.json()
        } catch (error) {
            return undefined
        }
    }

    static updateCard = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_PAYMENT_API_URL}/recurrence/update-card`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        });

        return await response.json()
    }
}