export default class Admin {
    static passOutList = async () => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/pass-out-list`, { method: 'GET' });

        return await response.json();
    }

    static passOut = async (id) => {
        await fetch(`${process.env.REACT_APP_GATEWAY_URL}/pass-out`, { method: 'POST', body: JSON.stringify({ id }) });
    }
}