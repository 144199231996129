import React, { useEffect, useState } from "react";
import { useAuthUser } from 'react-auth-kit';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import Tenant from "../../../services/tenant";


const StepTenant = ({ idReturning, setIdReturning, contractReturn }) => {
    const auth = useAuthUser();

    const [form, setForm] = useState({ created_by: auth().id, name: "", nickname: "", document: "", contact_name: "", email: "", mobile: "" });
    const [isInvalid, setIsInvalid] = React.useState({ stepOne: true, stepTwo: true, stepThree: true, stepFour: true });
    const [submitResult, setSubmitResult] = React.useState({ loading: true, success: true });
    const [errorMessage, setErrorMessage] = React.useState("")

    const [mask, setMask] = useState("");

    const submit = async () => {

        setIsInvalid({ ...isInvalid, stepThree: true });

        if (form.contact_name &&  form.email && form.mobile) {
            if (!isValidEmail(form.email)) {
                setIsInvalid({ ...isInvalid, stepThree: false });
                setErrorMessage("E-mail inválido !");
                return false;
            }
            const formattedPhoneNumber = formatPhoneNumber(form.mobile);
            if (formattedPhoneNumber.length < 14) {
                setIsInvalid({ ...isInvalid, stepThree: false });
                setErrorMessage("Número de celular inválido !");
                return false;
            }
            setForm({ ...form, mobile: formattedPhoneNumber });

            document.querySelector('.c-step').classList.toggle('d-none');
            document.querySelector(`.c-step-view.c-step-03`).classList.toggle('true');
            document.querySelector(`.c-step-view.c-step-04`).classList.toggle('true');

            try {
                const response = await Tenant.create(form);
                if (setIdReturning) setIdReturning({ ...idReturning, tenant_id: response.id, tenant: true });
                setSubmitResult({ loading: false, success: response.success, id: response.id });
            } catch (error) {
                console.log(error)
                console.error("Erro ao criar locatário:", error);
                setSubmitResult({ loading: false, success: false });
            }
        } else {
            setIsInvalid({ ...isInvalid, stepThree: false });
            setErrorMessage("Preencha todos os campos");
            return false;
        }
    };

    const isValidEmail = (email) => {

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    const formatPhoneNumber = (phoneNumber) => {
        const formattedPhoneNumber = phoneNumber.replace(/^(\d{2})\s?(\d{4,5})-?(\d{4})$/, '($1) $2-$3');
        return formattedPhoneNumber;
    };
    const handlePhoneChange = (e) => {
        const newPhoneNumber = e.target.value;
        const digitsOnly = newPhoneNumber.replace(/\D/g, '');
        const limitedDigits = digitsOnly.slice(0, 11);
        let formattedPhoneNumber = formatPhoneNumber(limitedDigits);
        if (limitedDigits.length >= 10 && limitedDigits.length <= 11) {
            formattedPhoneNumber = `${formattedPhoneNumber}`;
            setForm({ ...form, mobile: formattedPhoneNumber });
        } else {
            setForm({ ...form, mobile: limitedDigits });
        }
    };

    const switchStep = async (step, values) => {
        if (values != null) setForm(values);

        if (await validateStep(step)) {
            document.querySelector(`.c-step-display-0${step + 1}`).classList.toggle('true');
            document.querySelector(`.c-step-view.c-step-0${step}`).classList.toggle('true');
            document.querySelector(`.c-step-view.c-step-0${step + 1}`).classList.toggle('true');
        }
    }

    const validateStep = async (step) => {
        switch (step) {
            case 1:
                setIsInvalid({ ...isInvalid, stepOne: true });
                if (form.document && (form.document.length === 14 || form.document.length === 18)) {
                    if (!mask) {
                        const response = await Tenant.cnpj(form.document)
                        console.log(response)
                        if (response.razao_social === undefined) {
                            setIsInvalid({ ...isInvalid, stepOne: false });
                            setErrorMessage("CNPJ inválido");
                            return false;
                        } else {
                            console.log(response)
                            setForm({...form, name: response.razao_social, nickname: response.nome_fantasia})
                            console.log(form.name)
                            console.log(form.nickname)
                            setIsInvalid({ ...isInvalid, stepOne: true });
                            return true;
                        }
                    } else {
                        const response = await Tenant.cpf(form.document)
                        if (response.razao_social === undefined) {
                            console.log(form.document)
                            setIsInvalid({ ...isInvalid, stepOne: false });
                            setErrorMessage("CPF inválido");
                            return false
                        }
                         else {
                            console.log(response)
                            setForm({...form, name: response.razao_social})
                            setIsInvalid({ ...isInvalid, stepOne: true });
                            return true;
                        }
                    }
                } else {
                    setIsInvalid({ ...isInvalid, stepOne: false });
                    setErrorMessage("CPF inválido");
                    return false
                }


            case 2:
                setIsInvalid({ ...isInvalid, stepTwo: true });
                if (form.name && (mask || form.nickname)) return true;
                else {
                    setIsInvalid({ ...isInvalid, stepTwo: false });
                    return false;
                }
            default:
                return false;
        }
    }

    useEffect(() => {
        const myModal = document.getElementById('tenant-creation-modal') ?? document.getElementById('new-tenant-modal');

        myModal.addEventListener('show.bs.modal', () => {
            let dialogModal = document.querySelector('#tenant-creation-modal .modal-dialog');
            if (window.matchMedia("(max-width: 768px)").matches) {
                if (!dialogModal.classList.contains('modal-fullscreen')) dialogModal.classList.add('modal-fullscreen')
            } else dialogModal.classList.remove('modal-fullscreen')
        });

    }, [])

    return <div className="property-creation">
        <div className="c-step">
            <span className="c-step-display c-step-display-01 true">Etapa 1</span>
            <span className="c-step-display c-step-display-02">Etapa 2</span>
            <span className="c-step-display c-step-display-03" style={{ borderRadius: '0 10px 10px 0' }}><i className='bx bx-check'></i></span>
        </div>
        <div className="c-step-view c-step-01 true">
            <p>Para facilitar o cadastro de seu novo inquilino (locatário), nos informe o CPF ou CNPJ que vamos buscar as informações para você.</p>
            {isInvalid.stepOne === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        <strong> {errorMessage}</strong>
                    </div>
                </div>
            </> : <></>}
            <div className="c-options">
                <CpfCnpj
                    value={form.document}
                    placeholder="000.000.000-00"
                    className="form-control c-input-option"
                    onChange={(ev, type) => {
                        setForm({ ...form, document: ev.target.value });
                        setMask(type === "CPF");
                    }}
                />
            </div>
            {contractReturn?.active ? <div className="c-options-nav">
                <button onClick={() => contractReturn.callback({ ...contractReturn.config, currentStep: 2 })} className="btn c-prev-step">Voltar para o Contrato</button>
                <button onClick={() => switchStep(1)} className="btn c-next-step">Continuar</button>
            </div> :
                <div className="c-options-nav" style={{ justifyContent: 'flex-end' }}>
                    <button onClick={() => switchStep(1)} className="btn c-next-step">Continuar</button>
                </div>}
        </div>
        <div className="c-step-view c-step-02">
            <p><strong>Pronto!</strong></p>
            <p>Para seguirmos, por favor confirme se os dados estão corretos</p>
            {isInvalid.stepTwo === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        <strong> Preencha todos os campos</strong> antes de continuar.
                    </div>
                </div>
            </> : <></>}
            <div className="c-options">
                <div className="mb-3 c-option-box">
                    <label htmlFor="nome" className="form-label">{mask ? <>Nome</> : <>Razão Social</>}</label>
                    {mask? <input defaultValue={form.name}  disabled={true} minLength="3" maxLength="512" onKeyUp={(evt) => setForm({ ...form, name: evt.target.value })} id="nome" className="form-control c-input-option-0" type="text" placeholder="Digite seu nome" /> 
                    
                    : <input defaultValue={form.name}  disabled={true} minLength="3" maxLength="512" onKeyUp={(evt) => setForm({ ...form, name: evt.target.value })} id="nome" className="form-control c-input-option-0" type="text" placeholder="Digite seu nome" />  }
                    
                </div>
                {!mask ? <>
                    <div className="mb-3 c-option-box">
                        <label htmlFor="nickname" className="form-label">Nome Fantasia</label>
                        <input defaultValue={form.nickname} disabled={true} maxLength="512" onKeyUp={(evt) => setForm({ ...form, nickname: evt.target.value })} id="nickname" className="form-control c-input-option-0" type="text" />
                    </div></> : <></>}
            </div>
            <div className="c-options-nav">
                <button onClick={() => switchStep(1)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => switchStep(2)} className="btn c-next-step">Continuar</button>
            </div>
        </div>

        <div className="c-step-view c-step-03">
            <p>Para nos ajudar com o processo de envio mensal da fatura para o inquilino, poderia nos informar o <strong>melhor contato</strong> dele <strong>para cobrança</strong>?</p>
            {isInvalid.stepThree === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        <strong> {errorMessage}</strong>
                    </div>
                </div>
            </> : <></>}
            <div className="c-options">
                <div className="mb-3 c-option-box">
                    <label htmlFor="contact_name" className="form-label">Nome Fantasia</label>
                    <input onKeyUp={(evt) => setForm({ ...form, contact_name: evt.target.value })} id="contact_name" className="form-control c-input-option-0" placeholder="Nome" type="text" />
                </div>
                <div className="mb-3 c-option-box">
                    <label htmlFor="mobile" className="form-label">Telefone (Whatsapp)</label>
                    {/*<input onKeyUp={(evt) => setForm({ ...form, mobile: evt.target.value })} id="mobile" className="form-control c-input-option-0" placeholder="+55 00 00000-0000" type="text" />*/}
                    <input
                        placeholder="(__) _____-____"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={form.mobile}
                        onChange={handlePhoneChange}
                        maxLength={15}
                        className="inputRegistroTelefoneLocatario form-control c-input-option-0"
                        type='tel'
                    />
                </div >
                <div className="mb-3 c-option-box">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input onKeyUp={(evt) => setForm({ ...form, email: evt.target.value })} id="email" className="form-control c-input-option-0" placeholder="Digite seu e-mail" type="text" />
                </div>
            </div >
            <div className="c-options-nav">
                <button onClick={() => switchStep(2)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => submit()} className="btn c-next-step">Continuar</button>
            </div>
        </div >
        <div className="c-step-view c-step-04 c-step-final justify-content-center p-5">
            {submitResult.loading ? <div className="spinner-border text-success" role="status"></div> : submitResult.success ? <div className="box-success">
                <span><i className='bx bxs-check-circle'></i></span>
                <h4>Cadastro finalizado!</h4>
                <p>Agora você pode acessar os detalhes do seu Locatário e adicionar mais informações sobre ele..</p>
                <div className="c-options">
                    <a href={`/locatarios/detalhe/${submitResult.id}`} className="btn c-option my-button">Acessar detalhes do locatário</a>
                    <a href="/locatarios" className="btn c-option my-button">Voltar</a>
                </div>
            </div> : <>Ocorreu um erro inesperado :(</>}
        </div>
    </div >
}

export default StepTenant;