export default class Time {
  /**
   * @returns {String}
   */
  static formatToYYYYMMDD = (date) => {
    // Ensure the input is a Date object
    if (!(date instanceof Date)) {
      try {
        date = new Date(date);
      } catch (error) {
        return false;
      }
    }

    // Get date components
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Months are zero-based, so add 1
    var day = date.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Return formatted date in "yyyy-mm-dd" format
    return year + "-" + month + "-" + day;
  };

    /**
   * @returns {String}
   */
    static formatToYYYYMMDDClean = (date) => {
      // Ensure the input is a Date object
      if (!(date instanceof Date)) {
        try {
          date = new Date(date);
        } catch (error) {
          return false;
        }
      }
  
      // Get date components
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // Months are zero-based, so add 1
      var day = date.getDate();
  
      // Add leading zero if month or day is less than 10
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
  
      // Return formatted date in "yyyy-mm-dd" format
      return year + month + day;
    };

  /**
   * @returns {String}
   */
  static formatToDDMMYYYY = (date) => {
    // Ensure the input is a Date object
    if (!(date instanceof Date)) {
      try {
        date = new Date(date);
      } catch (error) {
        return false;
      }
    }

    // Get date components
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Months are zero-based, so add 1
    var day = date.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    // Return formatted date in "yyyy-mm-dd" format
    return day + "/" + month + "/" + year;
  };

  /**
   * @returns {String}
   */
  static formatToMMYYYY = (date) => {
    // Ensure the input is a Date object
    if (!(date instanceof Date)) {
      try {
        date = new Date(date);
      } catch (error) {
        return false;
      }
    }

    // Get date components
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Months are zero-based, so add 1

    // Add leading zero if month or day is less than 10
    month = month < 10 ? "0" + month : month;

    // Return formatted date in "yyyy-mm-dd" format
    return this.months()[month - 1] + "/" + year;
  };

  /**
   * @returns {Number}
   */
  static calculateMonthDifference = (date1, date2) => {
    // Ensure the input is a Date object
    if (!(date1 instanceof Date)) date1 = new Date(date1);
    if (!(date2 instanceof Date)) date2 = new Date(date2);

    // Calculate the difference in milliseconds
    var differenceInMilliseconds = Math.abs(date2 - date1);

    // Convert the difference to months
    var months = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30.44); // Average days per month

    // Round down the number of months
    return Math.floor(months);
  };

  /**
   * Retorna um array com os nomes dos meses em português.
   *
   * @returns {string[]} Um array de strings onde cada elemento é o nome de um mês.
   * O primeiro elemento é "Janeiro" e o último é "Dezembro".
   *
   * @example
   * const meses = Time.months();
   * console.log(meses[0]); // "Janeiro"
   * console.log(meses[11]); // "Dezembro"
   */
  static months = () => [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
}
