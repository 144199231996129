import React, { useState } from 'react';
import LogoHorizontal from '../../../../assets/images/logo_full.svg'
import { IoMdArrowRoundBack } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';
import "./style.css"
import { useInfo } from '../../../../context/UserContext';
import User from '../../../../services/user';

function Step04({ onPrevStep }) {
    const { userData } = useInfo();
    const [errorMessage, setErrorMessage] = useState("");

    const submit = async (e) => {
        e.preventDefault();
        setErrorMessage("");

        const password = e.target.querySelector("#password-input").value;
        const confirmPassword = e.target.querySelector("#confirm-password-input").value;

        if (password.length < 8) {
            setErrorMessage("A senha deve ter 8 caracteres ou mais.");
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage("As senhas não coincidem.");
            return;
        }

        const uniqueId = uuidv4();
        const data = {
            username: uniqueId,
            name: userData.name,
            lastName: userData.lastName,
            email: userData.email,
            document: userData.document ?? uniqueId.slice(0, 11),
            password: password,
            mobile: userData.mobile,
            created_by: 0,
            id_profile: 1
        }
        await User.create(data).then((response) => {
            if (response.success === true) {
                window.location.href = "/entrar";
            } else {
                if (response.message === 'duplicate key value violates unique constraint "user_email_key"') {
                    setErrorMessage("Esse e-mail já foi cadastrado")
                } else {
                    setErrorMessage(response.message)
                }
            }
        })
    }

    return (
        <>
            <section className='containerGeralStep04'>
                <IoMdArrowRoundBack size={20} onClick={onPrevStep} />
                <figure>
                    <img src={LogoHorizontal} alt='EuImob logo'></img>
                </figure>
                <div className='containerInfoStep03'>
                    <h2 className='tituloFormOtp'>Defina sua senha</h2>
                    <strong className='text01'>Para finalizarmos, defina sua senha</strong>
                </div>
                <form onSubmit={submit}>
                    <div className="mb-3">
                        <label htmlFor="password-input" style={{ fontWeight:'lighter' }}><strong>Senha</strong></label>
                        <input placeholder="Pelo menos 8 caracteres" autoComplete="current-password" type="password" id="password-input" className="form-control inputRegister mt-1" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confirm-password-input" className='mt-3' style={{ fontWeight:'lighter' }}><strong>Confirmar Senha</strong></label>
                        <input placeholder="Confirme sua senha" autoComplete="current-password" type="password" id="confirm-password-input" className="form-control inputRegister mt-1" />
                    </div>
                    {errorMessage && (
                        <div className="" role="alert">
                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                <i className='bx bx-info-circle'></i>
                                <strong> Atenção! </strong> {errorMessage}
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-between w-100 mt-3">
                        <button type="submit" style={{ color: "white" }} className="btn imob-button btn-lg w-100">Criar</button>
                    </div>
                </form>
            </section>
        </>
    );
}

export default Step04;
