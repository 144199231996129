import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

const InputMobileNumber = ({ maskOptions, ...inputProps }) => {
  return <MaskedInput mask={['+', '5', '5', ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} {...inputProps} />
}

InputMobileNumber.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

InputMobileNumber.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
}

export default InputMobileNumber
