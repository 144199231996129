import React from 'react';
import "./style.css";
import LogoImob from '../../../assets/images/Logo Horizontal.png'

function DeleteAccount() {
	return (
		<>
			<section>
				<main className='main-content'>
					<article className='menu-titulo'>
						<h1>Solicitação de Exclusão de Conta - Eu.Imob</h1>
					</article>
					<article className='content'>
						<p>Para solicitar a exclusão da sua conta, siga os passos abaixo:</p>
						<ol>
							<li>Acesse o aplicativo Eu.Imob.</li>
							<li>Vá até a tela "Minha Conta".</li>
							<li>Clique no botão "Excluir Conta".</li>
							<li>Você será redirecionado para o WhatsApp, onde nossa equipe de suporte irá ajudar na exclusão da sua conta.</li>
						</ol>
						<h3>Dados Excluídos</h3>
						<p>Os dados pessoais, como nome, e-mail e CPF/CNPJ, serão removidos dos nossos sistemas. Qualquer informação associada à sua conta será excluída.</p>
						<h3>Contato</h3>
						<p>Para mais informações, entre em contato com nossa equipe de suporte via <a href="https://wa.me/551135068273" target="_blank" rel="noreferrer">WhatsApp</a> após seguir os passos descritos acima.</p>
					</article>
				</main>
				<footer className='rodape'>
					<div className='content-rodape'>
						<img
							src={LogoImob}
							alt='imob logo'
							width='100'  /* Ajuste conforme necessário */
							height='auto' /* Mantém a proporção da imagem */
						/>
						<p>contato@euimob.com.br</p>
						<p>+55 (11)3506-8273</p>
					</div>
				</footer>
			</section>
		</>
	);
}

export default DeleteAccount;
