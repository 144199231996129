import React from "react";

const LoadingBasic = () => {
    return <div style={{ opacity: '0.3', margin: '40px 0', display: 'flex', 'justifyContent': 'center', 'flexDirection': 'row', alignItems: 'center'}}>
        <div style={{width: '7px', height: '7px', margin: '0 2px'}} className="spinner-grow text-success" role="status"></div>
        <div style={{width: '7px', height: '7px', margin: '0 2px'}} className="spinner-grow text-success" role="status"></div>
        <div style={{width: '7px', height: '7px', margin: '0 2px'}} className="spinner-grow text-success" role="status"></div>
    </div>
}

export default LoadingBasic;