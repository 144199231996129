import { useEffect, useState } from "react";

import Layout from "../../components/layout/layout";
import List from "./shared/list";
import ListMobile from "./shared/list-mobile";
import New from "./new";

import "../../assets/styles/property.css";

const Tenant = () => {
    const [mobile, setMobile] = useState(null);

    useEffect(() => {
        if(window.matchMedia("(max-width: 768px)").matches) setMobile(true)
        else setMobile(false);

    }, []);

    return <Layout>
        {mobile != null ? mobile === true ? <ListMobile /> : <List /> : <></>}        
        <New />
    </Layout>
}

export default Tenant;