import LoadingBasicFull from "./loading-basic-full";

const LoadingOverlay = (props) => {
    document.querySelector('body').style.overflow = 'hidden'

    return <div id="loading-overlay" class="overlay" style={{ 
        position: "fixed", 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: 'var(--color-primary-background)',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1000', }}>
            <LoadingBasicFull />
    </div>
}

export default LoadingOverlay;