import { useAuthUser } from 'react-auth-kit';
import ProfilePhoto from "../../assets/images/profile.jpg";

const Menu = () => {
    const auth = useAuthUser();
    return (
        <header className="header" id="header" >
            <div className="header_toggle"></div>
            <div className='header_name' style={{ cursor: 'pointer' }} onClick={() => window.location = '/conta'}> <span>{`${auth().name} ${auth().lastname}`}</span>
                <div className="header_img"><img src={ProfilePhoto} alt='Profile' /></div> 
            </div>
            
        </header>
    )
}

export default Menu;