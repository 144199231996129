import React, { useState, useEffect } from 'react';
import LogoHorizontal from '../../../../assets/images/logo_full.svg'
import "./style.css";
import User from '../../../../services/user';
import { useToken } from '../../../../context/TokenContext';
import { useInfo } from '../../../../context/UserContext';

import BrazilFlag from '../../../../assets/images/brazil-flag.svg'
import { CiCircleInfo } from "react-icons/ci";
import { IoMdArrowRoundBack } from "react-icons/io";

const Step01 = ({ onNextStep }) => {

  const [wppErrorMessage, setWppErrorMessage] = useState(false);
  const { tokenWpp, setTokenWpp } = useToken();
  const {userData, setUserData} = useInfo();


  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    document.head.appendChild(meta);
    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(/^(\d{2})\s?(\d{4,5})-?(\d{4})$/, '($1) $2-$3');
    return formattedPhoneNumber;
};

  const handlePhoneChange = (event) => {
    const newPhoneNumber = event.target.value;
    const digitsOnly = newPhoneNumber.replace(/\D/g, '');

    const limitedDigits = digitsOnly.slice(0, 11);

    let formattedPhoneNumber = formatPhoneNumber(limitedDigits);

    if (limitedDigits.length >= 10 && limitedDigits.length <= 11) {
      formattedPhoneNumber = `${formattedPhoneNumber}`;
    }

    setUserData((prevFormPhone) => {
      return { ...prevFormPhone, mobile: formattedPhoneNumber };
    });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (userData.mobile.length < 14) {
      setWppErrorMessage('Por favor, insira um número de telefone válido.');
      console.log(userData.mobile)
      return;
    }
  
    const formattedPhoneNumber = "+55" + userData.mobile;

    try {
      const response = await User.sendVerificationCode(formattedPhoneNumber);
      setTokenWpp(response);
      onNextStep();
    } catch (error) {
      console.error("Erro ao enviar código de verificação:", error);
    }
  };

  return (
    <>
      <section className='containerGeralStep01'>
        <IoMdArrowRoundBack size={25} onClick={() => window.location = "/entrar"} className='icon-back' />
        <figure>
          <img src={LogoHorizontal} alt='EuImob logo'></img>
        </figure>
        <div>
          <h1>Olá, Bem-vindo a eu.imob</h1>
          <p>Vamos enviar um código de verificação para continuarmos.</p>
        </div>
        <form onSubmit={handleSubmit} style={{marginTop: '-5px'}}>
          <label>Qual é seu número de celular?</label>
          <div className="mb-4 mt-1 d-flex align-items-center">
            <div className='containerFlag'>
              <img src={BrazilFlag} alt='Brazil flag' id="flagIcon" />
              <span>+55</span>
            </div>
            <input
              placeholder="(__) _____-____"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={userData.mobile}
              onChange={handlePhoneChange}
              maxLength={15}
              className=" inputRegistroTelefone"
              type='tel'
            />
          </div>
          {wppErrorMessage && (
            <div className="" role="alert">
              <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong> Atenção! </strong> {wppErrorMessage}
              </div>
            </div>
          )}
          <div className='containerButtons'>
            <button className="btn btn-success w-100"  onClick={handleSubmit}>Receber por WhatsApp</button>
          </div>
        </form>
        <div className='containerInfo'>
          <CiCircleInfo color='gray' size={25} />
          <span className='infoText'>Com essas informações podemos garantir um atendimento personalizado e
            eficiente.</span>
        </div>
      </section>
    </>
  );
}

export default Step01;
