import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import { useAuthUser } from 'react-auth-kit'

import LoadingBasic from "../../../components/loading-basic";
import Contract from "../../../services/contract";
import RetangularItem from "../../../components/rectangular-item";

const Step05 = ({ config, setForm, form, switchStep }) => {
    const auth = useAuthUser();

    const [searchCriteria, setSearchCriteria] = useState("");
    const [bankAccountsOriginal, setBankAccountsOriginal] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Contract.bankAccountList(auth().id).then(response => {
            setBankAccounts(response.bank_accounts);
            setBankAccountsOriginal(response.bank_accounts);
            setLoading(false);
            setTimeout(() => {
                if (form.bank_account_id) select(form.bank_account_id, true);
            }, 100);
        });
    }, [])

    useEffect(() => { if (form.bank_account_id) select(form.bank_account_id, true) }, [form.bank_account_id])

    const select = (id, sample) => {
        console.log('id ',id)
        console.log('sample ',sample)
        if(document.querySelectorAll('.retangular-item')) document.querySelectorAll('.retangular-item').forEach(item => item.classList?.remove('selected'));
        if(document.getElementById(`retangular-item-${id}`)) document.getElementById(`retangular-item-${id}`).classList?.toggle('selected');
        if (!sample || sample === undefined){
            console.log("setting form")
            setForm({ ...form, bank_account_id: id });
        } 
    }

    const search = (criteria) => {
        setSearchCriteria(criteria);

        let newList = [];

        bankAccountsOriginal.forEach(item => {
            if (item.bank_name?.toLowerCase().includes(criteria.toLowerCase())) newList.push(item);
            else if (item.agency?.toLowerCase().includes(criteria.toLowerCase())) newList.push(item);
            else if (item.account?.toLowerCase().includes(criteria.toLowerCase())) newList.push(item);
            else if (item.pix?.toLowerCase().includes(criteria.toLowerCase())) newList.push(item);
        });

        setBankAccounts(newList);
    }

    const previous = () => {
        document.getElementById("step-required").classList.add('d-none');
        switchStep({ ...config, currentStep: 4 });
    }

    const next = () => {
        console.log('form: ', form)
        document.getElementById("step-required").classList.add('d-none');
        if (form.bank_account_id !== null) switchStep({ ...config, currentStep: 6 });
        else document.getElementById("step-required").classList.remove('d-none');
    }

    return <>
        <p className="modal-step-text">Por favor, nos informe os detalhes da conta onde você gostaria de receber os aluguéis mensalmente</p>
        <div id="step-required" className="d-none w-100">
            <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong> Atenção! </strong> Selecione uma chave PIX antes de continuar.
            </div>
        </div>
        <div className={`input-group mb-3 modal-step-search ${bankAccounts.length === 0 && searchCriteria != "" ? 'search-criteria-not-foud' : ''}`}>
            <span className="input-group-text icon"><i className='bx bx-search'></i></span>
            <input onKeyUp={(evt) => search(evt.target.value)} type="text" className="form-control input" placeholder="Busque por Banco, Agência ou Conta" />
            <Button onClick={() => switchStep({ ...config, currentStep: 12 })} customStyle={{ 'marginLeft': '10px', 'borderRadius': '5px', 'width': '20%' }} display="Novo" />
        </div>
        <div className="modal-step-fields">
            {bankAccounts.length === 0 ? loading ? <LoadingBasic /> : searchCriteria != "" ? <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong>
                    Não encontramos nenhuma conta bancária correspondente aos critérios de pesquisa.
                    Você pode verificar as informações inseridas ou <strong onClick={() => switchStep({ ...config, currentStep: 12 })}><u>cadastrar uma nova conta bancária</u></strong>.
                </strong>
            </div> : <>Não encontrei nenhuma conta.</> : bankAccounts.map((item, index) => <>
                {item.bank_name ? <RetangularItem key={index} id={item.id} columnOne={item.bank_name} columnTwo={item.agency} columnThree={item.account} onClick={select} />
                    : <RetangularItem key={index} id={item.id} columnOne={`Chave PIX: ${item.pix}`} onClick={select} />}
            </>)}
        </div>
        <div className="modal-step-actions">
            <Button onClick={previous} customClass='imob-button-neutral' display='Voltar' />
            <Button onClick={next} display="Continuar" />
        </div>
    </>
}

export default Step05;