import "../assets/styles/lp.css"

// section 1
import LogoVerde from "../assets/images/lp/logo_verde.png"
import Notebook from "../assets/images/lp/notebook.png";

// section 2
import Icon1 from "../assets/images/lp/icon_1.png"
import Icon2 from "../assets/images/lp/icon_2.png"
import Icon3 from "../assets/images/lp/icon_3.png"

// section 3
import Smartphone from "../assets/images/lp/smartphone.png";
import Angle from "../assets/images/lp/seta.png";

// section 4
import Persona from "../assets/images/lp/persona.png";

const Index = () => {
    return <div id="landing-page">
        <div className="bg-200vh">
            <div className="bg-100vh d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center">
                <div className="d-flex flex-column justify-content-between">
                    <img src={LogoVerde} alt="" width={100} />
                    <br /><br />
                    <h1 className="imob-color-primary"><strong>Simplifique a gestão do seu imóvel</strong></h1>
                    <p>Na eu.imob você terá tudo o que precisa para gerenciar seus contratos de aluguel, sem a dependência de terceiros.</p>
                    <button onClick={() => window.open('/entrar')} className="btn lp-button mt-2">ACESSE!</button>
                </div>
                <img src={Notebook} alt="" id="notebook" />
            </div>
            <div className="bg-100vh d-flex flex-column justify-content-center">
                <center>
                    <h2 className="imob-color-primary"><strong>Por que a eu.imob?</strong></h2>
                    <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                        <span className="mt-5 d-flex justify-content-start align-items-center box-s2 text-justify">
                            <img className="icon-s2" src={Icon1} alt="" />
                            <span className="text" style={{color: '#03619C'}}>Libere tempo e elimine preocupações enquanto nós cuidamos de tudo para você.</span>
                        </span>
                        <span className="mt-5 d-flex justify-content-start align-items-center box-s2 text-justify">
                            <img className="icon-s2" src={Icon2} alt="" />
                            <span className="text" style={{color: '#03619C'}}>Nossa taxa de administração é fixa, aqui você paga pelo serviço prestado e não pelo valor do seu imóvel.</span>
                        </span>
                        <span className="mt-5 d-flex justify-content-start align-items-center box-s2 text-justify">
                            <img className="icon-s2" alt="" src={Icon3} />
                            <span className="text" style={{color: '#03619C'}}>Seu tempo é valioso, e nós entendemos isso. Por isso, simplificamos todo o processo de cadastro de informações, garantindo eficiência e praticidade em cada etapa.</span>
                        </span>
                    </div>
                </center>
            </div>
        </div>
        <div className="bg-100vh d-flex flex-column-reverse flex-lg-row justify-content-around align-items-center padding">
            <div className="d-flex flex-column justify-content-between how-box">
                <h1 className="imob-color-primary"><strong>Como funciona?</strong></h1>
                <div className="d-flex flex-column w-75">
                    <span className="mt-5 d-flex how-box-item align-items-start text-justify">
                        <img className="mt-1 target" alt="" src={Angle} />
                        <span className="text" style={{color: '#03619C'}}>Inclua as informações do imóvel, locatário e termos do aluguel de maneira fácil e intuitiva.</span>
                    </span>
                    <span className="mt-5 d-flex how-box-item align-items-start text-justify">
                        <img className="mt-1 target" alt="" src={Angle} />
                        <span className="text" style={{color: '#03619C'}}>Mensalmente enviamos a fatura e mantemos você atualizado sobre o status do pagamento.</span>
                    </span>
                    <span className="mt-5 d-flex how-box-item align-items-start text-justify">
                        <img className="mt-1 target" alt="" src={Angle} />
                        <span className="text" style={{color: '#03619C'}}>Nosso processo de confirmações de pagamento, repasse e geração de recibo é automatizado.</span>
                    </span>
                </div>
            </div>
            <img src={Smartphone} alt="" id="smartphone" />
        </div>
        <div className=" d-flex justify-content-around align-items-center padding pb-0">
            <div className="d-flex flex-column justify-content-between box-for-text">
                <h2 className="text-white"><strong>Transforme a gestão dos seus imóveis com a eu.imob</strong></h2>
                <p className="text-white">Automatize processos, Simplifique tarefas e liberte-se das complicações administrativas</p>
                <button onClick={() => window.open('/entrar')} className="btn lp-button mt-2 mb-5">Acesse agora</button>
            </div>
            <img src={Persona} alt="" id="persona" />
        </div>
        <footer>
            <h3>Entre em contato conosco</h3>
            <span><i className='bx bxl-whatsapp'></i> WhatsApp: <a href="tel:5511935020515" className="text-white">(11) 93502 0515</a></span>
            <br />
            <span><i className='bx bx-envelope' ></i> Email: <a href="mail:contato@euimob.com.br" className="text-white">contato@euimob.com.br</a></span>
            <hr />
            &copy; 2024 Eu.imob. Todos os direitos reservados.
        </footer>
    </div>
}

export default Index;