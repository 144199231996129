import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'
import Property from '../../../services/property';
import Loading from '../../../components/loading';
import ModalRegular from '../../../components/modal-regular';
import "../../../assets/styles/modal-step.css";
import { BiBuildingHouse } from "react-icons/bi";

const List = () => {
    const auth = useAuthUser();

    const [properties, setProperties] = useState([]);
    const [propertiesOriginal, setPropertiesOriginal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [selfId, setSelfId] = useState();

    useEffect(() => {
        Property.listByUser(auth().id, search, true).then(result => {
            setProperties(result.properties);
            setPropertiesOriginal(result.properties);
            setLoading(false);
        });
    }, [])

    const searchProperties = (all = true, is_active = true) => {
        //setLoading(true);
        Property.listByUser(auth().id, search, all, is_active).then(result => {
            setProperties(result.properties);
            setPropertiesOriginal(result.properties);
            setLoading(false);
        });
    }

    const togglePropertyState = async (currentState, id) => {
        const data = {
            only_state: true,
            is_active: !currentState,
            id
        }

        await Property.update(data);
        window.location.reload();
    }

    const searchOff = (available, rent) => {
        if (available) setProperties(propertiesOriginal.filter(a => !a.end_at || a.end_at < new Date().toISOString().slice(0, 100)))
        else if (rent) setProperties(propertiesOriginal.filter(a => a.end_at && a.end_at > new Date().toISOString().slice(0, 100)))
    }

    return loading ? <Loading /> :
        <div className="container-fluid my-properties d-md-block p-2" style={{ overflowY: 'scroll' }}>
            <ModalRegular feature="toggle-property-state" featureDisplay="Ativar Imóvel" view={<div className='d-flex justify-content-center flex-column align-items-center'>
                <h2><i style={{ color: 'var(--color-warning)' }} className='bx bx-info-circle'></i></h2>
                <p>Ao ativar o este imóvel, você concorda em pagar uma taxa única de [TAXA X] no seu cartão de crédito cadastrado. Este imóvel estará ativo por 30 dias. Se não for inativado antes do prazo, será feita uma cobrança recorrente a cada 30 dias. Próxima cobrança em [Data: 00/00/0000].</p>
                <button onClick={() => togglePropertyState(false, selfId)} className='btn imob-button btn-lg w-100'>Ativar imóvel</button>
                <button className='btn imob-button imob-button-outline btn-lg mt-2 w-100' data-bs-dismiss="modal" aria-label="Close">Cancelar</button>

            </div>} />
            <div className='d-flex justify-content-between' style={{ alignItems: "center" }}>
                <h2 className='d-flex align-items-center gap-2'><BiBuildingHouse size={35}/><strong>Meus imóveis cadastrados</strong></h2>
                <button data-bs-toggle="modal" data-bs-target="#property-creation-modal" className='btn btn-success btn-lg my-button'>Adicionar novo imóvel</button>
            </div>
            <div className='my-properties-container p-5'>
                <div className='actions-bar d-flex justify-content-between' style={{ alignItems: "center" }}>
                    <div className="input-group mb-3 w-25 my-search-box">
                        <span onClick={() => searchProperties()} className="input-group-text"><i className='bx bx-search'></i></span>
                        <input defaultValue={search} onKeyUp={(e) => {
                            setSearch(e.target.value);
                            searchProperties()
                        }}
                            type="text"
                            className="form-control"
                            placeholder="Procurar Imóvel" />
                    </div>
                    {/* <button className='btn btn-lg btn-outline-secondary my-button-neutral'><i className='bx bx-filter' ></i> Filtrar</button> */}
                    <div className="dropdown">
                        <button className="btn btn-lg btn-outline-secondary my-button-neutral" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='bx bx-filter' ></i> Filtrar
                        </button>
                        <ul className="dropdown-menu">
                            <li ><a onClick={(evt) => searchOff(false, true)} className="dropdown-item" href="#">Alugados</a></li>
                            <li><a onClick={(evt) => searchOff(true, false)} className="dropdown-item" href="#">Disponíveis</a></li>
                            <li onClick={(evt) => searchProperties(false, false)}><a className="dropdown-item" href="#">Inativos</a></li>
                            <li onClick={(evt) => searchProperties(true, true)}><a className="dropdown-item" href="#">Ver todos</a></li>
                        </ul>
                    </div>
                </div>
                {properties.length > 0 ?
                    <div className='properties-table-box d-md-block d-none'>
                        <table className='table table-hover-bg'>
                            <thead>
                                <tr>
                                    <td>Apelido</td>
                                    <td>Tipo</td>
                                    <td>Endereço</td>
                                    <td>UF</td>
                                    <td>Cidade</td>
                                    <td>Status</td>
                                    <td>
                                        Ativar/Desativar imóvels &nbsp;
                                        <i
                                            data-toggle="tooltip"
                                            data-html="true"
                                            data-placement="left"
                                            title="Ao ativar um imóvel, será cobrado uma taxa única. Apenas imóveis ativos geram cobranças."
                                            className='bx bx-info-circle'
                                        ></i>
                                    </td>
                                    {/* <td>Ação</td> */}
                                </tr>
                            </thead>
                            <tbody className='dataBodyImoveis'>
                                {properties.map((item, index) => <tr className='item-click' key={index} >
                                    <td className='databodyImoveisItem' onClick={() => window.location.href = `/imoveis/detalhe/${item.id}`} >{item.name}</td>
                                    <td className='databodyImoveisItem' onClick={() => window.location.href = `/imoveis/detalhe/${item.id}`} >{item.type.name}</td>
                                    <td className='databodyImoveisItem' onClick={() => window.location.href = `/imoveis/detalhe/${item.id}`} >{item.address}</td>
                                    <td className='databodyImoveisItem' onClick={() => window.location.href = `/imoveis/detalhe/${item.id}`} >{item.state}</td>
                                    <td className='databodyImoveisItem' onClick={() => window.location.href = `/imoveis/detalhe/${item.id}`} >{item.city}</td>
                                    <td className='databodyImoveisItem' onClick={() => window.location.href = `/imoveis/detalhe/${item.id}`} >
                                        {
                                            item.is_active !== "1" ? <span className='c-tag-outline'>Inativo</span> :
                                                !item.end_at || item.end_at < new Date().toISOString().slice(0, 100) ? <span className='c-tag-warning'>Disponível</span> :
                                                    item.end_at && item.end_at > new Date().toISOString().slice(0, 100) ? <span className='c-tag-success'>Alugado</span> : <span className='c-tag-warning'>Pendente</span>
                                        }
                                    </td>
                                    <td className='databodyImoveisItem'>
                                        {
                                            item.is_active === "1" ?
                                                <button onClick={() => togglePropertyState(true, item.id)} className={`btn imob-button imob-button-outline`}>Desativar imóvel</button> :
                                                <button data-bs-toggle="modal" onClick={() => setSelfId(item.id)} data-bs-target="#new-toggle-property-state-modal" className={`btn imob-button`}>Ativar imóvel</button>
                                        }
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    : <div className='text-center' style={{ marginTop: '120px' }}><h1><i className='bx bx-sad'></i></h1><span className=''>Nenhum imóvel encontrado...</span></div>}
            </div>
        </div>
}

export default List;
