import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout/layout";
import { BsCalendar3 } from "react-icons/bs";
import { RiCalendarTodoLine } from "react-icons/ri";
import { BsBank } from "react-icons/bs";
import Banner from "../../../assets/images/banner_home.png";
import User from "../../../services/user";
import { useAuthUser } from "react-auth-kit";
import Loading from "../../../components/loading";
import "./style.css";
import Logo from "../../../assets/images/logo_full.svg";

const Home = () => {
    const auth = useAuthUser();

    const [data, setData] = useState({
        "expired": { "count": "1", "sum": 1500 },
        "approaching": { "count": "2", "sum": 440 },
        "contracts": "6",
        "properties": "11"
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        User.home(auth().id).then((r) => {
            setData(r);
            setLoading(false);
        });
    }, []);
    

    return (
        <Layout>
            {loading ? <Loading /> : (
                <div className="container-fluid" style={{overflowY: 'auto'}}>
                    <img src={Banner} onClick={() => window.location = "/imoveis"} id="bannerHome" className="mt-5 col-12" alt="Banner eu.imob." style={{ maxWidth: '100%', cursor: 'pointer' }} />
                    <section className="containerGeral">
                        <div className="containerFaturas">
                            <div className="containerTitle">
                                <i><BsCalendar3 size={25} /></i>
                                <h4 className="title">Faturas vencidas</h4>
                            </div>
                            <hr />
                            <div className="containerContent">
                                <div>
                                    <strong className="containerSubTitle">Total de faturas vencidas</strong>
                                    <h2>{data.expired.count}</h2>
                                </div>
                                <div class="containerDetails" id="containerFaturaVencida">
                                    <strong className="containerSubTitle">Total do valor vencido</strong>
                                    <div>
                                        <h5>R$ </h5>
                                        <h2>{data.expired.sum}0,00</h2>
                                    </div>
                                </div>
                                <a className="btn btn-danger w-100 mt-2" href={`/faturas?ex=${new Date().getTime()}`}><strong>Ver detalhes</strong></a>
                                <p className="containerDescricao"> (Acesse informações detalhadas sobre cada fatura vencida, como histórico de pagamento e ações a serem tomadas).</p>
                            </div>
                        </div>
                        <div className="containerFaturas">
                            <div className="containerTitle">
                                <i><RiCalendarTodoLine size={25} /></i>
                                <h4 className="title">Faturas a vencer nesta semana</h4>
                            </div>
                            <hr />
                            <div className="containerContent">
                                <div>
                                    <strong className="containerSubTitle">Total de faturas vencidas</strong>
                                    <h2>{data.expired.count}</h2>
                                </div>
                                <div class="containerDetails" id="containerFaturaAvencer">
                                    <strong className="containerSubTitle">Total do valor vencido</strong>
                                    <div>
                                        <h5>R$ </h5>
                                        <h2>{data.expired.sum}0,00</h2>
                                    </div>
                                </div>
                                <a className="btn btn-warning w-100 mt-2" href={`/faturas?ap=${new Date().getTime()}`}><strong>Ver detalhes</strong></a>
                                <p className="containerDescricao"> (Acesse a lista de faturas programadas para vencer durante a semana atual).</p>
                            </div>
                        </div>
                        <div className="containerFaturas">
                            <div className="containerTitle">
                                <i><BsBank size={25} /></i>
                                <h4 className="title">Contratos ou imóveis cadastrados</h4>
                            </div>
                            <hr />
                            <div className="containerContent" id="containerImoveisCadastrados">
                                <div className="containerLogo">
                                    <img src={Logo} alt="Eu.Imob Logo"></img>
                                    <p className="containerDescricao">Este quadro fornece uma visão geral dos contratos de locação ou imóveis cadastrados pelos proprietários.</p>
                                </div>
                                <div className="containerImoveisContratos">
                                    <div>
                                        <p className="containerSubTitle">Contratos Ativos</p>
                                        <p className="value containerSubTitle">{data.contracts}</p>
                                    </div>

                                    <a href="/contratos" className="btn imob-button w-100 mt-2 btncontratos">Cadastrar novo contrato</a>

                                </div>
                                <div className="containerImoveisContratos">
                                { parseInt(data.properties) === 0 ? (<p className="containerDescricao text"> Você não tem imóveis cadastrados, clique no botão cadastrar novo imóvel</p>) : null
                                        }
                                    <div>
                                        <p  className="containerSubTitle" >Imoveis Cadastrados</p>
                                        <p className="value containerSubTitle">{data.properties}</p>
                                    </div>
                                    <a  href="/imoveis" className="btn imob-button w-100 mt-2 btnimoveis">Cadastrar novo imóvel</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </Layout>
    );
};

export default Home;
