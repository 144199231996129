import React, { createContext, useState, useContext } from 'react';

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [tokenWpp, setTokenWpp] = useState("");

  return (
    <TokenContext.Provider value={{ tokenWpp, setTokenWpp }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => useContext(TokenContext);