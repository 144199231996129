import { useAuthUser } from 'react-auth-kit'
import Layout from '../../../components/layout/layout';
import Loading from '../../../components/loading';
import Button from '../../../components/button';
import Pencil from '../../../components/icons/pencil';
import User from "../../../services/user";
import { useEffect, useState } from 'react';
import ProfilePhoto from "../../../assets/images/profile.jpg";
import LoadingOverlay from '../../../components/loading-overlay';
import './style.css'
import { Modal } from 'react-bootstrap';
import LogoHorizontal from '../../../assets/images/logo_full.svg'
import Contract from '../../../services/contract';

const Profile = () => {
    const auth = useAuthUser();

    const [otp, setOtp] = useState(new Array(6).fill(""));

    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [edit, setEdit] = useState(false);
    const [wppErrorMessage, setWppErrorMessage] = useState(false);
    const [tokenWpp, setTokenWpp] = useState("");
    const [modalMobile, setModalMobile] = useState(false)
    const [passwordForm, setPasswordForm] = useState({ password: "", newPassword: "", confirmNewPassword: "", errorMessage: "" })
    const [profileForm, setProfileForm] = useState({ name: auth().name + " " + auth().lastname, mobile: auth().mobile, email: auth().email, errorMessage: "", successMessage: "" })
    const [payment, setPayment] = useState(undefined);

    const updateProfile = async () => {
        setProfileForm({ ...profileForm, errorMessage: "" });
        if (profileForm.name && profileForm.mobile && profileForm.email) {
            const phone = profileForm.mobile.replace(/^(\d{2})\s?(\d{4,5})-?(\d{4})$/, '($1) $2-$3')
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(profileForm.email)) setProfileForm({ ...profileForm, errorMessage: "Por favor, insira um email válido." });
            else if (phone.length < 14 || phone.length > 15) setProfileForm({ ...profileForm, errorMessage: "Por favor, insira um telefone válido." });
            else {
                const parts = profileForm.name.split(' ', 2);
                if (parts.length === 2 && parts[1].trim() !== "") {
                    if (auth().mobile.replace(/^(\d{2})\s?(\d{4,5})-?(\d{4})$/, '($1) $2-$3') != phone && !modalMobile) {
                        const digitsOnly = phone.replace(/\D/g, '');
                        const formattedPhoneNumber = "55" + digitsOnly;
                        const response = await User.sendVerificationCode(formattedPhoneNumber);
                        setTokenWpp(response);
                        handleShow()
                    } else {
                        handleClose()
                        setLoadingOverlay(true)
                        const data = { name: parts[0], lastName: parts[1], email: profileForm.email, mobile: profileForm.mobile, id: auth().id };
                        await User.update(data);
                        setProfileForm({ ...profileForm, successMessage: " Ao acessar novamente, as alterações serão salvas." });
                        setLoadingOverlay(false)
                    }
                } else setProfileForm({ ...profileForm, errorMessage: "Adicione seu sobrenome antes de continuar." });
            }
        } else setProfileForm({ ...profileForm, errorMessage: "Preencha todos os campos antes de continuar." });
    }

    const sendCodeAgain = () => {
        const phone = profileForm.mobile.replace(/^(\d{2})\s?(\d{4,5})-?(\d{4})$/, '($1) $2-$3')
        const digitsOnly = phone.replace(/\D/g, '');
        const formattedPhoneNumber = "55" + digitsOnly;
        User.sendVerificationCode(formattedPhoneNumber).then((response) => {
            setTokenWpp(response);
            console.log(tokenWpp);
        });
    }

    const handleClose = () => setModalMobile(false);
    const handleShow = () => setModalMobile(true);

    function handleChange(e, index) {
        if (isNaN(e.target.value)) return false;
        setOtp([
            ...otp.map((data, indx) => (indx === index ? e.target.value : data)),
        ]);
        if (e.target.value && e.target.nextSibling) {
            e.target.nextSibling.focus()
        }
    }
    const getOtpString = () => {
        return otp.join('');
    };

    useEffect(() => {
        if (otp.join('').length === 6) {
            handleSubmit();
        }
    }, [otp]);

    useEffect(() => {
        Contract.getInfo(btoa(auth().id)).then((response) => {
            setPayment(response);
            console.log(response)
        })
    }, [])

    const handleSubmit = () => {
        const otpString = getOtpString();
        setWppErrorMessage("")
        User.validateWppCode({ token: tokenWpp, code: otpString }).then(({ success }) => {
            if (success === true) {
                updateProfile()
            } else setWppErrorMessage("Código inválido")
        })
    };

    function getProximoMesComData() {
        const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
        const hoje = new Date();
        const mesAtual = hoje.getMonth(); // Retorna o mês atual (0-11)
        const proximoMes = (mesAtual + 1) % 12; // Calcula o próximo mês, garantindo que seja de 0 a 11
        const anoAtual = hoje.getFullYear();
        const diaAtual = hoje.getDate();
        const proximoAno = (proximoMes === 0) ? anoAtual + 1 : anoAtual; // Se o próximo mês for janeiro (0), incrementa o ano
        return `${meses[proximoMes]} de ${proximoAno}`;
    }

    const updatePassword = async () => {
        setPasswordForm({ ...passwordForm, errorMessage: "" })

        if (passwordForm.password && passwordForm.newPassword && passwordForm.confirmNewPassword) {
            if (passwordForm.newPassword === passwordForm.confirmNewPassword) {
                if (passwordForm.password !== passwordForm.newPassword) {
                    if (passwordForm.newPassword.length > 7) {
                        let response = await User.login(auth().email, passwordForm.password);
                        if (response.success) {
                            const { id, name, lastname, email, mobile } = auth();
                            const data = { id, name, lastName: lastname, email, mobile, password: passwordForm.newPassword };
                            await User.update(data);
                            window.location.reload();
                        } else setPasswordForm({ ...passwordForm, errorMessage: "A senha atual não está correta." })
                    } else setPasswordForm({ ...passwordForm, errorMessage: "A senha deve ter 8 caracteres ou mais." })
                } else setPasswordForm({ ...passwordForm, errorMessage: "A nova senha não pode ser a mesma que a atual." })
            } else setPasswordForm({ ...passwordForm, errorMessage: "A confirmação da nova senha não confere." })
        } else setPasswordForm({ ...passwordForm, errorMessage: "Preencha todos os campos antes de continuar." })
    }

    const updateCard = async () => {
        Contract.updateCard(btoa(auth().id)).then((response) => {
            window.location.href = response.session;
        })
    }

    return (
        <Layout>
            {loadingOverlay && <LoadingOverlay />}

            <Modal show={modalMobile} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='containerGeralStep02'>
                        <figure>
                            <img src={LogoHorizontal} alt='EuImob logo'></img>
                        </figure>
                        <div className='containerInfoStep02 mt-4'>
                            <h2 className='tituloFormOtp'>Informe o código</h2>
                            <span>Foi enviado um código de verificação em seu WhatsApp</span>
                            {wppErrorMessage && <div className="mt-3" role="alert">
                                <div className="alert imob-alert imob-alert-danger" role="alert">
                                    <i className='bx bx-info-circle'></i>
                                    <strong> Atenção! </strong> {wppErrorMessage}
                                </div>
                            </div>}
                            <form>
                                <label className='labelInputOtp'>Digite o código</label>

                                <div className='containerOtpInput'>
                                    {otp.map((data, i) => {
                                        return <input type='tel'
                                            value={data}
                                            maxLength={1}
                                            onChange={(e) => handleChange(e, i)}
                                            className='inputOtp' />
                                    })}
                                </div>
                                {<p className=" textReenviarCodigo" onClick={sendCodeAgain}>Reenviar código</p>}
                            </form>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {auth ? (
                <main className='containerProfileGeral'>
                    <div className='containerProfileContent'>
                        <div className='profileContent'>
                            <div className='profileContentHeader'>
                                <h2>Informação da conta</h2>
                                <Button
                                    onClick={() => setEdit(!edit)}
                                    customClass={"imob-button-outline"}
                                    display="Editar"
                                    icon={<Pencil />}
                                />
                            </div>
                            <div className='profileImage'>
                                <img src={ProfilePhoto} width={120} className="rounded-circle" alt="Profile" />
                            </div>
                            {profileForm.errorMessage !== "" ? <div className="w-100" role="alert">
                                <div className="alert imob-alert imob-alert-danger" role="alert">
                                    <i className='bx bx-info-circle'></i>
                                    <strong> Oops! </strong> {profileForm.errorMessage}
                                </div>
                            </div> : <></>}
                            {profileForm.successMessage !== "" ? <div className="w-100" role="alert">
                                <div className="alert imob-alert imob-alert-success" role="alert">
                                    <i className='bx bx-info-circle'></i>
                                    <strong> Tudo certo! </strong> {profileForm.successMessage}
                                </div>
                            </div> : <></>}
                            <div className='profileBody'>
                                <div className='dadosProfile'>
                                    <div className="content-body-field w-100">
                                        <label htmlFor="name" className="form-label" style={{ fontSize: '1em' }}>
                                            Nome completo
                                        </label>
                                        <input
                                            name="name"
                                            id="name"
                                            disabled={edit ? false : "disabled"}
                                            type="text"
                                            className="form-control input mb-2"
                                            defaultValue={auth().name + " " + auth().lastname}
                                            onKeyUp={(evt) => setProfileForm({ ...profileForm, name: evt.target.value })}
                                        />
                                    </div>
                                    <div className="content-body-field w-100">
                                        <label htmlFor="document" className="form-label" style={{ fontSize: '1em' }}>
                                            Documento
                                        </label>
                                        <input
                                            name="document"
                                            id="document"
                                            disabled="disabled"
                                            type="text"
                                            className="form-control input mb-2"
                                            defaultValue={auth().document}
                                        />
                                    </div>
                                    <div className="content-body-field w-100">
                                        <label htmlFor="update_index_id" className="form-label" style={{ fontSize: '1em' }}>
                                            Número WhatsApp
                                        </label>
                                        <input
                                            name="name"
                                            id="name"
                                            disabled={edit ? false : "disabled"}
                                            type="text"
                                            className="form-control input mb-2"
                                            defaultValue={auth().mobile}
                                            onKeyUp={(evt) => setProfileForm({ ...profileForm, mobile: evt.target.value })}
                                        />
                                    </div>
                                    <div className="content-body-field w-100">
                                        <label htmlFor="email" className="form-label" style={{ fontSize: '1em' }}>
                                            E-mail
                                        </label>
                                        <input
                                            name="email"
                                            id="email"
                                            disabled={edit ? false : "disabled"}
                                            type="text"
                                            className="form-control input mb-2"
                                            defaultValue={auth().email}
                                            onKeyUp={(evt) => setProfileForm({ ...profileForm, email: evt.target.value })}

                                        />
                                    </div>
                                    <div className="content-body-field w-100">
                                        <button
                                            style={{ margin: '15px 0 0' }}
                                            disabled={edit ? false : "disabled"}
                                            className="btn imob-button"
                                            onClick={() => updateProfile()}
                                        >
                                            Atualizar Dados
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div className="content-body-field w-100">
                                        <div className="content-header-title mb-2">
                                            <strong>Configurações de segurança</strong>
                                        </div>
                                        {passwordForm.errorMessage !== "" ? <div className="w-100" role="alert">
                                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                                <i className='bx bx-info-circle'></i>
                                                <strong> Oops! </strong> {passwordForm.errorMessage}
                                            </div>
                                        </div> : <></>}
                                        <label htmlFor="password" className="form-label" style={{ fontSize: '1em' }}>
                                            Senha atual
                                        </label>
                                        <input
                                            name="password"
                                            id="password"

                                            onKeyUp={(evt) => setPasswordForm({ ...passwordForm, password: evt.target.value })}
                                            type="password"
                                            className="form-control input"
                                        />
                                        <label htmlFor="password" className="form-label" style={{ fontSize: '1em' }}>
                                            Nova senha
                                        </label>
                                        <input
                                            name="password"
                                            id="password"
                                            onKeyUp={(evt) => setPasswordForm({ ...passwordForm, newPassword: evt.target.value })}
                                            type="password"
                                            className="form-control input"
                                        />
                                        <label htmlFor="password" className="form-label" style={{ fontSize: '1em' }}>
                                            Confirmar nova senha
                                        </label>
                                        <input
                                            name="password"
                                            id="password"
                                            onKeyUp={(evt) => setPasswordForm({ ...passwordForm, confirmNewPassword: evt.target.value })}
                                            type="password"
                                            className="form-control input"
                                        />
                                    </div>
                                    <div className="content-body-field w-100">
                                        <button
                                            style={{ margin: '15px 0 0' }}
                                            className="btn imob-button"
                                            onClick={() => updatePassword()}
                                        >
                                            Alterar senha
                                        </button>
                                        <button
                                            style={{ margin: '15px 0 0 15px', background: 'red' }}
                                            className='btn imob-button'
                                            onClick={() => window.location.href = "/sair"}
                                        >
                                            Sair
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='paymentContent'>
                            <h2>Dados de pagamento</h2>
                            {payment ? <>
                                <strong>R$ {payment.total}/ 30 dias</strong>
                                <span>Renova em {payment.data_cobranca} </span>
                                <h5>Cartão cadastrado</h5>
                                <span className='d-flex align-items-center'>
                                    <i className='bx bx-credit-card'></i>
                                    <span style={{ marginLeft: '5px' }}>termina em {payment.card}</span>
                                    <span onClick={() => updateCard()} style={{ color: 'var(--color-primary)', textDecoration: 'underline', marginLeft: '10px', cursor: 'pointer' }}>Alterar</span>
                                </span>
                            </> : <>Nenhuma informação cadastrada até o momento.</>}
                        </div>
                    </div>
                </main>
            ) : <Loading />}

        </Layout>
    )
}

export default Profile;
