import { useEffect, useState } from "react";

import Layout from "../../components/layout/layout";
import List from "./shared/list";

import "../../assets/styles/property.css";
import New from "./new";
import ListMobile from "./shared/list-mobile";

const Contracts = () => {
    const [mobile, setMobile] = useState(null);

    useEffect(() => {
        if (window.matchMedia("(max-width: 768px)").matches) setMobile(true)
        else setMobile(false);

        const params = new URLSearchParams(window.location.search);
        const activatedParams = params.get('activated');

        if (activatedParams === 'true') {
            setTimeout(() => {
                let button = document.querySelector('[data-bs-target="#new-contract-modal"]');
                if (button) button.click();
                params.delete('activated');
                const newUrl = `${window.location.pathname}?${params.toString()}`;
                window.history.replaceState({}, '', newUrl);
            }, 1000);
        }
    }, []);

    return <Layout>
        {mobile ? <ListMobile /> : <List />}
        <New />
    </Layout>
}

export default Contracts;