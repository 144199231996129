import Admin from "../../services/admin";
import Layout from "../../components/layout-admin/layout";
import { useEffect, useState } from "react";
import LoadingOverlay from "../../components/loading-overlay";
import Time from "../../hooks/time";

const PassOut = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Admin.passOutList().then(response => {
      setData(response.data);
      setLoading(false);
    })
  }, [])

  const passOutTrue = (id) => {
    Admin.passOut(id).then(() => window.location.reload() ).catch((e) => console.log(e))
  }

  return (
    <Layout>
      {loading && <LoadingOverlay />}
      <div id="layout-content-container">
        <div className="content">
          <div className="content-header">
            <div className="content-header-title">
              <span>Lista de Repasses Pendentes</span>
            </div>
          </div>
          {data.length > 0 ? <div className="content-body">
            <div className='properties-table-box d-md-block d-none'>
              <table className='table'>
                <thead>
                  <tr>
                    <td>Fatura</td>
                    <td>Valor</td>
                    <td>Pago em</td>
                    <td>Enviar para</td>
                    <td>Ação</td>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => <tr className='item-click' key={index}>
                    <td>{item.invoice_id}</td>
                    <td>R$ {item.adjusted_total_value}</td>
                    <td>{Time.formatToDDMMYYYY(item.paid_out_date)}</td>
                    <td> {item.bank_name ? `${item.bank_name} - ${item.agency} - ${item.account}` : `PIX: ${item.pix}`}</td>
                    <td><button onClick={() => passOutTrue(item.invoice_id)} className="btn btn-outline-success">Registrar Baixa</button></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div> : <></>
          }
        </div>
      </div>
    </Layout>
  );
};

export default PassOut;
