import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import LoadingBasic from "../../../components/loading-basic";
import RetangularItem from "../../../components/rectangular-item";

const Step01 = ({ config, setForm, form, switchStep, properties }) => {
    const [searchCriteria, setSearchCriteria] = useState("");

    const search = (criteria) => {
        setSearchCriteria(criteria);
        properties.items = properties.original;

        let newPropertiesList = [];

        properties.items.forEach(property => {
            if (property.name.toLowerCase().includes(criteria.toLowerCase())) newPropertiesList.push(property);
            else if (property.address.toLowerCase().includes(criteria.toLowerCase())) newPropertiesList.push(property);
            else if (property.type.name.toLowerCase().includes(criteria.toLowerCase())) newPropertiesList.push(property);
        });

        properties.items = newPropertiesList;
    }

    const selectProperty = (id, sample) => {
        if(document.querySelectorAll('.retangular-item')) document.querySelectorAll('.retangular-item').forEach(item => item.classList.remove('selected'));
        if(document.getElementById(`retangular-item-${id}`)) document.getElementById(`retangular-item-${id}`).classList.toggle('selected');
        if (!sample) {
            let property_name = "", property_type = ""
            properties.items.forEach(i => {
                if(i.id === id) {
                    property_type = i.type.name;
                    property_name = i.name;
                }
            });
            setForm({ ...form, property_id: id, property_name, property_type })
        };
    }

    const next = () => {
        if(document.getElementById("step-01-required")) document.getElementById("step-01-required").classList.add('d-none');
        if (form.property_id !== null) switchStep({ ...config, currentStep: 2 });
        else if(document.getElementById("step-01-required")) document.getElementById("step-01-required").classList.remove('d-none');
    }

    useEffect(() => {
        if (form.property_id) selectProperty(form.property_id, true);
    }, [form.property_id])

    return <>
        <span className="modal-step-title">Boas-vindas!</span>
        <p className="modal-step-text">Vamos iniciar o cadastro do seu contrato?</p>
        <p className="modal-step-text">Vincule o seu imóvel!</p>
        <div id="step-01-required" className="d-none w-100">
            <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong> Selecione</strong> um imóvel antes de continuar.
            </div>
        </div>
        <div className={`input-group mb-3 modal-step-search ${(properties && properties.items && properties.items.length === 0) && !properties.loading && searchCriteria != "" ? 'search-criteria-not-foud' : ''}`}>
            <span className="input-group-text icon"><i className='bx bx-search'></i></span>
            <input onKeyUp={(evt) => search(evt.target.value)} type="text" className="form-control input" placeholder="Busque por nome, tipo ou endereço" />
            <Button onClick={() => switchStep({ ...config, currentStep: 10 })} customStyle={{ 'marginLeft': '10px', 'borderRadius': '5px', 'width': '20%' }} display="Novo" />
        </div>
        <div className="modal-step-fields">
            {properties && !properties.loading ? properties.items.length > 0 ? properties.items.map((property, index) => <RetangularItem key={index} id={property.id} columnOne={property.name} columnTwo={property.type.name} columnThree={property.address} onClick={selectProperty} />) : <>
                {searchCriteria != "" ? <div cclassNamelass="alert imob-alert imob-alert-danger" role="alert">
                    <i className='bx bx-info-circle'></i>
                    <strong>
                        Não encontramos um imóvel correspondente aos critérios de pesquisa.
                        Você pode verificar as informações inseridas ou <strong onClick={() => switchStep({ ...config, currentStep: 10 })}><u>cadastrar um novo imóvel</u></strong>.
                    </strong>
                </div> : <>Você ainda não tem um imóvel cadastrado. <strong onClick={() => switchStep({ ...config, currentStep: 10 })}><u>Clique aqui para adicionar um novo</u></strong>.</>}
            </> : <LoadingBasic />}
        </div>
        <div className="modal-step-actions">
            <button data-bs-dismiss="modal" aria-label="Close" className={`btn imob-button imob-button-neutral`}>Cancelar</button>
            <Button onClick={next} display="Continuar" />
        </div>
    </>
}

export default Step01;