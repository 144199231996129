import React from "react";
import StepTenant from "./new/step";

const New = () => {
    return <div className="modal fade" id="tenant-creation-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="tenant-creation-modal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="btn-close c-btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body property-creation">
                    <StepTenant />
                </div>
            </div>
        </div>
    </div>
}

export default New;