import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'

import Loading from '../../../components/loading';
import Contract from '../../../services/contract';
import { GrDocumentText } from "react-icons/gr";
import ExplanationImage from './explanation';


const ContractListComponent = () => {
    const auth = useAuthUser();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [isActive, setIsActive] = useState(null);
    const [firstContract, setFirstContract] = useState(false)

    useEffect(() => {
        setLoading(false);
        Contract.contractList({ search: searchCriteria, user: auth().id }).then(response => {
            setData(response.contracts);
            if(!response?.contracts?.length > 0) setFirstContract(true)
        })
    }, [])

    const searchProperties = () => {
        //setLoading(true);
        Contract.contractList({ search: searchCriteria, user: auth().id, is_active: isActive }).then(response => {
            setData(response.contracts);
            setLoading(false);
        })
    }

    useEffect(() => {
        searchProperties()
    }, [isActive])

    return loading ? <Loading /> :
        <div className="container-fluid my-properties d-md-block p-2" style={{ overflowY: 'scroll' }}>
            {firstContract ? <ExplanationImage /> : <></>} 
            <div className='d-flex justify-content-between' style={{ alignItems: "center" }}>
                <h2 className='d-flex gap-2 p-3'><GrDocumentText size={30}/><strong>Meus contratos cadastrados</strong></h2>
                <button data-bs-toggle="modal" data-bs-target="#new-contract-modal" className='btn btn-success btn-lg my-button'>Adicionar novo contrato</button>
            </div>
            <div className='my-properties-container p-5'>
                <div className='actions-bar d-flex justify-content-between' style={{ alignItems: "center" }}>
                    <div className="input-group mb-3 w-25 my-search-box">
                        <span onClick={() => searchProperties()} className="input-group-text"><i className='bx bx-search'></i></span>
                        <input defaultValue={searchCriteria} onKeyUp={(e) => {
                            setSearchCriteria(e.target.value);
                            searchProperties()}
                        }
                            type="text"
                            className="form-control"
                            placeholder="Procurar Contrato" />
                    </div>
                    <div className="dropdown">
                        <button className="btn btn-lg btn-outline-secondary my-button-neutral" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='bx bx-filter' ></i> Filtrar
                        </button>
                        <ul className="dropdown-menu">
                            <li onClick={(evt) => setIsActive(true)}><a className="dropdown-item" href="#">Ativo</a></li>
                            <li onClick={(evt) => setIsActive(false)}><a className="dropdown-item" href="#">Inativos</a></li>
                            <li onClick={(evt) => setIsActive(null)}><a className="dropdown-item" href="#">Ver todos</a></li>
                        </ul>
                    </div>
                </div>
                {data?.length > 0 ?
                    <div className='properties-table-box d-md-block d-none'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td>Imóvel</td>
                                    <td>Locatário</td>
                                    <td>Valor Aluguel</td>
                                    <td>Dia de Venciamento</td>
                                    <td>Status</td>
                                    {/* <td>Ação</td> */}
                                </tr>
                            </thead>
                            <tbody className='dataBodyImoveis'>
                                {data.map(item => <tr className='item-click' onClick={() => window.location.href = `/contratos/detalhe/${item.id}`} key={item.id}>
                                    <td className='databodyImoveisItens'>{item.property_name}</td>
                                    <td className='databodyImoveisItens'>{item.tenant_name}</td>
                                    <td className='databodyImoveisItens'>R$ {item.rent_value}</td>
                                    <td className='text-center databodyImoveisItens'>{item.pay_day}</td>
                                    <td className='databodyImoveisItens'>
                                        {item.is_active !== "1" ? <span className='c-tag-outline'>Inativo</span> :
                                            !item.end_at || item.end_at < new Date().toISOString().slice(0, 100) ? <span className='c-tag-warning'>Finalizado</span> :
                                                item.end_at && item.end_at > new Date().toISOString().slice(0, 100) ? <span className='c-tag-success'>Ativo</span> : <></>}
                                    </td>
                                    {/* <td>
                                        <a href={`/contratos/detalhe/${item.id}`} className='btn'><i className='bx bx-edit-alt'></i></a>
                                        <button className='btn'><i className='bx bx-trash'></i></button>
                                    </td> */}
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    : <div className='text-center' style={{ marginTop: '120px' }}><h1><i className='bx bx-sad'></i></h1><span className=''>Nenhum contrato encontrado...</span></div>}
            </div>
        </div>
}

export default ContractListComponent;