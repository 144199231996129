export default class Tenant {
    static listByUser = async (user, search, all, is_active) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-renter-list`, { method: 'POST', body: JSON.stringify({ user, search, all, is_active, page: 0 }) })

        return await response.json();
    }

    static create = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-renter-add`, { method: 'POST', body: JSON.stringify(data) })

        return await response.json();
    }


    static detail = async (id, user) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-renter-details`, { method: 'POST', body: JSON.stringify({ id, user }) });

        return await response.json();
    }

    static update = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-renter-edit`, { method: 'POST', body: JSON.stringify(data) });

            return response.status;
        } catch {
            return false;
        }
    }

    static cnpj = async (data) => {
        try {
            data = data.replace(/[./-]/g, '');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/tenant/${data}/cnpj`);

            if (response.ok) {
                data = await response.json()
                return {
                    razao_social: data?.razao_social,
                    nome_fantasia: data?.nome_fantasia
                }
            }

            return true;
        } catch (error) {
            return false;
        }
    }

    static cpf = async (data) => {
        try {
            data = data.replace(/[./-]/g, '');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tenant/${data}/cpf`);
            if (response.ok) {
                data = await response.json()
                return {
                    razao_social: data?.razao_social
                }
            }
        } catch (error) {
            return false
        }
    }
}