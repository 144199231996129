import React, { useEffect, useState } from 'react';
import "./style.css"
import LogoHorizontal from '../../../../assets/images/logo_full.svg'
import { IoMdArrowRoundBack } from "react-icons/io";
import { useInfo } from '../../../../context/UserContext';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

function Step03({ onNextStep, onPrevStep }) {

    const { userData, setUserData } = useInfo();
    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [documentError, setDocumentError] = useState("");

    const [mask, setMask] = useState("");

    useEffect(() => {
        console.log(userData)
    }, [userData]);

    const validateAndSubmit = (e) => {
        e.preventDefault();
        setEmailError("");
        setNameError("");
        setDocumentError("");

        const email = userData.email;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) setEmailError("Por favor, insira um email válido.");

        const fullName = userData.name.trim();
        const nameParts = fullName.split(" ");
        if (nameParts.length < 2) setNameError("Por favor, insira seu nome completo.");

        const document = userData.document;
        const documentPattern = /^(?:\d{3}\.\d{3}\.\d{3}-\d{2}|\d{11}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{14})$/;
        if(!documentPattern.test(document)) setDocumentError("Por favor, insira um documento válido.")

        if (!emailPattern.test(email) || nameParts.length < 2 || !documentPattern.test(document)) return;

        const name = nameParts[0];
        const lastName = nameParts.slice(1).join(" ");

        setUserData(prevUserData => ({
            ...prevUserData,
            name,
            lastName
        }));

        onNextStep();
    }

    return (
        <>
            <section className='containerGeralStep03'>
                <IoMdArrowRoundBack size={20} onClick={onPrevStep} />
                <figure>
                    <img src={LogoHorizontal} alt='EuImob logo'></img>
                </figure>
                <div className='containerInfoStep03'>
                    <h2 className='tituloFormOtp'>Estamos quase lá</h2>
                    <strong className='text01'>Para continuarmos, precisamos de mais algumas informações</strong>
                </div>
                <form>
                    <label htmlFor="email-input" style={{ fontWeight:'lighter' }}><strong>Informe seu email</strong></label>
                    <input
                        placeholder="eu.imob@euimob.com.br"
                        autoComplete="email"
                        type="email"
                        className="form-control inputLogin mb-3 mt-1"
                        id="email-input"
                        value={userData.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                    />
                    {emailError && (
                        <div className="" role="alert">
                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                <i className='bx bx-info-circle'></i>
                                <strong> Atenção! </strong> {emailError}
                            </div>
                        </div>
                    )}
                    <label htmlFor="name-input" className='mt-3'  style={{ fontWeight:'lighter' }}><strong>Nome completo</strong></label>
                    <input
                        placeholder="Nome Sobrenome"
                        type="text"
                        className="form-control inputLogin mb-3 mt-1"
                        id="name-input"
                        value={userData.name}
                        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                    />
                    {nameError && (
                        <div className="" role="alert">
                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                <i className='bx bx-info-circle'></i>
                                <strong> Atenção! </strong> {nameError}
                            </div>
                        </div>
                    )}
                    <label htmlFor="document-input" className='mt-3'  style={{ fontWeight:'lighter' }}><strong>CPF ou CNPJ</strong></label>
                    <CpfCnpj 
                        value={userData.document} 
                        onChange={(event, type) => {
                            setUserData({ ...userData, document: event.target.value })
                            setMask(type === "CPF");
                        }}
                        placeholder="CPF ou CNPJ"
                        className="form-control inputLogin mb-3 mt-1"
                    />
                    {/* <input
                        placeholder="CPF ou CNPJ"
                        type="text"
                        className="form-control inputLogin mb-3 mt-1"
                        id="document-input"
                        value={userData.document}
                        onChange={(e) => setUserData({ ...userData, document: e.target.value })}
                    /> */}
                    {documentError && (
                        <div className="" role="alert">
                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                <i className='bx bx-info-circle'></i>
                                <strong> Atenção! </strong> {documentError}
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-between w-100 mt-4">
                        <button onClick={validateAndSubmit} style={{ color: "white" }} className="btn imob-button btn-lg w-100">Criar</button>
                    </div>
                </form>
            </section>
        </>
    );
}

export default Step03;
