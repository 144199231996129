import { useParams } from "react-router-dom";
import { useAuthUser } from 'react-auth-kit'
import { useEffect, useState } from "react";
import "./style.css"

import Layout from "../../components/layout/layout";
import Tenant from "../../services/tenant";

import "../../assets/styles/property.css";
import LoadingOverlay from "../../components/loading-overlay";
import LoadingBasicFull from "../../components/loading-basic-full";
import InputMobileNumber from "../../components/input-mobile";
import { RiContactsBookLine } from "react-icons/ri";
import { RiShieldUserLine } from "react-icons/ri";
import Button from "../../components/button";
import Pencil from "../../components/icons/pencil";


const Detail = () => {
    const auth = useAuthUser();
    let { id } = useParams();
    const [tenant, setTenant] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!id) window.location.href = '/locatarios';
        else {
            Tenant.detail(id, auth().id).then(response => {
                if (!response.success) window.location.href = '/imoveis';
                else {
                    setTenant(response.tenant);
                }
            });
        }
    }, [])

    const emailValidate = () => {
        var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regex.test(tenant.email);
    }

    const mobileValidete = () => {
        var regex = /^\(?\d{2}\)?[\s-]?9?\d{4}[\s-]?\d{4}$/;
        const mobile = tenant.mobile.replace("+55 ", "").replace(" ", "").replace("(", "").replace(")", "").replace("-", "").replace("_", "")
        return regex.test(mobile);
    }

    const update_all_tenant = () => {
        setErrorMessage("");

        if (!tenant.nickname || tenant.nickname.trim().length === 0) {
            setErrorMessage('O apelido é obrigatório.');
        } else if (!tenant.contact_name || tenant.contact_name.length < 2) {
            setErrorMessage("Nome do contrato é obrigatório")
        }
        else if (mobileValidete()) {
                setLoadingOverlay(true);
                Tenant.update({
                    ...tenant,
                    updated_by: auth().id,
                    id: parseFloat(id),
                    nickname: tenant.nickname ?? ""
                }).then(response => {
                    if (response === 200) {
                        window.location.reload();
                        console.log(response)
                    }
                    else {
                        setLoadingOverlay(false);
                        console.log(response)
                        setErrorMessage('Não foi possível atualizar, tente novamente.');
                    }
                });
        } else setErrorMessage("O número de telefone digitado não é válido.");
    }

    return <Layout>
        {loadingOverlay && <LoadingOverlay />}
        {tenant === null ? <div style={{ height: '100vh', display: 'flex', 'justifyContent': 'center', 'flexDirection': 'column', alignItems: 'center', margin: 'auto', }}><LoadingBasicFull /></div> :
            <div className="container-fluid" style={{ overflowY: 'scroll', alignItems: 'center' }}>
                <div className='my-properties-container  property-details '>
                    <div style={{ padding: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <div className="d-flex justify-content-between topo flex-row w-100 p-2" style={{ borderBottom: '1px solid  #d8d6d6', borderWidth: '0.1rem' }}>
                            <h4 className="content-detalhes-title d-flex align-items-center gap-2">
                                <RiShieldUserLine size={25} color="#03619c" />Detalhes do cadastro
                            </h4>
                            <Button
                                onClick={() => setEdit(!edit)}
                                customClass={"imob-button-outline"}
                                display="Editar"
                                icon={<Pencil />}
                            />
                            <div className="content-header-actions-mobile ">
                                {/* <button className="imob-button-neutral imob-button-neutral-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='bx bx-dots-horizontal-rounded dots-icon' ></i>
                                </button>
                                <ul className="dropdown-menu my-dropdown">
                                    <li><a className="dropdown-item" onClick={() => setEdit(true)} href={`#`}><i className='bx bx-edit-alt'></i><span>Editar</span></a></li>
</ul>*/}
                            </div>
                        </div>

                        {errorMessage ? <div className="alert imob-alert imob-alert-danger" role="alert">
                            <i className='bx bx-info-circle'></i>
                            <strong> Atenção! </strong> {errorMessage}
                        </div> : <></>}
                        <div className="d-flex justify-content-start flex-column flex-lg-row w-100">
                            <div className="m-3 input-100 w-100">
                                <label htmlFor="document" className="form-detalhes-label">CPF ou CNPJ</label>
                                <input disabled='disabled' onKeyUp={(evt) => setTenant({ ...tenant, document: evt.target.value })} defaultValue={tenant.document} type="text" className="form-control" id="document" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-lg-row w-100 ">
                            <div className="m-3 input-100 w-100 ">
                                <label htmlFor="name" className="form-detalhes-label">Razão Social ou Nome:</label>
                                <input disabled='disabled' onKeyUp={(evt) => setTenant({ ...tenant, name: evt.target.value })} defaultValue={tenant.name} type="text" className="form-control" id="name" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-lg-row w-100">
                            <div className="m-3 input-100 w-100">
                                <label htmlFor="nickname" className="form-detalhes-label">Nome Fantasia ou Apelido:</label>
                                <input disabled={true} onKeyUp={(evt) => setTenant({ ...tenant, nickname: evt.target.value })} defaultValue={tenant.nickname} type="text" className="form-control" id="nickname" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between topo flex-row w-100 mt-5 p-2" style={{ borderBottom: '1px solid  #d8d6d6' }}>
                            <h4 className="content-detalhes-title d-flex align-items-center gap-2"><RiContactsBookLine size={25} color="#03619c" />
                                Contato de cobrança</h4>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-lg-row w-100">
                            <div className="m-3 input-100 w-100">
                                <label htmlFor="contact_name" className="form-detalhes-label">Nome do Contato:</label>
                                <input disabled={edit ? false : 'disabled'} onKeyUp={(evt) => setTenant({ ...tenant, contact_name: evt.target.value })} defaultValue={tenant.contact_name} type="text" className="form-control" id="contact_name" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-lg-row w-100">
                            <div className="m-3 input-100 w-100">
                                <label htmlFor="mobile" className="form-detalhes-label">Telefone (Whatsapp):</label>
                                <InputMobileNumber disabled={edit ? false : 'disabled'} onKeyUp={(evt) => setTenant({ ...tenant, mobile: evt.target.value })} defaultValue={tenant.mobile} type="text" className="form-control" id="mobile" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-lg-row w-100">
                            <div className="m-3 input-100 w-100">
                                <label htmlFor="email" className="form-detalhes-label">E-mail</label>
                                <input disabled={edit ? false : 'disabled'} onKeyUp={(evt) => setTenant({ ...tenant, email: evt.target.value })} defaultValue={tenant.email} type="text" className="form-control" id="email" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center w-100">
                            <button disabled={edit ? false : 'disabled'} className="btn imob-button" onClick={() => update_all_tenant()}>Confirmar</button>
                        </div>
                    </div>
                </div>

            </div>}
    </Layout>
}

export default Detail;