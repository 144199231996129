// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 992px) {
    .labelInputTermos {
        font-size: larger;
    }
}   
@media only screen and (min-width: 993px) {
    .labelInputTermos {
        font-size: medium;
    }
}   `, "",{"version":3,"sources":["webpack://./src/views/contract/new/style.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["@media only screen and (max-width: 992px) {\n    .labelInputTermos {\n        font-size: larger;\n    }\n}   \n@media only screen and (min-width: 993px) {\n    .labelInputTermos {\n        font-size: medium;\n    }\n}   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
