import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'

import Tenant from '../../../services/tenant';
import "./style.css"

const ListComponent = () => {
    const auth = useAuthUser();

    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        Tenant.listByUser(auth().id, search, true).then(result => {
            setLoading(false);
            if (result.users != null) setTenants(result.users);
        });
    }, []);

    const searchTenant = (all = true, is_active = true) => {
        setLoading(true);
        Tenant.listByUser(auth().id, search, all, is_active).then(result => {
            setTenants(result.users);
            setLoading(false);
        });
    }

    return <div className="container-fluid my-properties d-md-block p-2" style={{ overflowY: 'scroll' }}>
        <div className='d-flex justify-content-between' style={{ alignItems: "center" }}>
            <h2 className='d-flex align-items-center gap-2' style={{ fontWeight: 'bold' }}><i className='bx bx-group' ></i> Lista de locatários</h2>
            <button data-bs-toggle="modal" data-bs-target="#tenant-creation-modal" className='btn btn-success btn-lg my-button'>Adicionar Novo Locatário</button>
        </div>
        <div className='my-properties-container p-5'>
            <div className='actions-bar d-flex justify-content-between' style={{ alignItems: "center" }}>
                <div className="input-group mb-3 w-25 my-search-box">
                    <span onClick={() => searchTenant()} className="input-group-text"><i className='bx bx-search'></i></span>
                    <input
                        onKeyUp={(e) => {
                            setSearch(e.target.value); 
                            searchTenant(); 
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Procurar Locatário"
                    />
                </div>
                {/* <button className='btn btn-lg btn-outline-secondary my-button-neutral'><i className='bx bx-filter' ></i> Filtrar</button> */}
                {/* <div class="dropdown">
                        <button class="btn btn-lg btn-outline-secondary my-button-neutral" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='bx bx-filter' ></i> Filtrar
                        </button>
                        <ul class="dropdown-menu">
                            <li onClick={(evt) => searchTenant(false, true)}><a class="dropdown-item" href="#">Ativos</a></li>
                            <li onClick={(evt) => searchTenant(false, false)}><a class="dropdown-item" href="#">Inativos</a></li>
                            <li onClick={(evt) => searchTenant(true, true)}><a class="dropdown-item" href="#">Ver todos</a></li>
                        </ul>
                    </div>*/}
            </div>
            <div className='properties-table-box d-md-block d-none '>
                <table className='table'>
                    <thead>
                        <tr>
                            <td>Nome/Fantasia</td>
                            <td>CPF/CNPJ</td>
                            <td>Nome do contato</td>
                            <td>Telefone</td>
                            <td>E-mail</td>
                            {/* <td>Ação</td> */}
                        </tr>
                    </thead>
                    <tbody className='dataBody'>
                        {tenants.map(item => <tr className='item-click' onClick={() => window.location.href = `/locatarios/detalhe/${item.id}`} key={item.id}>
                            <td className='databodyitem'>{item.nickname ?? item.name}</td>
                            <td className='databodyitem'>{item.document}</td>
                            <td className='databodyitem'>{item.contact_name}</td>
                            <td className='databodyitem'>{item.mobile}</td>
                            <td className='databodyitem'>{item.email}</td>
                            {/* <td className='c-actions'>
                                    <a href={`/locatarios/detalhe/${item.id}`} className='btn'><i className='bx bx-edit-alt'></i></a>
                                    <button className='btn'><i className='bx bx-trash'></i></button>
                                </td> */}
                        </tr>)}
                    </tbody>
                </table>

            </div >
        </div >
    </div >
}

export default ListComponent;