export default class User {
    static login = async (username, password) => {
        try {
            const responseIdentity = await fetch(`${process.env.REACT_APP_IDENTITY_API_URL}/user/access`, { method: 'POST', body: JSON.stringify({ email: username, password }), headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            if(responseIdentity.status === 401) {
                const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-user-login`, { method: 'POST', body: JSON.stringify({ email: username, password }) })
                return await response.json()
            } else return await responseIdentity.json()
        } catch {
            const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-user-login`, { method: 'POST', body: JSON.stringify({ email: username, password }) })
            return await response.json()
        }
    }

    static create = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-user-add`, { method: 'POST', body: JSON.stringify(data) })

        return await response.json();
    }

    static updateOnlyPassword = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_IDENTITY_API_URL}/user/forgot-password`, { method: 'PUT', body: JSON.stringify(data), headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } },)

        return response.status === 202;
    }

    static update = async (data) => {
        await fetch(`${process.env.REACT_APP_GATEWAY_URL}/post-user-edit`, { method: 'POST', body: JSON.stringify(data) })
    }

    static sendVerificationCode = async (mobile) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_COMUNICATE_URL}/generate`, { method: 'POST', body: JSON.stringify({ mobile }), headers: { "Content-Type": "application/json" } })

            if (response.ok) {
                const json = await response.json();
                return json.token;
            } else return undefined;
        } catch (error) {
            return undefined;
        }
    }

    static sendVerificationCodeForgotPassword = async (mobile) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_COMUNICATE_URL}/generate/forgot-password`, { method: 'POST', body: JSON.stringify({ mobile }), headers: { "Content-Type": "application/json" } })

            if (response.ok) {
                const json = await response.json();
                return json;
            } else return undefined;
        } catch (error) {
            return undefined;
        }
    }

    static validateWppCode = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/whatsapp-verification-validate`, { method: 'POST', body: JSON.stringify(data) })
            const json = await response.json()
            console.log(json)
            return json;
        } catch (error) {
            return false;
        }
    }

    static home = async (user) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/home-indicators`, { method: 'POST', body: JSON.stringify({ user }) })
            return (await response.json()).data;
        } catch (error) {
            return false;
        }
    }
}