import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'

import Loading from '../../../components/loading';
import Invoice from '../../../services/invoice';
import Time from '../../../hooks/time';

const ContractListComponent = () => {
    const auth = useAuthUser();

    const [data, setData] = useState([]);
    const [dataOriginal, setDataOriginal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState("");

    useEffect(() => {
        setLoading(false);
        Invoice.list({ search: searchCriteria, user: auth().id }).then(response => {
            
            let params = new URL(document.location).searchParams;
            let ex = params.get("ex"), ap = params.get("ap"); 

            if(ex) setData(response.invoices.filter(item => item.due_date_actual < new Date().toISOString().slice(0, 100) && item.paid_out === '0'))
            else if(ap) setData(response.invoices.filter(item => item.due_date_actual > new Date().toISOString().slice(0, 100) && item.due_date_actual < new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().slice(0, 100) && item.paid_out === '0'));
            else setData(response.invoices);
            setDataOriginal(response.invoices);
        })
    }, [])

    const colorFilters = (full, selected) => {
        // let filters = document.querySelectorAll(".mobile-filter");

        if (full) {
            // filters.forEach(element => {
            // element.classList.remove('c-custom');

            // if(element.classList.contains(selected)) element.classList.add('c-custom');

            switch (selected) {
                case 'a-vencer':
                    setData(dataOriginal.filter(item => item.due_date_actual > new Date().toISOString().slice(0, 100) && item.paid_out === '0'));
                    break;
                case 'pago':
                    setData(dataOriginal.filter(item => item.paid_out === '1'));
                    break;
                case 'vencido':
                    setData(dataOriginal.filter(item => item.due_date_actual < new Date().toISOString().slice(0, 100) && item.paid_out === '0'));
                    break;
                case 'ver-todos':
                    setData(dataOriginal);
                    break;
                default:
                    setData(dataOriginal);
                    break;
            }
            // });
        }
    }

    const searchProperties = () => {
        setLoading(true);
        Invoice.list({ search: searchCriteria, user: auth().id }).then(response => {
            setDataOriginal(response.invoices);
            setData(response.invoices);
            setLoading(false);
        })
    }

    return loading ? <Loading /> :
        <div className="container-fluid my-properties d-md-block p-2" style={{overflowY: 'scroll'}} >
            <div className='d-flex justify-content-between' >
                <h2><strong>Lista de faturas</strong></h2>
                {/* <button data-bs-toggle="modal" data-bs-target="#new-contract-modal" className='btn btn-success btn-lg my-button'>Adicionar Novo Contrato</button> */}
            </div>
            <div className='my-properties-container p-5'>
                <div className='actions-bar d-flex justify-content-between' style={{ alignItems: "center" }}>
                    <div className="input-group mb-3 w-25 my-search-box">
                        <span onClick={() => searchProperties()} className="input-group-text"><i className='bx bx-search'></i></span>
                        <input defaultValue={searchCriteria} onKeyUp={(e) => setSearchCriteria(e.target.value)} type="text" className="form-control" placeholder="Procurar Fatura" />
                    </div>
                    {/* <button className='btn btn-lg btn-outline-secondary my-button-neutral'><i className='bx bx-filter' ></i> Filtrar</button> */}
                    <div className="dropdown">
                        <button className="btn btn-lg btn-outline-secondary my-button-neutral" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='bx bx-filter' ></i> Filtrar
                        </button>
                        <ul className="dropdown-menu">
                            <li onClick={() => colorFilters(true, 'a-vencer')}><a className="dropdown-item" href="#">A vencer</a></li>
                            <li onClick={() => colorFilters(true, 'pago')}><a className="dropdown-item" href="#">Pago</a></li>
                            <li onClick={() => colorFilters(true, 'vencido')}><a className="dropdown-item" href="#">Vencido</a></li>
                            <li onClick={() => colorFilters(true, 'ver-todos')}><a className="dropdown-item" href="#">Ver todos</a></li>
                        </ul>
                    </div>
                </div>
                {data.length > 0 ?
                    <div className='properties-table-box d-md-block d-none'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td>Número</td>
                                    <td>Imóvel</td>
                                    <td>Locatário</td>
                                    <td>Mês De Cobrança</td>
                                    <td>Valor Total</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(item => <tr className='item-click' onClick={() => window.location.href = `/faturas/detalhe/${item.invoice_id}`} key={item.invoice_id}>
                                    <td>{item.invoice_id}</td>
                                    <td>{item.property_name}</td>
                                    <td>{item.tenant_name}</td>
                                    <td>{item.due_date ? `${Time.months()[new Date(item.due_date_actual).getMonth()]}/${new Date(item.due_date_actual).getFullYear()}` : ""}</td>
                                    <td>R$ {item.total_value}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {item.paid_out === '1' ? <span className='badge c-tag-success'>Pago</span> : item.due_date_actual < new Date().toISOString().slice(0, 10) ? <span className='badge c-tag-danger'>Vencido</span> : <span className='badge c-tag-warning'>A Vencer</span>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    : <div className='text-center' style={{ marginTop: '120px' }}><h1><i className='bx bx-sad'></i></h1><span className=''>Nenhum contrato encontrado...</span></div>}
            </div>
        </div>
}

export default ContractListComponent;