import React, { useEffect } from "react";

import Logo from "../assets/images/logo_color.svg";
import "../assets/styles/modal-step.css";

const ModalRegular = ({ feature, featureDisplay, view }) => {
  useEffect(() => {
    document
      .getElementById(`new-${feature}-modal`)
      .addEventListener("show.bs.modal", (event) => {
        let prefix = event?.relatedTarget?.attributes['data-prefix-title']
        if(prefix) document.getElementById("title-prefix").textContent = prefix.value;
        let dialogModal = document.querySelector(
          `#new-${feature}-modal .modal-dialog`
        );
        if (window.matchMedia("(max-width: 768px)").matches) {
          if (!dialogModal.classList.contains("modal-fullscreen"))
            dialogModal.classList.add("modal-fullscreen");
        } else dialogModal.classList.remove("modal-fullscreen");
      });
  }, []);

  return (
    <div
      className="modal fade"
      id={`new-${feature}-modal`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby={`new-${feature}-modal`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header" style={{ justifyContent: "center", color: 'var(--color-primary)', padding: '10px 0', borderBottom: '1px solid gray' }}>
            <h5
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="title"
            >
              <span id="title-prefix"></span> &nbsp; {featureDisplay}
            </h5>
            <button
              style={{
                display: "flex",
                width: "0%",
                justifyContent: "end",
                alignItems: "center",
              }}
              type="button"
              className="close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i style={{color: '#fff'}} className="bx bx-x"></i>
            </button>
          </div>
          <div className={`modal-body new-${feature}`}>
            <div className="modal-step-view"> {view} </div>
          </div>
          <div className="modal-footer modal-step-footer">
            <img src={Logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRegular;
