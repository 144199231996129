import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import Button from "../../components/button";
import Contract from "../../services/contract";

const UpdateBank = () => {
    const { reference } = useParams();
    const [form, setForm] = useState({ id: null, expires: null, pix: null, error: null, showError: false, success: false });

    useEffect(() => {
        const _reference = atob(reference).split("_");
        setForm({ ...form, id: _reference[0], expires: _reference[1] });
    }, [])

    const next = async () => {
        try {
            setForm({ ...form, showError: false, error: null });
            if (!form.pix) setForm({ ...form, showError: true })
            else {
                const response = await Contract.bankAccountUpdate(form);
                if (response == 204) {
                    setForm({ ...form, success: true })
                    setTimeout(() => {
                        window.location.href = "/contratos"
                    }, 5000);
                }
                else setForm({ ...form, showError: true, error: "O link está expirado! Entre em contato com o suporte." })
            }
        } catch (error) {
            setForm({ ...form, showError: true, error: "O link está expirado! Entre em contato com o suporte." })
        }
    }

    return (
        <main style={{
            background: '#00BB82',
            height: '100dvh',
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                background: '#fff',
                padding: '20px',
                borderRadius: '10px',
                maxWidth: '500px',
                margin: '0 20px'
            }}>
                {console.log(form.success)}
                {form.success === true ? <>
                    <div style={{ display: "flex", justifyContent: 'center', flexDirection: 'column', 'alignItems': 'center' }}>
                        <span style={{ 
                            fontSize: '1.5em', background: 'green', borderRadius: '50%', height: '50px', width: '50px',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}><i style={{ color: "white" }} className="bx bx-check"></i></span>
                        <span style={{ fontSize: '2em' }}>Obrigado!</span>
                    </div>
                </> : <><h1 style={{}}>Informe seu PIX</h1>
                    <h5>Precisamos da informação para que possamos repassar o aluguel mensalmente</h5>
                    {form.showError ?
                        <div className="w-100">
                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                <i className='bx bx-info-circle'></i>
                                {form.error != null ? <strong> {form.error}</strong> : <><strong> Preencha todos os campos</strong> antes de continuar.</>}
                            </div>
                        </div>
                        : <></>}
                    <div>
                        <label htmlFor="pix">Chave PIX</label>
                        <div className={`input-group mb-3`}>
                            <input onKeyUp={(evt) => setForm({ ...form, pix: evt.target.value })} id="pix" name="pix" type="text" className="form-control input" />
                        </div>
                    </div>
                    <Button customClass={'w-100'} onClick={next} display="Continuar" /></>}
            </div>
        </main>
    )
}

export default UpdateBank;