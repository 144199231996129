// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.form-detalhes-label {
    font-size: medium;
}

@media only screen and (min-width: 300px) and (max-width: 390px) {
    .content-detalhes-title {
        font-size: medium ;
    }
    .form-detalhes-label {
        font-size: medium !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/tenant/style.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["\n.form-detalhes-label {\n    font-size: medium;\n}\n\n@media only screen and (min-width: 300px) and (max-width: 390px) {\n    .content-detalhes-title {\n        font-size: medium ;\n    }\n    .form-detalhes-label {\n        font-size: medium !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
