// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerProfileGeral {
    position: relative;
    width: 100%;
    padding: 15px;
    overflow-y: auto;

}
.containerProfileContent {
    width: 100%;
    background-color: #fff;
    box-shadow: 5px 5px 5px 0px #0000000D;
    border-radius: 10px;
    padding: 25px;
    gap: 3vw;
    display: flex;
    justify-content: space-between;
}
.profileContent {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding-left: 25px;
    width: 100%;
}
.profileContentHeader {
    display: flex;
    gap: 15vw;
}
.profileImage {
    display: flex;
    gap: 2vw;
    align-items: center;
}

.paymentContent {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding-left: 25px;
    min-width: 50%;
}

@media only screen and (min-width: 1200px) {
    .containerProfileGeral {
        position: relative;
        width: 100%;
        padding: 15px;
        overflow-y: auto;
    }

}

@media only screen and (max-width: 1200px) {

    .containerProfileContent {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

}`, "",{"version":3,"sources":["webpack://./src/views/user/profile/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,gBAAgB;;AAEpB;AACA;IACI,WAAW;IACX,sBAAsB;IACtB,qCAAqC;IACrC,mBAAmB;IACnB,aAAa;IACb,QAAQ;IACR,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI;QACI,kBAAkB;QAClB,WAAW;QACX,aAAa;QACb,gBAAgB;IACpB;;AAEJ;;AAEA;;IAEI;QACI,aAAa;QACb,8BAA8B;QAC9B,sBAAsB;IAC1B;;AAEJ","sourcesContent":[".containerProfileGeral {\n    position: relative;\n    width: 100%;\n    padding: 15px;\n    overflow-y: auto;\n\n}\n.containerProfileContent {\n    width: 100%;\n    background-color: #fff;\n    box-shadow: 5px 5px 5px 0px #0000000D;\n    border-radius: 10px;\n    padding: 25px;\n    gap: 3vw;\n    display: flex;\n    justify-content: space-between;\n}\n.profileContent {\n    display: flex;\n    flex-direction: column;\n    gap: 2vh;\n    padding-left: 25px;\n    width: 100%;\n}\n.profileContentHeader {\n    display: flex;\n    gap: 15vw;\n}\n.profileImage {\n    display: flex;\n    gap: 2vw;\n    align-items: center;\n}\n\n.paymentContent {\n    display: flex;\n    flex-direction: column;\n    gap: 2vh;\n    padding-left: 25px;\n    min-width: 50%;\n}\n\n@media only screen and (min-width: 1200px) {\n    .containerProfileGeral {\n        position: relative;\n        width: 100%;\n        padding: 15px;\n        overflow-y: auto;\n    }\n\n}\n\n@media only screen and (max-width: 1200px) {\n\n    .containerProfileContent {\n        display: flex;\n        justify-content: space-between;\n        flex-direction: column;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
