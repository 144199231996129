import React, { useState } from "react";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import LoadingBasic from "../../../components/loading-basic";
import Contract from "../../../services/contract";
import Button from "../../../components/button";
import { useAuthUser } from "react-auth-kit";

const Step01 = ({ idReturning, setIdReturning, contractReturn }) => {
    const auth = useAuthUser();
    console.log()
    const [mask, setMask] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentForm, setCurrentForm] = useState({ document: null, bank_name: null, agency: null, account: null, pix: null });

    const next = () => {
        if (document.getElementById("step-required")) document.getElementById("step-required").classList?.add('d-none');
        if (((currentForm.bank_name && currentForm.agency && currentForm.account) || currentForm.pix)) {
            setLoading(true);
            const data = {
                document: auth().document,
                created_by: auth().id
            }

            if (currentForm.pix) data.pix = currentForm.pix;
            if (currentForm.bank_name) data.bank_name = currentForm.bank_name;
            if (currentForm.agency) data.agency = currentForm.agency?.toString();
            if (currentForm.account) data.account = currentForm.account?.toString();

            Contract.bankAccountInsert(data).then(response => setIdReturning({ ...idReturning, bank_account_id: response.id, bank_account: true }));
        }
        else if (document.getElementById("step-required")) document.getElementById("step-required").classList?.remove('d-none');
    }

    return loading ? <LoadingBasic /> : <>
        <p className="modal-step-text">Por favor, nos informe os dados da sua conta bancária para que possamos repassar o aluguel mensalmente. Os dados necessários incluem:</p>
        <div id="step-required" className="d-none w-100">
            <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong> Preencha todos os campos</strong> antes de continuar.
            </div>
        </div>
        <div className="modal-step-fields without-overflow">
            <div className="d-flex flex-row row">
                {/* <div className="item w-100">
                    <label htmlFor="document">CPF ou CNPJ</label>
                    <div className={`input-group mb-3`}>
                        <CpfCnpj
                            value={currentForm.document}
                            placeholder="000.000.000-00"
                            className="form-control c-input-option"
                            onChange={(ev, type) => {
                                setCurrentForm({ ...currentForm, document: ev.target.value });
                                setMask(type === "CPF");
                            }}
                        />
                    </div>
                </div>
                <hr /> */}
                <div className="item w-100">
                    <label htmlFor="pix">Chave PIX</label>
                    <div className={`input-group mb-3`}>
                        <input onKeyUp={(evt) => setCurrentForm({ ...currentForm, pix: evt.target.value })} id="pix" name="pix" type="text" className="form-control input" />
                    </div>
                </div>
                {/* <span className="text-center"><strong>ou</strong></span>
                <div className="item w-50">
                    <label htmlFor="bank_name">Nome do banco</label>
                    <div className={`input-group mb-3`}>
                        <input onKeyUp={(evt) => setCurrentForm({ ...currentForm, bank_name: evt.target.value })} id="bank_name" name="bank_name" type="text" className="form-control input" placeholder="" />
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="agency">Agência</label>
                    <div className={`input-group mb-3`}>
                        <input onKeyUp={(evt) => setCurrentForm({ ...currentForm, agency: parseFloat(evt.target.value) })} id="agency" name="agency" type="number" className="form-control input" placeholder="0000" />
                    </div>
                </div>
                <div className="item w-100">
                    <label htmlFor="account">Conta Bancária</label>
                    <div className={`input-group mb-3`}>
                        <input onKeyUp={(evt) => setCurrentForm({ ...currentForm, account: parseFloat(evt.target.value) })} id="account" name="account" type="number" className="form-control input" placeholder="0000" />
                    </div>
                </div> */}
            </div>
        </div>
        <div className="modal-step-actions c-options-nav">
            {contractReturn?.active ?
                <button onClick={() => contractReturn.callback({ ...contractReturn.config, currentStep: 5 })} className="btn c-prev-step">Voltar para o Contrato</button>
                : <button data-bs-dismiss="modal" aria-label="Close" className={`btn imob-button imob-button-neutral`}>Cancelar</button>}
            <Button onClick={next} display="Continuar" />
        </div>
    </>
}

export default Step01;