const Nav = () => {
    return <div className="l-navbar d-sm-none d-md-block" id="nav-bar">
        <nav className="nav">
            <div>
                <div className="nav_list">
                    <hr className="hidden-hr" />
                    <a href="/admin/repasse" className="nav_link">
                        <i className='bx bx-home-alt nav_icon'></i>
                        <span className="nav_name">Repasse</span>
                    </a>
                </div>
            </div>
        </nav>
    </div>
}

export default Nav;