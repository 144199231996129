// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {

}

/* Estilos para telas médias (601px a 900px) */
@media only screen and (max-width: 1143px) {
    .nav-style {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/nav/style.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA,8CAA8C;AAC9C;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media only screen and (max-width: 600px) {\n\n}\n\n/* Estilos para telas médias (601px a 900px) */\n@media only screen and (max-width: 1143px) {\n    .nav-style {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
