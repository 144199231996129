import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-auth-kit'
import { RouterProvider } from "react-router-dom";
import Controller from './controller';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider authType={'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}>
      <RouterProvider router={Controller} />
    </AuthProvider>
);