import Layout from "../../components/layout/layout";
import React, { useState, useEffect } from 'react';

import List from "./shared/list";
import ListMobile from "./shared/list-mobile";

const Invoices = () => { 
    const [mobile, setMobile] = useState(null);

    useEffect(() => {
        if(window.matchMedia("(max-width: 768px)").matches) setMobile(true)
        else setMobile(false);
    }, []);
    
    return (
        <Layout>
            {mobile ? <ListMobile /> : <List /> }
        </Layout>
    )
 }  

export default Invoices;