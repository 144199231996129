const NavMobile = () => {

    return <div className="l-navbar d-block d-md-none" id="nav-bar">
        <nav className="navbar fixed-bottom nav-mobile">
            <a href="/admin/repasse" className="nav_mobile_link home active">
                <i className='bx bx-home nav_icon'></i>
                <span className="nav_mobile_name">Repasse</span>
            </a>
        </nav>
    </div>
}

export default NavMobile;