import { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit'

import Tenant from '../../../services/tenant';
import Loading from '../../../components/loading';
import "./style.css"

const ListMobileComponent = () => {
    const auth = useAuthUser();

    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        Tenant.listByUser(auth().id, search, true).then(result => {
            setLoading(false);
            if (result.users != null) setTenants(result.users);
        });
    }, []);

    const searchTenant = (evt, all = true, is_active = true) => {
        setLoading(true);

        Tenant.listByUser(auth().id, search, all, is_active).then(result => {
            setTenants(result.users);
            setLoading(false);
            setTimeout(() => {
                document.querySelector('.mobile-filter.c-custom').classList.toggle('c-custom');
                if (all || !Array.from(evt.target.classList).includes('mobile-filter')) document.querySelector('.mobile-filter.ver-todos').classList.toggle('c-custom');
                else if (is_active === false) document.querySelector('.mobile-filter.inativos').classList.toggle('c-custom');
                else if (is_active === true) document.querySelector('.mobile-filter.ativos').classList.toggle('c-custom');
            }, 100);
        });
    }

    return <div className="container-fluid my-properties-mobile d-block">
        <div className='d-flex justify-content-end' style={{ alignItems: "center" }}>
            <div className="input-group mb-3 my-mobile-search-box" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <span onClick={(evt) => { evt.preventDefault(); searchTenant(evt); }} className="input-group-text"><i className='bx bx-search'></i></span>
                <input
                    onKeyUp={(e) => {
                        setSearch(e.target.value); 
                        searchTenant(); 
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Procurar Locatário"
                />

                <button data-bs-toggle="modal" data-bs-target="#tenant-creation-modal" className='btn btn-success my-button my-mobile-button'>
                    <i className='bx bx-plus' ></i>
                </button>
            </div>
        </div>
        <div className='my-propertis-mobile-container'>
            <div className='mobile-filters'>
                <span onClick={(evt) => searchTenant(evt, false, true)} className='mobile-filter ativos w-50 m-1 filter-locatario-mobile'>Ativos</span>
                <span onClick={(evt) => searchTenant(evt, false, false)} className='mobile-filter inativos w-50 m-1 filter-locatario-mobile'>Inativos</span>
                <span onClick={(evt) => searchTenant(evt, true, true)} className='mobile-filter ver-todos c-custom w-50 m-1 filter-locatario-mobile'>Ver todos</span>
            </div>
            <div className='mobile-list-items'>
                {tenants.length > 0 ? tenants.map(item => <div onClick={() => window.location.href = `/locatarios/detalhe/${item.id}`} className='mobile-list-item' key={item.id}>
                    {/* <div className="dropdown c-mobile-action-list">
                        <button className="my-button-neutral-0 " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className='bx bx-dots-horizontal-rounded' ></i>
                        </button>
                        <ul className="dropdown-menu my-dropdown">
                            <li><a className="dropdown-item" href={`/locatarios/detalhe/${item.id}`}><i className='bx bx-edit-alt'></i><span>Editar</span></a></li>
                            <li><a className="dropdown-item" href="#"><i className='bx bx-trash'></i><span>Excluir</span></a></li>
                        </ul>
                    </div> */}
                    <div className='property-mobile-details-list locatario-details'>
                        <div className='c-attr-group locatario-info'>
                            <div className='c-attr'>
                                <span className='c-title locatario-title'>Nome:</span>
                                <span className='c-content locatario-data'>{item.nickname ?? item.name}</span>
                            </div>
                            <div className='c-attr'>
                                <span className='c-title locatario-title'>CPF/CNPJ:</span>
                                <span className='c-content locatario-data'>{item.document}</span>
                            </div>
                        </div>
                        <div className='c-attr-group locatario-info'>
                            <div className='c-attr'>
                                <span className='c-title locatario-title'>Número:</span>
                                <span className='c-content locatario-data'>{item.mobile}</span>
                            </div>
                            <div className='c-attr'>
                                <span className='c-title locatario-title'>E-mail:</span>
                                <span className='c-content locatario-data'>{item.email}</span>
                            </div>
                        </div>
                    </div>
                    <div className='c-attr c-attr-group-custom' style={{ display: 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'flexDirection': 'column', 'padding': '10px' }}>
                        <span className='c-title'>Status:</span>
                        <span className='c-content' style={{ marginTop: '10px' }}>
                            {item.is_active == '1' ? <span className='c-tag-success'>Ativo</span> : <span className='c-tag'>Inativo</span>}
                        </span>
                    </div>
                </div>
                ) : <div className='text-center' style={{ marginTop: '120px' }}><h1><i className='bx bx-sad'></i></h1><span className=''>Nenhum locatário encontrado...</span></div>}
            </div>
        </div>
    </div>
}

export default ListMobileComponent;