import React, { useState } from "react";
import "./style.css";
import Step01 from "./step01/step-01";
import Step02 from "./step02/step-02";
import Step03 from "./step03/step-03";
import Step04 from "./step04/step04";
import { TokenProvider } from "../../../context/TokenContext";
import { UserProvider } from "../../../context/UserContext"


const Register = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
      };
    
      const handlePrevStep = () => {
        setCurrentStep(currentStep - 1);
      };

    return (
        <main className="registerMainContainer">
            <div className="containerRegisterGeral">
                <UserProvider>
                    <TokenProvider>
                        {currentStep === 1 && <Step01 onNextStep={handleNextStep} />}
                        {currentStep === 2 && <Step02 onNextStep={handleNextStep} onPrevStep={handlePrevStep} />}
                        {currentStep === 3 && <Step03 onNextStep={handleNextStep} onPrevStep={handlePrevStep} />}
                        {currentStep === 4 && <Step04 onPrevStep={handlePrevStep} />}
                    </TokenProvider>
                </UserProvider>
            </div>

        </main>
    )
}

export default Register;

