import React from "react";
import User from "../../../services/user";
import { useSignIn } from 'react-auth-kit'
import "./style.css";
import Logo from '../../../assets/images/logo(2).svg'
import Logo4 from '../../../assets/images/qrcode.png'
import Logo2 from '../../../assets/images/logo_white.svg'
import Logo3 from '../../../assets/images/logo_i.svg';
import LogoHorizontal from '../../../assets/images/logo_horizontal.svg'

// import GoogleIcon from "../../assets/images/social/Google.png"
// import FacebookIcon from "../../assets/images/social/Facebook.png"

// import FacebookLogin from 'react-facebook-login';
// import GoogleLogin from 'react-google-login';

const Login = () => {
    const signIn = useSignIn();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [isInvalid, setIsInvalid] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const submit = async (e) => {
        e.preventDefault();
        setIsInvalid(false);
        setErrorMessage("");

        if (username && password) {
            const response = await User.login(username, password);
            if (response.success && signIn({ token: response.user.username ?? response.access_token, expiresIn: 86400, tokenType: "Bearer", authState: response.user })) {
                if (response?.user?.profile?.id === 1 || response?.user?.id_profile === "1") window.location.href = "/home"
                else if (response?.user?.profile?.id === 2 || response?.user?.id_profile === "2") window.location.href = "/admin/repasse"
            } else {
                setIsInvalid(true);
                setErrorMessage("E-mail e/ou Senha incorretos.");
            }
        } else {
            setIsInvalid(true);
            setErrorMessage("Preencha todos os campos antes de continuar.");
        }
    }

    // const responseFacebook = (response) => {
    //     console.log(response);
    // }

    // const responseGoogle = (response) => {
    //     console.log(response);
    // }

    return (
        <>
            <main className="containerLoginGeralMobile"style={{ overflowY: 'auto' }}>
                
                <div className="containerFormMobile bg-white">
                    <form className="formMobileLogin">
                        <figure className="mobileLogoEuImobContainer">
                            <img src={Logo} alt="Euimob Logo" className="mobileLogoEuImob"></img>
                        </figure>
                        <h1 className="tituloLogin">Seja bem-vindo</h1>
                        {isInvalid && <div className="w-100" role="alert">
                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                <i className='bx bx-info-circle'></i>
                                <strong> Atenção! </strong> {errorMessage}
                            </div>
                        </div>}
                        <div className="mb-3 w-100-2 mt-3 containerMobileInput">
                            <label htmlFor="username-input" className="form-label">E-mail</label>
                            <input placeholder="eu.imob@euimob.com.br" autoComplete="email" type="email" className="form-control inputLogin" id="username-input" onKeyUp={(evt) => setUsername(evt.target.value)} />
                            <div className="mb-3 w-100-2">
                                <div className="d-flex justify-content-between mt-3">
                                    <label htmlFor="password-input">Senha</label>
                                    {/* <a href="#" className="formLink">Esqueceu sua senha?</a> */}
                                </div>
                                <input placeholder="Digite sua senha" autoComplete="current-password" type="password" id="password-input" className="form-control inputLogin" onKeyUp={(evt) => setPassword(evt.target.value)} />
                            </div>
                            <button style={{ background: '#00BB82', color: '#fff', borderRadius: '5px !important' }} type="submit" className="btn w-100" onClick={(e) => submit(e)}>Entrar</button>
                            <div className="mt-3 text-center d-flex align-items-center justify-content-center ">
                                Não tem uma conta?
                                <a href="/registrar" className="btn btn-link formLink">Cadastre-se</a>
                            </div>
                        </div>

                    </form>
                </div>
            </main>

            <main className="containerLoginGeral" style={{ overflowY: 'scroll' }}>
                <div className="containerLogin">
                    <form action="javascript(0)">
                        <div className="containerForm">
                            <figure className="containerLogoLogin">
                                <img src={Logo} alt="Eu.imob Logo" className="logoLogin" />
                            </figure>
                            <h1 className="tituloLogin">Seja bem-vindo</h1>
                            {isInvalid && <div className="w-100" role="alert">
                            <div className="alert imob-alert imob-alert-danger" role="alert">
                                <i className='bx bx-info-circle'></i>
                                <strong> Atenção! </strong> {errorMessage}
                            </div>
                        </div>}
                            <div className="mb-3 w-100-2 mt-3">
                                <label htmlFor="username-input">E-mail</label>
                                <input placeholder="eu.imob@euimob.com.br" autoComplete="email" type="email" className="form-control inputLogin" id="username-input" onKeyUp={(evt) => setUsername(evt.target.value)} />
                                <div className="mb-3 w-100-2">
                                    <div className="d-flex justify-content-between mt-3">
                                        <label htmlFor="password-input">Senha</label>
                                        <a href="/esqueci-minha-senha" className="formLink">Esqueceu sua senha?</a>
                                    </div>
                                    <input placeholder="Digite sua senha" autoComplete="current-password" type="password" id="password-input" className="form-control inputLogin" onKeyUp={(evt) => setPassword(evt.target.value)} />
                                </div>
                                <button style={{ background: '#00BB82', color: '#fff', borderRadius: '5px !important' }} type="submit" className="btn w-100" onClick={(e) => submit(e)}>Entrar</button>
                                <div className="mt-5 text-center d-flex align-items-center justify-content-center ">
                                    Não tem uma conta?
                                    <a href="/registrar" className="btn btn-link formLink">Cadastre-se</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="containerAnuncio" style={{ display: 'flex', 'alignItems': 'center', justifyContent: 'flex-start', flexDirection: 'column', gap: '0' }}>
                    <img src={Logo4} alt="euimob logo 4" width={300} />
                    <div className="containerTitulo">
                        <h1 style={{ fontSize:'2em', textAlign: 'center' }}>Escaneie o QR code e<br /> baixe nosso App!</h1>
                    </div>
                    <div className="containerSubTitulo" style={{ fontSize:'1.3em' }}>
                        <p>Com o app da eu.imob você terá acesso a todos<br /> os recursos para gerenciar seu imóvel na palma mão</p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Login;


