import React, { useState } from 'react';
import "./style.css";

import image from "../../../assets/images/fluxo_cobranca.jpg";

const ExplanationImage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="ExplanationImage">
      {modalIsOpen && (
        <div className="modal-overlay-ei" >
          <div className="modal-ei" style={{ overflowY: 'visible' }}>
            <img src={image} width={1000} alt="Sample" className="modal-image-ei" /><br />
            <button onClick={closeModal} className="close-button-ei">Fechar!</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExplanationImage;
