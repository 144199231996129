import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        username: "",
        name: "",
        lastName: "",
        email: "",
        document: "",
        password: "",
        mobile: "",
        created_by: 0,
        id_profile: 1
    });

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserContext.Provider>
    );
};

export const useInfo = () => useContext(UserContext);