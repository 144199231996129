import "../assets/styles/retangular-item.css";

const RetangularItem = ({ id, columnOne, columnTwo, columnThree, onClick }) => {
    return <div key={`retangular-item-${id}`} id={`retangular-item-${id}`} className="retangular-item" onClick={() => onClick(id)}>
        <span>{columnOne}</span>
        <span>{columnTwo}</span>
        <span>{columnThree}</span>
    </div>
}

export default RetangularItem;