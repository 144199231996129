import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import LoadingBasic from "../../../components/loading-basic";
import RetangularItem from "../../../components/rectangular-item";

const Step02 = ({ config, setForm, form, switchStep, tenants }) => {
    const [searchCriteria, setSearchCriteria] = useState("");

    const search = (criteria) => {
        setSearchCriteria(criteria);
        tenants.items = tenants.original;

        let newTenantList = [];

        tenants.items?.forEach(tenant => {
            if (tenant.name.toLowerCase().includes(criteria.toLowerCase())) newTenantList.push(tenant);
            else if (tenant.nickname && tenant.nickname.toLowerCase().includes(criteria.toLowerCase())) newTenantList.push(tenant);
            else if (tenant.document.toLowerCase().includes(criteria.toLowerCase())) newTenantList.push(tenant);
        });

        tenants.items = newTenantList;
    }

    const selectTenant = (id, sample) => {
        document.querySelectorAll('.retangular-item').forEach(item => item.classList.remove('selected'));
        let item = document.getElementById(`retangular-item-${id}`)//.classList.toggle('selected');;
        if(item) item.classList.toggle('selected');

        if(!sample) {
            let tenant_name = ""
            tenants.items.forEach(i => i.id === id ? tenant_name = i.name : "");
            setForm({ ...form, tenant_id: id, tenant_name });
        }
    }

    const previous = () => {
        document.getElementById("step-02-required").classList.add('d-none');
        switchStep({ ...config, currentStep: 1 });
    }

    const next = () => {
        document.getElementById("step-02-required").classList.add('d-none');
        if (form.tenant_id !== null) switchStep({ ...config, currentStep: 3 });
        else document.getElementById("step-02-required").classList.remove('d-none');
    }

    useEffect(() => {
        if(form.tenant_id) selectTenant(form.tenant_id, true);
    }, [form.tenant_id, tenants])

    return <>
        <p className="modal-step-text">Agora, selecione um locatário já cadastrado ou cadastre um novo locatário para vinculá-lo a este imóvel.</p>
        <div id="step-02-required" className="d-none w-100">
            <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong> Selecione</strong> um locatário antes de continuar.
            </div>
        </div>
        <div className={`input-group mb-3 modal-step-search ${(tenants && tenants.items && tenants.items.length === 0) && !tenants.loading && searchCriteria != "" ? 'search-criteria-not-foud' : ''}`}>
            <span className="input-group-text icon"><i className='bx bx-search'></i></span>
            <input onKeyUp={(evt) => search(evt.target.value)} type="text" className="form-control input" placeholder="Busque por apelido, nome ou documento" />
            <Button onClick={() => switchStep({ ...config, currentStep: 11 })} customStyle={{ 'marginLeft': '10px', 'borderRadius': '5px', 'width': '20%' }} display="Novo" />
        </div>
        <div className="modal-step-fields">
            {tenants && !tenants.loading ? tenants.items.length > 0 ? tenants.items.map(tenant => <RetangularItem key={tenant.id} id={tenant.id} columnOne={tenant.name} columnTwo={tenant.nickname} columnThree={tenant.document} onClick={selectTenant} />) : <>
                {searchCriteria != "" ? <div className="alert imob-alert imob-alert-danger" role="alert">
                    <i className='bx bx-info-circle'></i>
                    <strong>
                        Não encontramos nenhum locatário correspondente aos critérios de pesquisa.
                        Você pode verificar as informações inseridas ou <strong onClick={() => switchStep({ ...config, currentStep: 11 })}><u>cadastrar um novo imóvel</u></strong>.
                    </strong>
                </div> : <><>Você ainda não tem um locatário cadastrado. <strong onClick={() => switchStep({ ...config, currentStep: 11 })}><u>Clique aqui para adicionar um novo</u></strong>.</></>}
            </> : <LoadingBasic />}
        </div>
        <div className="modal-step-actions">
            <Button onClick={previous} customClass='imob-button-neutral' display='Voltar' />
            <Button onClick={next} display="Continuar" />
        </div>
    </>
}

export default Step02;