import React, { useState } from "react";
import { useAuthUser } from 'react-auth-kit'
import Button from "../../../components/button";
import LoadingBasic from "../../../components/loading-basic";
import Contract from "../../../services/contract";
import "./style.css"

const Step06 = ({ config, setForm, form, switchStep }) => {
    const auth = useAuthUser();

    const [currentForm, setCurrentForm] = useState({ terms_consents: false });
    const [loading, setLoading] = useState(false);
    const [redirectToPayment, setRedirectToPayment] = useState(false);
    const [redirectStripeUrl, setRedirectStripeUrl] = useState("");

    const previous = () => {
        document.getElementById("step-required").classList.add('d-none');
        switchStep({ ...config, currentStep: 5 });
    }

    const next = () => {
        document.getElementById("step-required").classList.add('d-none');
        if (currentForm.terms_consents) {
            setLoading(true);
            let data = { ...form, ...currentForm };

            delete data.tenant_name;
            delete data.property_name;
            delete data.property_type;

            let start_date = new Date(data.start_at);
            let end_date = new Date(start_date.setMonth(start_date.getMonth() + data.end_at));

            data.start_at = new Date(data.start_at).toISOString();
            data.end_at = end_date.toISOString();

            Contract.contractInsert(data).then(response => {
                Contract.needSetupRecurrence(btoa(auth().id)).then((has) => {
                    if (!has) {
                        Contract.activeContract(auth().id, response.id).then((response2) => {
                            setRedirectStripeUrl(response2.session)
                            setRedirectToPayment(true);
                            setLoading(false);
                        });
                    } else {
                        Contract.updateContract(auth().id, response.id).then(_ => {
                            window.location.reload();
                        });
                    }
                })
                
            })
        }
        else document.getElementById("step-required").classList.remove('d-none');
    }

    return loading ? <LoadingBasic /> : redirectToPayment ? <>
        <div className="c-step-final justify-content-center p-5">
            <span className="d-flex justify-content-center mb-3" style={{
                color: '#00BB82',
                fontSize: '3em'
            }}><i className='bx bxs-check-circle'></i></span>
            <h4 className="text-center">Entenda a mensalidade</h4>
            <p className="text-justify">A assinatura da eu.imob tem o custo fixo de R$50,00 por contrato ativo. O processamento ocorre todo dia 1° no cartão de crédito cadastrado.
            <br />O cadastro é necessário apenas no primeiro contrato. Se houver a necessidade de substitui-lo, você poderá realizar a troca na sessão “Minha conta” {">"} “Dados de pagamento”</p>
            <div className="c-options d-flex flex-column">
                <a href={redirectStripeUrl} style={{ color: 'white' }} className="btn c-option my-button">Ativar contrato</a>
                <a href="/contracots" className="btn c-option my-button" style={{
                    background: 'transparent',
                    color: '#03619c',
                    marginTop: '5px'
                }}>Página inicial</a>
            </div>
        </div>
    </> : <>
        <p className="modal-step-text">Para finalizarmos, por favor, leia e confirme os termos de aceite dos serviços. Abaixo, você encontrará um resumo do contrato:</p>
        <div id="step-required" className="d-none w-100">
            <div className="alert imob-alert imob-alert-danger" role="alert">
                <i className='bx bx-info-circle'></i>
                <strong> Aceite os termos</strong> antes de continuar.
            </div>
        </div>
        <div className="modal-step-fields without-overflow">
            <div className="d-flex flex-row row">
                {/* <div className="item w-100">
                <label htmlFor="resume">Resumo do Contrato</label>
                <div className={`input-group mb-3`}>
                    <input readOnly defaultValue={`Contrato de locação de imóvel ${form.property_type}`} id="resume" name="resume" type="text" className="form-control input" />
                </div>
            </div> */}
                <div className="item w-100">
                    <label htmlFor="property_name" style={{ fontSize: "medium" }}>Imóvel</label>
                    <div className={`input-group mb-3`}>
                        <input readOnly defaultValue={form.property_name} id="property_name" name="property_name" type="text" className="form-control input" />
                    </div>
                </div>
                <div className="item w-100">
                    <label htmlFor="tenant_name" style={{ fontSize: "medium" }}>Locatário</label>
                    <div className={`input-group mb-3`}>
                        <input readOnly defaultValue={form.tenant_name} id="tenant_name" name="tenant_name" type="text" className="form-control input" />
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="rent_value" style={{ fontSize: "medium" }}>Valor Mensal da Locação</label>
                    <div className={`input-group mb-3`}>
                        <input readOnly defaultValue={form.rent_value} value={`R$ ${form.rent_value + (form.condominium_value ?? 0) + (form.iptu_value ?? 0)}`} id="rent_value" name="rent_value" type="text" className="form-control input" />
                    </div>
                </div>
                <div className="item w-50">
                    <label htmlFor="pay_day" style={{ fontSize: "medium" }}>Dia de Vencimento da Fatura</label>
                    <div className={`input-group mb-3`}>
                        <input readOnly defaultValue={form.pay_day} id="pay_day" name="pay_day" type="text" className="form-control input" />
                    </div>
                </div>
                <div className="item w-100 d-flex justify-content-center">
                    <div className={`form-check mb-3`} style={{ display: 'flex', width: '100%', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        <input onChange={(evt) => setCurrentForm({ terms_consents: evt.target.checked })} id="accept_terms" name="pay_day" type="checkbox" className="" style={{ width: '8%' }} />
                        <label className="form-check-label " htmlFor="accept_terms" ><span className="labelInputTermos">Concordo com os termos de contrato</span></label>
                    </div>
                </div>
                <div className="item w-100 text-center">
                    <small>
                        Se desejar, você pode ler os termos completos do contrato <strong><a href="https://euimob.com.br/termos-e-condicoes" target="_blank">clicando aqui</a></strong>
                    </small>
                </div>
            </div>
        </div>
        <div className="modal-step-actions">
            <Button onClick={previous} customClass='imob-button-neutral' display='Voltar' />
            <Button onClick={next} display="Continuar" />
        </div>
    </>
}

export default Step06;